import React from "react";
import { Field } from "redux-form";

import { app } from "redux/actions";
import { Input, Dadata } from "components/reduxForm";
import { toCapitalize } from "helpers";
import { InputModeType } from "components/UI";
import { Grid } from "components/UI/grid/Grid";
import { FormSection } from "components/form/FormSection";
import { InputMaskType } from "../../constants";

export function PreviousPassport() {
  const normalizePassportIssuerCode = React.useCallback(
    ({ data, value }) => (data ? data.code : value),
    []
  );

  return (
    <FormSection header="Ранее выданный паспорт">
      <Grid>
        <Field
          component={Input.Redux}
          name="previous_passport"
          label="Серия, номер"
          placeholder="7788123456"
          width="4"
          inputMode={InputModeType.NUMERIC}
          mask={InputMaskType.PASSPORT}
        />
        <Field
          component={Input.Redux}
          name="previous_issue_date"
          label="Дата выдачи"
          width="4"
          mask={InputMaskType.DATE}
          placeholder="дд.мм.гггг"
          inputMode={InputModeType.NUMERIC}
        />
        <Field
          component={Dadata.Redux}
          name="previous_issuer_code"
          label="Код подразделения"
          type="fms_unit"
          paramToShow="code"
          onChange={app.prev_issuer_code}
          placeholder="123-987"
          mask={InputMaskType.BRANCH_CODE}
          width="4"
          normalize={normalizePassportIssuerCode}
          inputMode={InputModeType.NUMERIC}
        />
        <Field
          component={Input.Redux}
          name="previous_issuer_name"
          label="Кем выдан"
          width="12"
          normalize={toCapitalize}
        />
      </Grid>
    </FormSection>
  );
}
