import { useState, useCallback, useEffect, useRef } from "react";

export const useTimer = (start = 0) => {
  const timerId = useRef(null);
  const timeoutId = useRef(null);
  const [timer, setTimer] = useState(0);

  const resetTimers = useCallback(() => {
    clearInterval(timerId.current);
    clearTimeout(timeoutId.current);
    setTimer(start);
  }, [setTimer]);

  const onStart = useCallback(
    (timeout) => {
      resetTimers();
      timerId.current = setInterval(() => {
        setTimer((remainingTime) =>
          remainingTime > 0 ? remainingTime - 1 : timeout - 1
        );
      }, 1000);
      timeoutId.current = setTimeout(resetTimers, timeout * 1000);
    },
    [timer]
  );

  useEffect(() => {
    return () => resetTimers();
  }, []);

  const minutes = parseInt(timer / 60);
  const seconds = timer - minutes * 60;
  const isTimeout = seconds === 0;
  const isStarted = timer > 0;

  const timerString = isStarted
    ? `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`
    : "00:00";

  return {
    minutes,
    seconds,
    isStarted,
    isTimeout,
    onStart,
    onStop: resetTimers,
    timerString,
  };
};
