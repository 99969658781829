import { getState } from "../helpers";
import { selectApp } from "../modules/app/selectors";

const StepNames = {
  INFO: 'Ваши контакты',
  PROF: 'Ваша деятельность',
  DOCS: 'Персональные данные',
};

const nameAliaces = {
    dealer: 'dealer_id',
    sum: 'price',
    term: 'period',
    car: 'model',
    fee: 'initial_fee'
};

const HyundaiMetricSteps = {
  START_APPLICATION: 'START_APPLICATION',
  GO_TO_DOCS: 'GO_TO_DOCS',
  GO_TO_PROF: 'GO_TO_PROF',
  APPONLINE_SUCCESS: 'APPONLINE_SUCCESS',
  APPONLINE_ERROR: 'APPONLINE_ERROR',
  APPLICATION_APPROVED: 'APPLICATION_APPROVED',
  COUNTER_FINISHED: 'COUNTER_FINISHED',
  INFORMATION_CARD_CLICKED: 'INFORMATION_CARD_CLICKED',
  CHAT_BUTTON_CLICKED: 'CHAT_BUTTON_CLICKED',
};

const metricEntities = {
  START_APPLICATION: {
    category: 'Ecredit предодобрение',
    action: 'Первый этап предодобрения',
    label: 'Ваши контакты'
  },
  GO_TO_DOCS: {
    category: 'Ecredit предодобрение',
    action: 'Переход на этап предодобрения',
    label: 'Персональные данные'
  },
  GO_TO_PROF: {
    category: 'Ecredit предодобрение',
    action: 'Переход на этап предодобрения',
    label: 'Ваша деятельность'
  },
  APPONLINE_SUCCESS: {
    category: 'Ecredit предодобрение',
    action: 'Заявка отправлена'
  },
  //Заявка отклонена или другая ошибка
  APPONLINE_ERROR: {
    category: 'Ecredit предодобрение',
    action: 'Заявка отклонена'
  },
  //Счетчик истек
  COUNTER_FINISHED: {
    category: 'Ecredit предодобрение',
    action: 'Заявка отклонена'
  },
  APPLICATION_APPROVED: {
    category: 'Ecredit предодобрение',
    action: 'Заявка принята'
  },
  INFORMATION_CARD_CLICKED: {
    category: 'Ecredit предодобрение',
    action: 'Взаимодействие с информацией внизу формы',
    label: '{{Название информационной карточки}}, {{Название этапа}}'
  },
  CHAT_BUTTON_CLICKED: {
    category: 'Ecredit предодобрение',
    action: 'Взаимодейсчите с чатом',
    label: '{{Название этапа}}'
  }
};

const HYUDAI_TOKEN = 'hyundai';
const DEFAULT_BRAND = 'Hyundai';

class Hyundai
{
    static sendedMetrics = {};

    static getStepName(step) {
      return StepNames[step];
    }

    static toStandartParams(defaultParams) {
        const hasCustomParams = defaultParams.dealer && defaultParams.sum && defaultParams.car;

        if (!hasCustomParams) {
            return;
        }

        for (let i in nameAliaces) {
            defaultParams[nameAliaces[i]] = defaultParams[i];
        }

        defaultParams.token = HYUDAI_TOKEN;
        defaultParams.brand = DEFAULT_BRAND;
    }

    static currentStepIsProcessed(metricName)
    {

      if (metricName === HyundaiMetricSteps.INFORMATION_CARD_CLICKED || metricName === HyundaiMetricSteps.CHAT_BUTTON_CLICKED){
        return false;
      }

      if (this.sendedMetrics[metricName]){
        return true;
      }

      this.sendedMetrics[metricName] = true;

      return false;
    }

    static sendMetric(metricName, token, debugFlag, payload)
    {

      try{

        if (window.location.hostname !== 'credit-approval.ecredit.one'){
          return null;
        }

        if (!window.dataLayer){
          return null;
        }

        if (token !== HYUDAI_TOKEN || this.currentStepIsProcessed(metricName)){
          return null;
        }

        if (metricEntities[metricName]){
          const { step } = selectApp(getState());
          let label = null;

          if (metricName === HyundaiMetricSteps.INFORMATION_CARD_CLICKED){
            label = `${payload}, ${this.getStepName(step)}`;
          }

          if (metricName === HyundaiMetricSteps.CHAT_BUTTON_CLICKED){
            label = this.getStepName(step);
          }

          const sendGoogleObject = {
            "event": "custom_event",
            "category" : metricEntities[metricName].category,
            "action": metricEntities[metricName].action,
            "label": label || metricEntities[metricName].label || undefined
          };
          window.dataLayer.push(sendGoogleObject);
          debugFlag && Hyundai.consoleLoggMetrics(metricName, sendGoogleObject);
        }
      }catch (e) {
        window.console.log('Sending metrics error for hyundai', e);
      }

    }

    static consoleLoggMetrics(step, googleObject)
    {
      window.console.log('МЕТРИКА = ' + step);
      window.console.log(googleObject);
    }
}

export { Hyundai, HyundaiMetricSteps };
