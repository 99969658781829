import React from "react";
import PropTypes from "prop-types";
import {Field} from "redux-form";
import {
  Grid,
  GridEmptyCell,
  InputModeType,
  TypographyType,
  Button,
} from "components/UI";
import {Input, Dadata} from "components/reduxForm";
import {
  normalizePersonName,
  formatMobilePhone,
  normalizeMobilePhone,
} from "../../utils";
import {InputMaskType} from "../../constants";
import {Typography} from "components/UI/typography/Typography";
import {DriverType} from "../constants";
import {ReactComponent as DeleteIcon} from "./delete.svg";
import {Flex} from "components/layouts";
import {app} from "redux/actions";
import {toCapitalize} from "helpers";

import "./driver.scss";
import {FormSection} from "components/form";

const fieldIsDisabled = (type) =>
  type === DriverType.MAIN || type === DriverType.SECOND;
export const isMainDriver = (type) => type === DriverType.MAIN;

export function Driver({driver, index, value, onRemove}) {
  const {type} = value;

  const handleRemove = React.useCallback(() => onRemove(index, type), [
    index,
    onRemove,
  ]);

  const handleExperienceBeginChange = (date) => {
    app.saveExperienceYearFromDate(date, index);
  };

  return (
    <div className="driver">
      <div className="driver__header">
        <Typography type={TypographyType.SECTION_HEADER}>{`Водитель #${
          index + 1
        }`}</Typography>
        {!isMainDriver(type) && (
          <Button flat onClick={handleRemove} className="driver__remove-button">
            <DeleteIcon/>
          </Button>
        )}
      </div>
      <FormSection>
        <Grid>
          <Field
            component={Dadata.Redux}
            type="fio"
            name={`${driver}.surname`}
            label="Фамилия"
            width="4"
            mask={InputMaskType.PERSON_NAME}
            autoReplace={normalizePersonName}
            parts="SURNAME"
            disabled={fieldIsDisabled(type)}
          />
          <Field
            component={Dadata.Redux}
            type="fio"
            name={`${driver}.name`}
            label="Имя"
            width="4"
            mask={InputMaskType.PERSON_NAME}
            autoReplace={normalizePersonName}
            parts="NAME"
            disabled={fieldIsDisabled(type)}
          />
          <Field
            component={Dadata.Redux}
            type="fio"
            name={`${driver}.middle_name`}
            label="Отчество"
            width="4"
            mask={InputMaskType.MIDDLE_NAME}
            autoReplace={normalizePersonName}
            parts="PATRONYMIC"
            disabled={fieldIsDisabled(type)}
          />
          {!isMainDriver(type) && (
            <>
              <Field
                component={Input.Redux}
                label="Дата рождения"
                name={`${driver}.birth_date`}
                mask={InputMaskType.DATE}
                width="4"
                placeholder="дд.мм.гггг"
                inputMode={InputModeType.NUMERIC}
              />
              <Field
                component={Input.Redux}
                name={`${driver}.mobile_phone`}
                label="Номер телефона"
                prefix="+7"
                placeholder="(___) ___ ____"
                mask={InputMaskType.MOBILE_PHONE_WITHOUT_COUNTRY_CODE}
                width="4"
                inputMode={InputModeType.NUMERIC}
                format={formatMobilePhone}
                normalize={normalizeMobilePhone}
                disabled={fieldIsDisabled(type)}
              />
              <Field
                component={Dadata.Redux}
                name={`${driver}.registrationCity`}
                label="Город регистрации"
                width="4"
                parts="CITY"
              />
            </>
          )}
        </Grid>
      </FormSection>

      <FormSection header="Водительское удостоверение">
        <Grid>
          <Field
            component={Input.Redux}
            name={`${driver}.driverLicense`}
            label="Серия, номер"
            mask={InputMaskType.DRIVE_LICENSE}
            placeholder="7788123456"
            width="4"
          />
          <Field
            component={Input.Redux}
            name={`${driver}.issue_date_2nd`}
            label="Дата выдачи"
            mask={InputMaskType.DATE}
            placeholder="дд.мм.гггг"
            inputMode={InputModeType.NUMERIC}
            width="4"
          />
          <Field
            component={Input.Redux}
            name={`${driver}.experience_begin`}
            label="Дата начала стажа"
            width="4"
            placeholder="дд.мм.гггг"
            inputMode={InputModeType.NUMERIC}
            onChange={handleExperienceBeginChange}
            mask={InputMaskType.DATE}
          />
          <Field
            component={Input.Redux}
            name={`${driver}.issuer_name_2nd`}
            label="Кем выдан"
            width="8"
            normalize={toCapitalize}
          />
        </Grid>
      </FormSection>

    </div>
  );
}

Driver.propTypes = {
  driver: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(DriverType)),
    maritalStatus: PropTypes.object,
  }),
  onRemove: PropTypes.func.isRequired,
};
