import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { change } from 'redux-form';
import reducers from 'redux/reducers';
import { Flex } from 'components/layouts';
import { Icon, Backdrop } from 'components/UI';
import { GridCell } from 'components/UI/grid/components/GridCell';
import { useAlternativeField } from './useAlternativeField';

import { createMetricName } from "../../field-metric-name";

import './style.sass';


export const Flat = (props) => {
  const {
    onChange = () => {},
    module,
    field,
    name,
    meta,
    options = [{id: 0, name: "Нет данных"}],
    width,
    label,
    selected,
    defaultValue = "Не указано",
    mini,
    wBorder,
    fieldClassName,
    notRequired,
    disabled = false,
    className,
    help,
  } = props;

  const [isOptionsOpened, setIsOptionsOpened] = React.useState(false);
  const { FieldComponent, alternativeDesign } = useAlternativeField();

  const selectValue = React.useCallback(async (value) => {
    await module && field && reducers[module].update({ [field]: value });
    onChange(value);
    name && meta && meta.dispatch(change('Application', name, value));
  }, [onChange, module, field, name, meta]);

  const toggleOptions = React.useCallback(
    () => setIsOptionsOpened(prevState => !prevState),
    [setIsOptionsOpened],
  );

  const selectedValue = React.useMemo(() => {
    const value = selected && selected.name || defaultValue;

    if (alternativeDesign) {
      return value;
    }

    //Для suzukimotors в селектах placeholder не используется (иначе косяк в марка, модель)
    //Но в Город/Дилер - надо использовать placeholder
    //const emptyValue = field === 'city' || field === 'dealer' ? defaultValue : '';
    //return value !== defaultValue ? value : emptyValue;

    return value !== defaultValue ? value : '';
  }, [selected]);

  const error = meta && (meta.dirty || meta.touched) && meta.error;
  const inputId = `alternativeSelect-${name || field || label}`;
  const dataId = createMetricName(name);

  return (
    <GridCell width={width}>
      <FieldComponent
         label={label}
         error={error}
         required={!notRequired && Boolean(label)}
         className={className}
         help={help}
         active={isOptionsOpened || selected && selected.name}
         inputId={inputId}
      >
        <Backdrop
          className="select-backdrop"
          active={isOptionsOpened}
          onClick={toggleOptions}
        />
        <div
          className={cx('select-wrapper', mini && 'mini', wBorder && 'with_border', error && 'error', fieldClassName || '', {
            'select-wrapper_active': isOptionsOpened,
          } )}
          onClick={disabled ? () => {} : toggleOptions}>
          <input id={inputId} data-id={dataId} hidden />
          <div className={'select'} name={name} >
            <p className="select__value">{selectedValue}</p>
            {
              isOptionsOpened &&
                <div className={'options'}>
                  {
                    options.map( option =>
                      <div
                        className={'option'}
                        key={option.id}
                        onClick={() => selectValue(option)}>
                          {option.name}
                      </div>
                  )}
                </div>
            }
          </div>
          <Icon symbol="select" className={'icon'} />
        </div>
      </FieldComponent>
    </GridCell>
  )
};


Flat.propTypes = {
  onChange: PropTypes.func,
  module: PropTypes.string,
  field: PropTypes.string,
  name: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.array,
  width: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.object,
  defaultValue: PropTypes.string,
  mini: PropTypes.bool,
  wBorder: PropTypes.bool,
  fieldClassName: PropTypes.string,
  notRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  help: PropTypes.string,
};

export const Redux = ({ input, ...props }) =>
  <Flat
    {...input}
    {...props}
  />

Redux.propTypes = {
  input: PropTypes.object,
};
