import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { ErrorBoundary } from 'components/error-boundary';
import { Preloader } from 'components/layouts'
import { app } from 'redux/actions';
import {
  setMobile,
  selectTheme,
  selectAppInitialized,
} from 'modules/app';
import { MainPage } from './MainPage';
import { resizeHandler } from './resizeHandler';

import './app.scss';
import { isIFrameMode } from 'isIFrameMode';

export const App = () => {
  const theme = useSelector(selectTheme);
  const initialized = useSelector(selectAppInitialized);
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const resizeCallback = () => {
      setMobile();
      setIsVisible(document.documentElement.clientWidth > 0);
    };

    resizeCallback();
    return resizeHandler(resizeCallback);
  }, []);

  React.useEffect(() => {
    if (isVisible) {
      app.initApp();
    }
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={classnames('app', `theme-${theme}`, {'app__iframe': isIFrameMode()})}>
      <div className="app__container">
        <ErrorBoundary>
  {initialized ? <MainPage /> : <Preloader isShow />}
        </ErrorBoundary>
      </div>
    </div>
  );
};
