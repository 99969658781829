import {string, object, array} from 'yup';

export const validationSchema = object().shape({
  'drivers': array()
    .of(object().shape({
      'type': string(),
      'surname': object()
        .dadataRequired(),
      'name': object()
        .dadataRequired(),
      'middle_name': object()
        .dadataRequired(),
      'birth_date': string()
        .required()
        .date()
        .adult(),
      'mobile_phone': string()
        .required()
        .phone(),
      'driverLicense': string()
        .required()
        .driverLicense(),
      'issue_date_2nd': string().required()
        .driverLicenseIssueDate(),
      'issuer_name_2nd': string()
        .nullable()
        .required()
        .notLatin(),
      'experience_begin': string()
        .driverLicenseExperienceBegin()
        .required(),
    }))
    .required()
    .min(1),
});
