export const equipments = [
  {
    id: 1997041,
    brand_id: 7,
    model_id: 8598,
    name: '2.0T CVT Prestige',
    displayName: 'Комплектация PRESTIGE',
    displayPrice: '1 999 900 ₽',
    price: 1999900,
    description: [
      '18-дюймовые алюминиевые литые диски',
      'Боковые зеркала с электрической регулировкой, обогревом, повторителями поворотов',
      'Режим Sport/Eco mode',
      'Газовые упоры капота',
      'Бесключевой доступ и запуск двигателя кнопкой (ключ в кармане)',
      'Система дистанционного запуска двигателя и прогрева салона',
      'Водительское сиденье с электрической регулировкой в 6-ти направлениях',
      'Система улучшенной фильтрации воздуха в салоне',
      'Кузов из высокопрочной стали немецкого концерна BENTELER AG',
      'HD-ОБЗОР 360°',
      'Панорамные камеры парковки',
      'Премиальный кожаный салон',
      'Дистанционный запуск и открытие багажника'
    ]
  },
  {
    id: 1997042,
    brand_id: 7,
    model_id: 8598,
    name: '1.6T 7DCT Premium (пакет Speedline)',
    displayName: 'Пакет Speedline',
    displayPrice: '+ 200 000 ₽',
    price: 2199900,
    description: [
      'Новый двигатель 1.6L TGDI 186 л.с. / 275 Нм.',
      '7-DCT GETRAG - автоматизированная трансмиссия с двумя сцеплениями',
      'Новый премиальный интерьер TIGGO 8 PRO',
      '12.3” HD ЖК-экран панели приборов',
      'Мультисенсорный 12.3” HD-дисплей медиацентра',
      'Беспроводная зарядка для смартфонов',
      'LED-противотуманные фары'
    ]
  },
  {
    id: 1997043,
    brand_id: 7,
    model_id: 8598,
    name: '1.6T 7DCT Flagship (пакет Dreamline)',
    displayName: 'Пакет Dreamline',
    displayPrice: '+ 350 000 ₽',
    price: 2349900,
    description: [
      'Панорамная крыша с люком и шторкой',
      'Наружная подсветка под зеркалами',
      'Память настроек зеркал',
      'Передние датчики парковки',
      'Система кругового обзора 360 (HD-камера)',
      'Система мониторинга слепых зон',
      'Система Старт/Стоп',
      'Цветная сенсорная боковая панель управления климат-контролем 8"',
      'Темно-коричневый цвет отделки сидений',
      'Память настроек сидения водителя',
      'Пассажирское сиденье с электрической регулировкой в 4-х направлениях',
      'Электрохромное зеркало заднего вида',
      'Функция авто-отпотевания стекла',
      'Подсветка в солнцезащитном козырьке водителя и пассажира',
      'Хромированная отделка педалей'
    ]
  }
];

