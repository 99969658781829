import React from "react";
import { useSelector } from "react-redux";
import { selectSuccessReturnUrl } from "../../../../../modules/app/selectors";
import { Typography } from "components/UI/typography/Typography";
import {Button, TypographyType} from "components/UI";

import "./index.scss";

export const DocumentsSuccessPage = () => {
  const successReturnUrl = useSelector(selectSuccessReturnUrl);
  return (
    <div className="thank-you">
      <Typography type={TypographyType.RESULT_HEADER} text="Спасибо!" />
      <Typography
        type={TypographyType.RESULT_HEADER}
        text="Мы отправили ваши документы в банк."
        className="thank-you__result-header"
      />
      <Typography
        type={TypographyType.SUB_HEADER}
        text="Скоро с вами свяжется специалист и назначит встречу."
      />
      <a href={successReturnUrl} className='thank-you__closebutton'><Button>Закрыть</Button></a>
    </div>
  );
}
