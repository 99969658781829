import React, {useCallback} from "react";
import {QUESTIONNAIRE_ELEMENT_ID, SwitchType} from "../../../../helpers/constants";
import {Flex} from "../../../../components/layouts";
import {useSelector} from "react-redux";
import {selectDealerBlock, selectTheme} from "../../../../modules/app";
import {VolvoShortIntroHeader} from "./components/VolvoShortIntroHeader";
import {isVolvoOnline} from "../../../../modules/app/utils/isVolvo";
import {ReactComponent as InfoRed1} from "../../../../assets/info-red1.svg";
import {ReactComponent as InfoShortIcon4} from "../../../../assets/info-short-icon4.svg";
import {ReactComponent as ArrowLine} from "../../../../assets/arrow-line.svg";
import {ReactComponent as InfoShortIcon2} from "../../../../assets/info-short-icon2.svg";
import {ReactComponent as InfoRed2} from "../../../../assets/info-red2.svg";
import {ReactComponent as InfoShortIcon1} from "../../../../assets/info-short-icon1.svg";
import {ReactComponent as InfoRed3} from "../../../../assets/info-red3.svg";
import {ReactComponent as InfoShortIcon3} from "../../../../assets/info-short-icon3.svg";
import {CHERY_TIGGO8_PRO_THEME_NAME} from "../../../../modules/app/utils/cheryTiggo8Pro";

export const ShortIntroduction = () => {
  const theme = useSelector(selectTheme);
  const isCherry = useCallback(() => theme === 'chery_red' || theme === CHERY_TIGGO8_PRO_THEME_NAME, [theme])
  const dealerBlock = useSelector(selectDealerBlock);

  return (
    <>
      <Flex column className={'steps-header'} id={QUESTIONNAIRE_ELEMENT_ID}>
        {
          isVolvoOnline() ? <VolvoShortIntroHeader /> : <></>
        }
      </Flex>
      <div className='info-short__steps'>
        {dealerBlock.show === SwitchType.ON &&
        <React.Fragment>
          <div className='info-short__steps__step'>
            {isCherry() ? <InfoRed1 /> : <InfoShortIcon4/>}
            <span>Выберите дилера</span>
          </div>
          <div className='info-short__steps__step info-short__steps__step--arrow'>
            {isCherry() ? <ArrowLine /> : <InfoShortIcon2 />}
          </div>
        </React.Fragment>
        }

        <div className='info-short__steps__step'>
          {isCherry() ? <InfoRed2 /> : <InfoShortIcon1 />}
          <span>Отправьте заявку в дилерский центр</span>
        </div>
        <div className='info-short__steps__step info-short__steps__step--arrow'>
          {isCherry() ? <ArrowLine /> : <InfoShortIcon2 />}
        </div>
        <div className='info-short__steps__step'>
          {isCherry() ? <InfoRed3 /> : <InfoShortIcon3 />}
          <span>Узнайте подробности</span>
        </div>

      </div>
    </>
  )
}
