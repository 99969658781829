import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {connect} from 'redaction'
import {app as application} from 'redux/actions'
import {parseSubmissionTextError, scrollToElement} from 'helpers'
import {QuestionaryType, DealerIdType, SwitchType} from 'helpers/constants';
import {formValueSelector, getFormValues, SubmissionError} from 'redux-form'
import {AppOnline, Hyundai, HyundaiMetricSteps} from 'models';
import {Select} from 'components/reduxForm';

import {Flex} from 'components/layouts'

import {ThankYou, Sidebar} from '..'

import {InfoPopup, CarConditionPopup} from 'components/UI/info-popup'
import {InfoShort} from './info-short/InfoShort'
import {Info} from './info/Info';
import {Prof} from './prof/Prof';
import {Docs} from './docs/Docs';
import {Drivers} from "./drivers/Drivers";
import {Kasko} from "./kasko/Kasko";
import {FinalConditions} from "./final-conditions/FinalConditions";
import {sendNotify} from "modules/notify/notify";
import {validateKaskoParams} from "../../../../modules/kasko/validateKaskoParams";
import {canShowKaskoFrameOffers} from "../../../../modules/kasko/validateKaskoParams";

import {selectCityDealersForCurrentBrand, selectCitiesForCurrentBrand} from 'modules/car';
import {
  selectDealerBlock,
  selectToken,
  selectDebugFlag,
  setApplicationStep,
  selectNeedManualCarCondition,
  selectStepFromRequest,
  selectClientApplicationId,
  selectInitialStep
} from 'modules/app';
import {isProcessKasko} from "modules/app/utils/isProcessKasko";
import {choseFirstCityDealer} from 'modules/car/actions';

import {QuestionaryStepType} from './constants';
import {QuestionaryTabs} from './tabs/QuestionaryTabs';
import {Grid} from 'components/UI/grid/Grid';

import {switchOnShowIndustryBranch} from "modules/app";

import './styles.sass'
import {GridEmptyCell} from 'components/UI/grid/components/GridEmptyCell';

import {gtmStartApplication} from "models/GtmSender";

import {getOffers} from "modules/car";
import {selectPeriod, selectOffers} from "modules/car";

import reducers from 'redux/reducers';
import {selectCurrentOffer} from "modules/car";
import {FORM_NAME} from "modules/form";
import {getState} from "helpers";
import {fetchWrapper, MethodType} from "api/fetch";
import {ApiMethods, createApiMethodUrl} from "api/createApiMethodUrl";
import {DocumentsUploader} from "pages/Main/components/AppForm/documents-uploader";
import {DocumentsSuccessPage} from "pages/Main/components/AppForm/documents-success-page";
import {Kasago} from "models";
import {selectFirstOffer, resetPeriod} from "modules/car";
import {selectTheme} from "modules/app";
import { getBuyBackAmount } from "modules/app/utils/getBuyBackAmount";
import {Typography, TypographyType} from "../../../../components/UI";

export const AppForm = connect({
  app: 'app',
  user: 'user',
  car: 'car',
  period: 'car.period',
  appConfig: 'app.appConfig',
  defaultParams: 'app.defaultParams',
  untouchedInitialParams: 'app.untouchedInitialParams',
  brand: 'car.brand',
  model: 'car.model',
  config: 'car.config',
  //cities: 'dictionary.cities',
  cities: selectCitiesForCurrentBrand,
  city: 'car.city',
  dealer: 'car.dealer',
  dealers: selectCityDealersForCurrentBrand,
  dealerBlock: selectDealerBlock,
  chosenPeriod: selectPeriod,
  offers: selectOffers,
  token: selectToken,
  debugFlag: selectDebugFlag,
  currentOffer: selectCurrentOffer,
  needManualCarCondition: selectNeedManualCarCondition,
  stepFromRequest: selectStepFromRequest,
  clientApplicationId: selectClientApplicationId,
  initialStep: selectInitialStep,
  firstOffer: selectFirstOffer,
  userPersonalData: state => formValueSelector('Application')(state, 'personal_data'),
  userName: state => formValueSelector('Application')(state, 'name'),
  birthDate: state => formValueSelector('Application')(state, 'birth_date'),
  middleName: state => formValueSelector('Application')(state, 'middle_name'),
  email: state => formValueSelector('Application')(state, 'email'),
  userSurname: state => formValueSelector('Application')(state, 'surname'),
  userPhoneNum: state => formValueSelector('Application')(state, 'mobile_phone'),
  employer_industry_branch: state => formValueSelector('Application')(state, 'employer_industry_branch'),
  employer_number_of_employees: state => formValueSelector('Application')(state, 'employer_number_of_employees'),
  theme: selectTheme
})(
  class AppFormComponent extends Component {
    static propTypes = {
      dealers: PropTypes.arrayOf(PropTypes.object).isRequired,
      dealerBlock: PropTypes.shape({
        show: PropTypes.number,
        block: PropTypes.number,
      }),
      city: PropTypes.object,
      dealer: PropTypes.object,
    };

    state = {
      step: this.props.initialStep,
      data: null,
      shortForm: true,
      offerNotChosenWarning: false,
      agreementType: null,
      dealerNotChosenWarning: false,
      genderNotSelectedWarning: false
    }

    send = async (formValues) => {
      const {app} = this.props
      const {step} = this.state;

      await application.appOnline(AppOnline.toBackendModel(formValues, app, step));

      this.goToDrivers();
    }

    setStepOnThankYouPage = () => {
      this.setState({step: QuestionaryStepType.THANK_YOU_PAGE})
      setApplicationStep(QuestionaryStepType.THANK_YOU_PAGE);
      try{
        sessionStorage.setItem('is_page_reload', 1);
      }catch (e){
      }
    }

    toggleOfferNotChosenWarning = () => reducers.app.update({offerNotChosenWarning: !this.props.app.offerNotChosenWarning});

    toggleDealerNotChosenWarning = () => reducers.app.update({dealerNotChosenWarning: !this.props.app.dealerNotChosenWarning});

    toggleGenderNotSelectedWarning = () => reducers.app.update({genderNotSelectedWarning: !this.props.app.genderNotSelectedWarning});


    toggleCarConditionNeed = (carCondition) => {
      const {appConfig} = this.props;
      reducers.app.update({appConfig: {...appConfig, car_condition: carCondition}, needManualCarCondition: false});
      //reducers.app.update({ needManualCarCondition: true });
      getOffers();
    }

    goToProf = data => {
      const {token, debugFlag} = this.props
      this.setState({step: QuestionaryStepType.PROF, data})
      setApplicationStep(QuestionaryStepType.PROF);
      Hyundai.sendMetric(HyundaiMetricSteps.GO_TO_PROF, token, debugFlag);
    }

    goToDocs = () => {
      const {token, debugFlag} = this.props;
      this.setState({step: QuestionaryStepType.DOCS})
      setApplicationStep(QuestionaryStepType.DOCS);

      Hyundai.sendMetric(HyundaiMetricSteps.GO_TO_DOCS, token, debugFlag);
    }

    submitProf = async (formValues) => {
      const {app, employer_industry_branch} = this.props;
      const {step} = this.state;

      if (!employer_industry_branch) {
        switchOnShowIndustryBranch();
      }

      await application.appOnlineApplyProf(AppOnline.toBackendModel(formValues, app, step));


      this.goToDocs();
    };

    goToKasko = async (formValues) => {
      const {app} = this.props;
      const {step} = this.state;

      if (isProcessKasko()) {

        //Валидировать данные для фрейма
        const validatedParams = await validateKaskoParams();
        const cahShowKaskoFrameOffers = canShowKaskoFrameOffers();

        await application.appOnline(AppOnline.toBackendModel(formValues, app, step, true));

        if (cahShowKaskoFrameOffers){
          this.setState({step: QuestionaryStepType.KASKO});
          setApplicationStep(QuestionaryStepType.KASKO);
        }else{
          alert('Недостаточно параметров для оформления страхования ОСАГО. Заявка на кредит успешно сформирована и будет отправлена в банк.');
          this.setStepOnThankYouPage();
        }

      }else{
        await application.appOnline(AppOnline.toBackendModel(formValues, app, step, true));
        this.setStepOnThankYouPage();
      }
    }

    goToKaskoForOsagoPayment = () => {
      this.setState({step: QuestionaryStepType.KASKO});
    }

    goToKaskoWithoutAppOnlineSending = () => {
      this.goToKaskoForOsagoPayment();
      setApplicationStep(QuestionaryStepType.KASKO);
    }

    goToFinalConditions = () => {
      this.setState({step: QuestionaryStepType.FINAL_CONDITIONS})
      setApplicationStep(QuestionaryStepType.FINAL_CONDITIONS);
    }

    goToDrivers = () => {
      this.setState({step: QuestionaryStepType.DRIVERS})
      setApplicationStep(QuestionaryStepType.DRIVERS);
    }

    goToDocumentsSuccess = () => {
      this.setState({step: QuestionaryStepType.DOCUMENTS_SUCCESS_PAGE})
      setApplicationStep(QuestionaryStepType.DOCUMENTS_SUCCESS_PAGE);
    }

    showDocumentsStep = () => {
      this.setState({step: QuestionaryStepType.DOCUMENTS})
    }

    saveFrameCommunication = async (data) => {
      const {clientApplicationId} = this.props;
      await fetchWrapper(
        createApiMethodUrl(
          ApiMethods.saveFrameCommunicationData
        ), {
          method: MethodType.POST,
          headers: {},
          body: JSON.stringify({data, client_application_id: (clientApplicationId)})
        });
    }

    updateKaskoConditions = async (data) => {
      const {clientApplicationId} = this.props;
      await Kasago.saveKasagoParams(clientApplicationId, data);
    }

    telegramNotify = async (message) => {
      const {clientApplicationId} = this.props;
      await sendNotify(message, clientApplicationId);
    }

    recalculateOffersWithKasko = async (data) => {
      const kaskoPrice = data && data.kasko_price ? data.kasko_price : 0;
      const {clientApplicationId} = this.props;

      //Сохранение данных от каско
      await fetchWrapper(
        createApiMethodUrl(
          ApiMethods.saveInsurancePrepare
        ), {
          method: MethodType.POST,
          headers: {},
          body: JSON.stringify({prepare: data, client_application_id: (clientApplicationId)})
        });

      //Перерасчет
      reducers.car.update({kaskoPrice});
      await getOffers();

      //Поставить автоматом расчет из того же периода
      const {chosenPeriod, offers} = this.props;
      const chosenOffer = offers[chosenPeriod] ?? null;


      if (chosenOffer) {
        reducers.car.update({chosen: chosenOffer});
      }

    }

    goBackToInfo = () => {
      reducers.app.update({step: QuestionaryStepType.INFO});
      this.setState({step: QuestionaryStepType.INFO})
    }

    componentDidUpdate(props, state) {
      if (state.step === QuestionaryStepType.INFO && this.state.step === "prof" && !this.props.app.application_id) this.newApp(this.state.data)
    }

    shortFormSubmit = async () => {

      const {
        car,
        userName,
        userSurname,
        userPhoneNum,
        defaultParams,
        appConfig,
        app,
        dealer,
        birthDate,
        middleName,
        email,
        currentOffer,
        untouchedInitialParams,
        dealerBlock
      } = this.props;

      const {step} = this.state;

      const sendApplicationToBank = step === QuestionaryStepType.FINAL_CONDITIONS ? SwitchType.ON : SwitchType.OFF;

      if (dealerBlock.show === SwitchType.ON && !car.dealer) {
        scrollToElement('dealersBlock')
        this.toggleDealerNotChosenWarning();
        return;
      }

      const client_phone = userPhoneNum.replace("+", "").replace(/\s/g, "");

      const buyBackAmount = getBuyBackAmount();

      // Вот здесь реальная отправка newapp
      let body = {
        anketa_type: appConfig.blocks.anketa_type,
        last_success_carfin_url: app.last_success_carfin_url,
        process_kasko: Number(isProcessKasko()),
        kasko_price: car.kaskoPrice,
        rate: defaultParams.rate,
        untouchedInitialParams,
        engine_volume: car.engine_volume ? car.engine_volume : '',
        engine_power: car.engine_power ? car.engine_power : '',
        car_issue_date: car.issue_date ? car.issue_date : '',
        transmission_type: car.transmission_type ? car.transmission_type : '',
        pts_status: car.pts && car.pts.status ? car.pts.status : '',
        pts_issue_date: car.pts && car.pts.issue_date ? car.pts.issue_date : '',
        pts_number: car.pts && car.pts.number ? car.pts.number : '',
        pts_series: car.pts && car.pts.series ? car.pts.series : '',
        application_id: app.application_id || null,
        id_type: app.id_type || null,
        from_our_front: 1,
        async: 1,
        // dealer_id: (dealer && dealer.id) ? dealer.id : defaultParams.dealer_id,
        // //В списке дилеров всегда используется id дилера по нумерции екредит
        // dealer_id_type: (dealer && dealer.id) ? DealerIdType.ECREDIT : defaultParams.dealer_id_type,
        price: car.price,
        initial_fee_money: car.initial_fee_money,
        period: car.period,
        car_brand: car.brand.name,
        car_model: car.model.name,
        car_configuration: car.config ? car.config.name : '',
        program_id: currentOffer ? currentOffer.id : '',
        client_phone,
        birth_date: birthDate,
        client_name: userName.value ? userName.value : userName,
        client_surname: userSurname ? userSurname.value : (userName.value ? userName.value : userName),
        car_condition: appConfig.car_condition,
        car_year: appConfig.car_year,
        agree_terms: 1,
        product_type: 1,
        client_email: email,
        client_middle_name: middleName ? middleName.value : '',
        buyback_payment: buyBackAmount,
        first_auto: Number(car.firstAuto),
        family_auto: Number(car.familyAuto),
        medical_worker: Number(car.medicalWorker),
        trade_in: Number(car.tradeIn),
        monthly_payment: defaultParams.monthly_payment,
        vin: car.vin ? car.vin : defaultParams.vin,
        site: defaultParams.clienthost ? defaultParams.clienthost : window.location.host,
        client_application_id: appConfig.client_application_id || '',
        send_to_bank: sendApplicationToBank
      }

      if (appConfig.blocks.calculator.show === 0) {
        body = {
          ...body,
          car_brand: defaultParams.brand,
          car_model: defaultParams.model,
          car_configuration: defaultParams.configuration,
          price: defaultParams.price,
          initial_fee_money: car.initial_fee_money,
          initial_fee: car.initial_fee,
          car_year: appConfig.car_year,
          car_condition: appConfig.car_condition,
          program_id: defaultParams.program_id,
          period: defaultParams.period
        }
      }

      await application.newApp(body);

      try {
        if (!sendApplicationToBank) {
          this.goToProf();
        } else {
          this.setStepOnThankYouPage();
        }

      } catch (error) {
        throw new SubmissionError({_error: parseSubmissionTextError(error)});
      }
    }

    shortFormSubmitAndSend = async () => {
      const {app} = this.props;
      const {step} = this.state;

      await this.shortFormSubmit();

      const formValues = getFormValues(FORM_NAME)(getState());

      await application.appOnline(AppOnline.toBackendModel(formValues, app, step, isProcessKasko()));
    }

    setDealerInfoToAppConfig = (dealer) => {
      application.setDealerToAppConfig(dealer);
      resetPeriod();
      getOffers();
    }


    render() {
      const {
        step,
        dealerNotChosenWarning,
        genderNotSelectedWarning,
      } = this.state;

      const {
        app,
        period,
        appConfig,
        brand,
        model,
        config,
        car,
        userName,
        userPhoneNum,
        city,
        cities,
        dealer,
        dealers,
        dealerBlock,
        token,
        debugFlag,
        currentOffer,
        needManualCarCondition,
      } = this.props;

      const brandHasSelected = !!car.brand;

      Hyundai.sendMetric(HyundaiMetricSteps.START_APPLICATION, token, debugFlag);

      gtmStartApplication();

      const newAppValid = !!car.price && !!car.initial_fee && !!car.period && !!car.brand && !!car.model && !!config && !!currentOffer.id && !!car.city && !!car.dealer && userName && !!userName && !!userPhoneNum;

      let disableShortFormSubmit = true;
      if (appConfig?.blocks?.calculator?.show === 1 && newAppValid) {
        disableShortFormSubmit = false;
      } else if (period && brand && model && config && userName && !!userPhoneNum && car.city && !!car.dealer) {
        disableShortFormSubmit = false;
      }


      return (
        <Flex relative alignCenter column>
          {dealerBlock?.show === SwitchType.ON && appConfig.blocks.anketa_type !== QuestionaryType.SHORT &&
          <div id='dealersBlock' style={{opacity: !brandHasSelected || step !== QuestionaryStepType.INFO ? 0.5 : 1}}>
            <Grid>
              <GridEmptyCell width="2"/>
              <Select.Flat
                className="app-form__dealer-select"
                width="4"
                fieldClassName="select-v1"
                label="Ваш город"
                options={cities}
                selected={city}
                module="car"
                field="city"
                onChange={choseFirstCityDealer}
                disabled={!brandHasSelected || step !== QuestionaryStepType.INFO || dealerBlock.block === SwitchType.ON}
              />
              <Select.Flat
                className="app-form__dealer-select"
                width="4"
                fieldClassName="select-v1"
                label="Ваш дилер"
                options={dealers}
                selected={dealer}
                onChange={this.setDealerInfoToAppConfig}
                module="car"
                field="dealer"
                disabled={!brandHasSelected || step !== QuestionaryStepType.INFO || dealerBlock.block === SwitchType.ON}
              />
            </Grid>
          </div>
          }
          {
            <InfoPopup
              isOpen={app.dealerNotChosenWarning}
              message="Необходимо выбрать дилера"
              onClose={this.toggleDealerNotChosenWarning}
            />
          }
          {
            <InfoPopup
              isOpen={app.offerNotChosenWarning}
              message="Выберите срок кредита"
              onClose={this.toggleOfferNotChosenWarning}
            />
          }
          {
            <InfoPopup
              isOpen={app.genderNotSelectedWarning}
              message="Необходимо заполнить пол"
              onClose={this.toggleGenderNotSelectedWarning}
            />
          }
          {appConfig.blocks.anketa_type === QuestionaryType.SHORT
            ? (
              <>
                <InfoShort
                  onSubmit={this.shortFormSubmit}
                  disableSubmit={disableShortFormSubmit}
                />

              </>
            ) : (
              <>
                {step === QuestionaryStepType.THANK_YOU_PAGE
                  ? <ThankYou/>
                  : (
                    <div className="app-form__form" id="app-form">
                      <QuestionaryTabs step={step}/>
                      {period && <Sidebar/>}
                      {step === QuestionaryStepType.INFO && <Info onSubmit={this.shortFormSubmit}/>}
                      {step === QuestionaryStepType.PROF &&
                      <Prof onSubmit={this.submitProf} goBack={this.goBackToInfo}/>}
                      {step === QuestionaryStepType.DOCS &&
                      <Docs onSubmit={this.send} goBack={this.goToProf}/>}
                      {step === QuestionaryStepType.DRIVERS &&
                      <Drivers onSubmit={this.goToKasko} goBack={this.goToDocs}/>}
                      {step === QuestionaryStepType.KASKO &&
                      <Kasko onSubmit={this.goToFinalConditions} goBack={this.goToDrivers}
                             recalculate={this.recalculateOffersWithKasko} onPaymentSuccess={this.goToDocumentsSuccess}
                             updateKaskoConditions={this.updateKaskoConditions}
                             telegramNotify={this.telegramNotify}
                             saveFrameCommunication={this.saveFrameCommunication}/>}
                      {step === QuestionaryStepType.FINAL_CONDITIONS &&
                      <FinalConditions onSubmit={this.shortFormSubmitAndSend}
                                       goBack={this.goToKaskoWithoutAppOnlineSending}/>}
                      {step === QuestionaryStepType.DOCUMENTS &&
                      /*<DocumentsUploader onSuccess={this.goToDocumentsSuccess}/>}*/
                      <DocumentsUploader onSuccess={this.goToKaskoForOsagoPayment}/>}
                      {step === QuestionaryStepType.DOCUMENTS_SUCCESS_PAGE && <DocumentsSuccessPage/>}
                      {/*<InfoPopup*/}
                      {/*  isOpen={offerNotChosenWarning}*/}
                      {/*  message="Необходимо выбрать кредитный расчет"*/}
                      {/*  onClose={this.toggleOfferNotChosenWarning}*/}
                      {/*/>*/}
                      <CarConditionPopup
                        isOpen={needManualCarCondition}
                        onClose={this.toggleCarConditionNeed}
                      />
                    </div>
                  )
                }
              </>
            )
          }
        </Flex>
      )
    }
  }
)
