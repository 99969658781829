import { selectToken } from "../selectors";
import { getState } from "../../../helpers";

export const VOLVO_TOKEN = 'volvomotors';

/**
 * Партнер Вольво с GTM
 * @type {string}
 */
export const VOLVO_ONLINE_TOKEN = 'a3ff15554b3b3311d0a2dddf29d83981';

export const VOLVO_DOMAIN = 'volvo-online.e-credit.one';

export const isVolvoOnline = clientToken =>
{
  const token = clientToken ? clientToken : selectToken(getState());
  const domain = window.location.host;

  return token === VOLVO_ONLINE_TOKEN || domain.includes(VOLVO_DOMAIN);

}
