const splitPassport = (passport) => {
  return {
    series: passport.slice(0, 4),
    number: passport.slice(4),
  }
}

export function passportToBackendModel(passport) {
  if (passport && passport.length === 10) {
    return splitPassport(passport);
  }

  return {
    series: passport,
    number: "",
  };
}
