import React from "react";
import { Field } from "redux-form";
import { app } from "redux/actions";

import { Input, Dadata } from "components/reduxForm";
import { InputModeType } from "components/UI";
import { Grid } from "components/UI/grid/Grid";
import { FormSection } from "components/form/FormSection";
import { toCapitalize } from "helpers";
import { InputMaskType } from "../../constants";

export function ActualAddress() {
  return (
    <FormSection header="Адрес фактического проживания">
      <Grid>
        {<Field
          component={Input.Redux}
          name="la_index"
          label="Индекс"
          width="4"
          mask={InputMaskType.POST_INDEX}
          inputMode={InputModeType.NUMERIC}
        />}
        <Field
          component={Dadata.Redux}
          onChange={app.livingAdress}
          name="living_address"
          label="Адрес"
          width="12"
          autoReplace={toCapitalize}
        />
        <Field
          component={Input.Redux}
          name="la_registration_address_date"
          label="Дата начала проживания"
          mask={InputMaskType.DATE}
          width="4"
          placeholder="дд.мм.гггг"
          inputMode={InputModeType.NUMERIC}
        />
      </Grid>
    </FormSection>
  );
}
