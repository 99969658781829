import React, {useState} from "react";
import {useSelector} from "react-redux";
import {parse, format} from "date-fns";
import "./auto-driver-details.scss";

export const AutoDriverDetails = () => {
  const [active, setActive] = useState(false);
  const {brand, model, engine_power, vin, pts} = useSelector((state) => state.car);
  const {values} = useSelector((state) => state.form.Application);
  const {car_year} = useSelector((state) => state.app.appConfig);

  const formatDate = (date) => date && format(parse(date, "yyyy-MM-dd", new Date()), "dd-MM-yyyy") || '';

  const getValue = (field, delimiter) => {
    return values && values[field] && values[field].value && `${values[field].value}${delimiter}` || '';
  }
  const handleToggleClick = () => {
    setActive(!active)
  };


  return (
    <div className="auto-driver-details">
      {active && <div className="auto-driver-details__data">
        <div className="auto-driver-details__subtitle">
          Автомобиль
        </div>
        <p>
          {brand && brand.name} {model && model.name} {car_year} г., {engine_power} л.с.<br/>
          <div  style={{ display:'none'}}>
            VIN {vin} <br/>
            ПТС {pts.series}{pts.number} Дата выдачи ПТС {formatDate(pts.issue_date)}
          </div>
        </p>
        <div className="auto-driver-details__subtitle">
          Страхователь и собственник
        </div>
        <p>
          {getValue("surname", " ")} {getValue("name", " ")} {getValue("middle_name", " ")} {values["birth_date"]} г.р. <br/>
          <b>Паспорт</b> {values["passport"]} выдан {values["passport_issue_date"]} <br/>
          {values["passport_issuer_name"]} <br/>
          <b>Регистрация</b> {values["registration_address"] && values["registration_address"].value}
        </p>
        <p>
          {values["email"]}<br/>
          {values["mobile_phone"]}
        </p>
        {values.drivers && values.drivers.map((d, index) => (
          <p>
            <b>Водитель #{index + 1}</b><br/>
            {d.surname.value} {d.name.value} {d.middle_name.value}<br/>
            <b>ВУ {d.driverLicense}</b>
          </p>
        ))}
      </div>}
      <div className="auto-driver-details__toggle" onClick={handleToggleClick}>
        {active ? 'Скрыть анкету' : 'Ваша анкета'}
      </div>
    </div>
  );
}
