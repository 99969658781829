import { getState } from "helpers";
import { selectAppConfig } from "../selectors";
import { selectDefaultParams } from "../selectors";
import { selectRecognizedCar } from "../selectors";

export const isReInitCarSwitcherAvailable = () => {
  const state = getState();
  const { stock } = selectAppConfig(state);
  const defaultParams = selectDefaultParams(state);
  const recognizedCar = selectRecognizedCar(state);

  //Если задан автомобиль, то переключатель не показывать
  if (defaultParams.brand && defaultParams.model && defaultParams.price){
    return false;
  }

  //Авто распознано другим способом
  if (recognizedCar){
    return false;
  }

  return stock.used.cars && stock.used.cars.length;
};
