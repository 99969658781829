import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Typography, TypographyType } from "components/UI";
import { QuestionaryType } from "helpers/constants";
import { selectAnketaType, selectTheme, selectToken } from "modules/app";
import {
  CHERY_TIGGO8_PRO_TOKEN,
  CHERY_TIGGO8_PRO_FULL_ANKETA_TOKEN,
  OMODA,
} from "../../../../modules/app/utils/cheryTiggo8Pro";
import { CHERY_TIGGO8_PRO_THEME_NAME } from "../../../../modules/app/utils/cheryTiggo8Pro";
import { CHERY_TOKEN, CHERY_ONLINE_TOKEN } from "modules/app/utils/isChery";
import { VOLVO_TOKEN } from "modules/app/utils/isVolvo";
import { KIA_MOTORS_TOKEN } from "modules/app/utils/isKia";
import { HYUNDAI_DOS_TOKEN } from "modules/app/utils/isHyundaiDosProject";
import { EXEED } from "modules/app/utils/cheryTiggo8Pro";
import { isHyundaiRu } from "modules/app/utils/isHyundaiRu";

export const Header = () => {
  const theme = useSelector(selectTheme);
  const token = useSelector(selectToken);
  const anketatype = useSelector(selectAnketaType);

  const params = {
    headerDefault: {
      text: "Получите одобрение кредита за 2 минуты",
      shortFormText: "Получите одобрение кредита за 2 минуты",
    },
    subHeaderDefault: {
      text: null,
      shortFormText: null,
    },
    headerVariants: [
      {
        include: [VOLVO_TOKEN, CHERY_ONLINE_TOKEN],
        text: "Рассчитайте кредит",
        shortFormText: "Рассчитайте кредит",
      },
      {
        include: [CHERY_TOKEN],
        text: "Получите одобрение кредита за 2 минуты",
        shortFormText: "Получите одобрение кредита за 2 минуты",
      },
      {
        include: [
          CHERY_TIGGO8_PRO_THEME_NAME,
          CHERY_TIGGO8_PRO_TOKEN,
          CHERY_TIGGO8_PRO_FULL_ANKETA_TOKEN,
        ],
        text: "Рассчитайте кредит на Chery Tiggo 8 PRO",
        shortFormText: "Рассчитайте кредит на Chery Tiggo 8 PRO",
      },
      {
        include: [KIA_MOTORS_TOKEN, HYUNDAI_DOS_TOKEN],
        text: null,
        shortFormText: null,
      },
      {
        include: [EXEED],
        text: null,
        shortFormText: null,
      },
      {
        include: [OMODA],
        text: "Рассчитать кредит",
        shortFormText: "Рассчитать кредит",
      },
    ],
    subHeaderVariants: [
      // {
      //   include: [VOLVO_TOKEN, CHERY_TOKEN],
      //   text: '1. Выберите автомобиль',
      //   shortFormText: '1. Выберите автомобиль'
      // },
      {
        include: [
          CHERY_TIGGO8_PRO_THEME_NAME,
          CHERY_TIGGO8_PRO_TOKEN,
          CHERY_TIGGO8_PRO_FULL_ANKETA_TOKEN,
        ],
        text: "Получите одобрение автокредита за 2 минуты",
        shortFormText: "Получите одобрение автокредита за 2 минуты",
      },
      {
        include: [KIA_MOTORS_TOKEN],
        text: "Онлайн одобрение кредита",
        shortFormText: "Онлайн одобрение кредита",
      },
      {
        resolverFunc: () => {
          return isHyundaiRu();
        },
        text: null,
        shortFormText: null,
      },
    ],
  };

  const isShort = useCallback(() => {
    return anketatype !== QuestionaryType.SHORT;
  }, [anketatype])();

  const getHeader = useCallback(
    (type = "header") => {
      const data =
        params[`${type}Variants`].find((item) => {
          if (item.resolverFunc) {
            return item.resolverFunc();
          } else {
            return item.include.includes(theme) || item.include.includes(token);
          }
        }) || params[`${type}Default`];

      return isShort ? data?.shortFormText : data?.text;
    },
    [theme, token]
  );

  const header = getHeader("header");
  const subheader = getHeader("subHeader");

  return (
    <>
      {header && (
        <Typography
          className="top-header"
          type={TypographyType.MAIN_HEADER}
          text={header}
        />
      )}
      {subheader && (
        <Typography type={TypographyType.BLOCK_HEADER} text={subheader} />
      )}
    </>
  );
};
