import axios from 'axios';
import {getParameterByName} from "helpers";
import {env} from '../.env.js'

export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';
export const PATCH = 'patch';
export const DELETE = 'delete';


const CancelToken = axios.CancelToken;

const Agent = (cancel) => {
  const {api_url} = env;
  const baseURL = api_url;
  const token = getParameterByName("token");

  let headers = {'Content-Type': 'application/json'}; // { 'Content-Type': 'application/x-www-form-urlencoded' };

  const _agent = axios.create({
    baseURL,
    headers,
  });

  _agent.interceptors.request.use((config) => {
    if (token) {
      config.headers = {
        ...headers,
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    config.cancelToken = new CancelToken((c) => cancel = c);
    return config;
  });

  _agent.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
  );

  return _agent;
};

const agent = Agent();

export default agent;
