import { string, object } from 'yup';

export const validationSchema = object().shape({
  'name': string()
    .required(),
  'email': string()
    .email()
    .required(),
  'question': string()
    .required()
    .min(60)
});
