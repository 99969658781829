import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {Flex} from 'components/layouts';
import {Typography} from 'components/UI/typography/Typography';
import {TypographyType} from 'components/UI';
import {QUESTIONARY_TABS, QuestionaryStepType} from '../constants';

import './questionary-tabs.scss';
import {useSelector} from "react-redux";

const MAIN_STEPS = [
  QuestionaryStepType.INFO,
  QuestionaryStepType.PROF,
  QuestionaryStepType.DOCS,
  QuestionaryStepType.DRIVERS,
];

const isTabHidden = (tab, step) => {
  if (step === tab || tab === QuestionaryStepType.DOCS) {
    return false;
  }

  if (MAIN_STEPS.includes(step)) {
    return !MAIN_STEPS.includes(tab);
  } else {
    return MAIN_STEPS.includes(tab);
  }
}

export function QuestionaryTabs({step}) {
  const isMobile = useSelector((state) => state.app.isMobile);
  return (
    <Flex fullWidth alignCenter className={classnames('questionary-tabs', step)}>
      {
        QUESTIONARY_TABS.map(({name, nameMobile, tab}) =>
          <Typography
            key={name}
            className={classnames('questionary-tabs__tab', {
              'questionary-tabs__tab_active': step === tab,
              'questionary-tabs__tab_hidden': isTabHidden(tab, step)
            })}
            type={TypographyType.PARAGRAPH}
          >
            {isMobile ? nameMobile : name}
          </Typography>
        )}
    </Flex>
  )
}

QuestionaryTabs.propTypes = {
  step: PropTypes.oneOf(Object.values(QuestionaryStepType)).isRequired,
};
