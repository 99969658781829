import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../Modal';

import './info-popup.scss';

export const InfoPopup = ({ isOpen, message, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal className="info-popup" onClose={onClose}>
      <p>{message}</p>
    </Modal>
  );
}

InfoPopup.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
