import React, {useState} from 'react';
import {Button} from 'components/UI/index';
import PropTypes from 'prop-types'
import cn from 'classnames';
import {ReactComponent as MinusIcon} from 'assets/minus.svg';
import {ReactComponent as UploadIcon} from 'assets/upload.svg';
import './styles.scss';

export const FilesUploader = ({
    className = '',
    buttonTitle = 'Загрузить',
    emptyText = 'Нет загруженных файлов',
    allowedFormats = [],
    maxFileSize = 2e+6,
    maxFileSizeMessage = 'Превышен максимально допустимый размер файла',
    emptyFileMessage = 'Передан пустой файл',
    wrongFormatMessage = 'Недопустимый формат файла',
    onChange = () => {},
    onError = () => {}
}) => {
  const [uploaded, setUploaded] = useState([]);

  const openUpload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.accept = allowedFormats.join();

    input.onchange = e => {
      const files = [...e.target.files];
      console.log(files);
      if (!files.every(f => allowedFormats.includes(f.type))) return onError(wrongFormatMessage);
      if (files.some(f => f.size <= 0)) return onError(emptyFileMessage);
      if (files.some(f => f.size > maxFileSize)) return onError(maxFileSizeMessage);
      onChange([...uploaded, ...files]);
      setUploaded([...uploaded, ...files]);
      input.remove();
    }

    input.click();
  }

  const handleRemove = (index) => {
    const dataForUpdate = uploaded.filter((f, i) => i !== index);
    setUploaded(dataForUpdate);
    onChange(dataForUpdate);
  }

  return (<div className={cn(["files-uploader", className])}>
      <div className="files-uploader__list">
        {uploaded.map((file, index) => <div className="files-uploader__list-item" key={index}>
            <div className="files-uploader__file-title">{file.name}</div>
            {file.size && <div className="files-uploader__file-size">{Math.round(file.size / 1000)} кб</div>}
            <MinusIcon className="files-uploader__file-remove" onClick={() => handleRemove(index)}/>
          </div>
        )}
        {!uploaded.length && <div className="files-uploader__empty">{emptyText}</div> }
        <Button className="files-uploader__button" onClick={openUpload}><UploadIcon />{buttonTitle}</Button>
      </div>
    </div>
  );
}

FilesUploader.propTypes = {
  className: PropTypes.string,
  buttonTitle: PropTypes.string,
  emptyText: PropTypes.any,
  allowedFormats: PropTypes.array,
  onChange: PropTypes.func,
  onError: PropTypes.func
}
