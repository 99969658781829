import {request, api, dispatch, isRequestFailed, getState, getPhoneAsNumber, scrollToElement} from 'helpers'
import reducers from 'redux/reducers'
import lists, { employer_type } from 'helpers/lists'
import { ResultType, AppStatusType, FlatType } from 'helpers/constants';
import {
  SubmissionError,
  change,
  startAsyncValidation,
  stopAsyncValidation,
  setSubmitFailed,
  setSubmitSucceeded,
} from 'redux-form';
import { AppOnline, SmsAuthorization, AuthorizationCode, Hyundai, HyundaiMetricSteps } from 'models';
import {selectClientApplicationId, selectCurrentStep} from "modules/app";

import { selectMobilePhone, selectSMSCode, selectRegistrationAddressObject, selectLivingAddressObject } from '../../form';
import { selectSmsForce } from "../selectors/selectSmsForce";
import { selectToken, selectDebugFlag } from 'modules/app';
import { FORM_NAME } from 'modules/form';

import {findOkvedInIndustryBranch, matchCountEmployees} from "helpers/lists";

import { selectEmployerIndustryBranch } from "../../form";

import { gtmSendNewApplication, gtmSendAppOnlineSuccess } from "../../../models/GtmSender";
import { selectDrivers } from "../../form";
import { Metric } from "modules/metrics";
import { NewApp } from "../../../models/NewApp";
import { selectDefaultParams } from "modules/app";
import {selectDealer} from "../../car";
import {graylogNotify} from "../../notify/notify";
import {QuestionaryStepType} from "../../../pages/Main/components/AppForm/constants";

const addDealerIdsToBody = (body) => {

  const dealer = selectDealer(getState());
  body.dealer_id = dealer.id;

  return body;
}


export const newApp = async body => {
  body = addDealerIdsToBody({...body, ...Metric.getMetricClients()});

  const {
    application_id,
    api_application_id,
    id_type,
    client_application_id,
    error_code,
    error_text,
    response
  } = await request.post(api.newapp, { body });

  if (isRequestFailed(error_code)) {
    //Promise.reject(error_text);
    throw new SubmissionError(NewApp.toSubmissionError(response));
  }

  reducers.app.update({ api_application_id, application_id, client_application_id, id_type, success_newapp: true })
  gtmSendNewApplication(application_id, client_application_id);
};

const checkAppStatus = async (body) => {
  body = addDealerIdsToBody(body);

  const { applications } =  await request.post(api.appStatus, { body, noPreloader: true });
  const state = applications[0].state;
  const token = selectToken(getState());
  const debugFlag = selectDebugFlag(getState());

  if (state === AppStatusType.CONSIDERATION) {
    return;
  }

  if (state === AppStatusType.APPROVE) {
    Hyundai.sendMetric(HyundaiMetricSteps.APPLICATION_APPROVED, token, debugFlag);
  }

  if (state === AppStatusType.DECLINE) {
    Hyundai.sendMetric(HyundaiMetricSteps.APPONLINE_ERROR, token, debugFlag);
  }

  if ([AppStatusType.ERROR_IN_FIELD, AppStatusType.UNKNOWN_ERROR, AppStatusType.BACKEND_ERROR].includes(state)) {
    reducers.app.update({ result: ResultType.FAILED });
    return;
  }

  reducers.app.update({ result: ResultType.DONE });
};

export const appOnline = async body => {

  body = addDealerIdsToBody(body);

  const token = selectToken(getState());
  const debugFlag = selectDebugFlag(getState());
  const client_application_id = selectClientApplicationId(getState());
  const step = selectCurrentStep(getState());

  const {
    error_code,
    response,
    insurance_response,
    application_id,
    banks,
  } = await request.post(api.app_online, { body });

  const errors = step === QuestionaryStepType.DOCS
    ? AppOnline.toDocsSubmissionError(response)
    : AppOnline.toDriversSubmissionError(response);

  if (errors) {
    Hyundai.sendMetric(HyundaiMetricSteps.APPONLINE_ERROR, token, debugFlag);
    throw new SubmissionError(errors);
  }

  // if (isRequestFailed(error_code)) {
  //   Hyundai.sendMetric(HyundaiMetricSteps.APPONLINE_ERROR, token, debugFlag);
  //   throw new SubmissionError(AppOnline.toSubmissionError(response));
  // }

  if (body.send_to_bank){
    const cid = selectDefaultParams(getState()).cid;
    gtmSendAppOnlineSuccess(application_id, client_application_id, cid);
  }

  Hyundai.sendMetric(HyundaiMetricSteps.APPONLINE_SUCCESS, token, debugFlag);



  const result = banks && banks.length ? ResultType.WAITING : ResultType.FAILED;
  const kaskoApplicationId = insurance_response ? insurance_response.kasko_application_id : null;
  reducers.app.update({ result, kaskoApplicationId });

  const params =  { application_id, id_type: body.id_type };
  const requestTimeInterval = 10000;
  const { app: { appConfig: { timeout } } } = getState();

  //checkAppStatus(params);

  // let timerId = setTimeout(function tick() {
  //   if (result !== ResultType.WAITING) {
  //     clearTimeout(timerId);
  //     Hyundai.sendMetric(HyundaiMetricSteps.COUNTER_FINISHED, token, debugFlag);
  //   }
  //
  //   checkAppStatus(params);
  //
  //   timerId = setTimeout(tick, requestTimeInterval);
  // }, requestTimeInterval);

  setTimeout(() => {
    const { app: { result } } = getState();
    if (result !== ResultType.DONE) {
      reducers.app.update({ result: ResultType.FAILED });
      Hyundai.sendMetric(HyundaiMetricSteps.COUNTER_FINISHED, token, debugFlag);
    }

    //clearTimeout(timerId);
  }, timeout * 1000);
}

export const appOnlineApplyProf = async (body) => {
  body = addDealerIdsToBody(body);

  const { response } = await request.post(api.app_online, { body });
  const errors = AppOnline.toProfSubmissionError(response);

  if (errors) {
    throw new SubmissionError(errors);
  }
}

let selectedEmployerNameFromDadata = false;

export const employerNameBlur = (e) => {
  const {selectedSuggestion} = e;
  if (!selectedSuggestion) {
    reducers.app.update({showEmployerAddressFields: true});
  }

  if (selectedEmployerNameFromDadata) {
    return;
  }

  const branch = selectEmployerIndustryBranch(getState());

  if (!branch || !selectedEmployerNameFromDadata){
    dispatch(change(FORM_NAME, 'employer_legal_address', ""));
    dispatch(change(FORM_NAME, 'employer_type', ""));
    dispatch(change(FORM_NAME, 'employer_industry_branch', ""));
    dispatch(change(FORM_NAME, 'employer_number_of_employees', ""));
    dispatch(change(FORM_NAME, 'employer_inn', ""));
    reducers.app.update({
      showIndustryBranchFields: true
    })
  }
}

export const employerName = async ({
    value: employer_name,
    data,
  }) => {

    selectedEmployerNameFromDadata = false;

    if (!data) {
      return;
    }

    const debugFlag = selectDebugFlag(getState());

    selectedEmployerNameFromDadata = true;

    const { info } =  await request.get(api.suggestById, { query: {id: data.inn} });
    const employeeCount = matchCountEmployees(info && info.employee_count ? info.employee_count : null);
    const employerBusinessType = data.opf && data.opf.short
      ? lists.employerBusinessType.find(({ name }) => name == data.opf.short)
      : null;

    const { address = {}, inn: employer_inn = "", opf = {}, okved = '' } = data;
    const code = opf.code ? +opf.code[0] : null;
    let type = null

  if (debugFlag){
    console.log('Подсказка дадата:', data);
    console.log('Адрес:', address);
  }

    switch (code) {
      case 1:
      case 3:
      case 5:
      case 6:
        type = employer_type[1]
        break
      case 2:
      case 4:
        type = employer_type[2]
        break
      case 7:
        type = employer_type[0]
        break
    }

    const industryBranch = findOkvedInIndustryBranch(okved);

    // TODO: We must use only one place for saving data

    let showEmployerAddressFields = false;

    if (address.data && !address.data.house){
      showEmployerAddressFields = true;
    }else{
      reducers.app.update({employer_name});
      profAdress(address);
    }

    dispatch(change(FORM_NAME, 'employer_business_type', employerBusinessType));
    dispatch(change(FORM_NAME, 'employer_legal_address', address));
    dispatch(change(FORM_NAME, 'employer_index', address.data ? address.data.postal_code : null));
    dispatch(change(FORM_NAME, 'employer_type', type));
    dispatch(change(FORM_NAME, 'employer_industry_branch', industryBranch));
    dispatch(change(FORM_NAME, 'employer_number_of_employees', employeeCount));
    dispatch(change(FORM_NAME, 'employer_inn', employer_inn));
    reducers.app.update({
      showIndustryBranchFields: !industryBranch && !!employer_name,
      showEmployerAddressFields,
    });
}

export const switchOnShowIndustryBranch = () => {
  reducers.app.update({showIndustryBranchFields: true});
}

export const issuer_code = ({ value, data }) => {
  // TODO: In this case we don't use dadata and mustn't update issuer name. Need to be refactored we must save data in one place
  if (!data) {
    return;
  }

  reducers.app.update({ passport_issuer_name: value });
  dispatch(change(FORM_NAME, 'passport_issuer_name', value));
}

export const prev_issuer_code = ({ data, value }) => {
  // TODO: In this case we don't use dadata and mustn't update issuer name
  if (!data) {
    return;
  }

  reducers.app.update({ previous_issuer_name: value });
  dispatch(change(FORM_NAME, 'previous_issuer_name', value));
}

const changeApartment = (flat, addressName) => {
  const address = addressName ===  'registration_address'
    ? selectRegistrationAddressObject(getState())
    : selectLivingAddressObject(getState());

  if (address && address.data && address.data.flat != flat && address.value){
    const reg = new RegExp(address.data.flat + '$');
    address.data.flat = flat;

    if (reg.test(address.value)){
      address.value = address.value.replace(reg, flat);
    }else{
      address.value += ', ' + flat;
    }

    dispatch(change(FORM_NAME, addressName, address));

    if (address && address.value){
      //Костыль костыльный
      //Как сменить значение поля registration_address???
      //dispatch - меняет значение только в store; само поле смаплено на объект registration_address и пока не жмакнешь
      //по полю с адресом, оно не меняет видимую часть
      const addressInput = addressName ===  'registration_address'
        ? document.querySelector('[data-id="docs_registration_address"]')
        : document.querySelector('[data-id="docs_living_address"]');
      if (addressInput){
        addressInput.value = address.value;
      }
    }

  }
}

export const changeRegistrationApartment = (flat) => {
  changeApartment(flat, 'registration_address');
}

export const changeLivingApartment = (flat) => {
  changeApartment(flat, 'living_address');
}

export const regAdress = ({data}) => {
  if (!data) {
    return;
  }

  const {
    postal_code,
    flat,
  } = data;

  dispatch(change(FORM_NAME, 'apartment_number', flat));
  dispatch(change(FORM_NAME, 'index', postal_code));
}

export const livingAdress = ({data}) => {
  if (!data) {
    return;
  }

  const {
    postal_code,
    flat,
  } = data;

  dispatch(change(FORM_NAME, 'la_apartment_number', flat));
  dispatch(change(FORM_NAME, 'la_index', postal_code));
}

export const profAdress = ({ data }) => {
  if (!data) {
    return;
  }

  const {
    postal_code,
    flat,
    flat_type,
  } = data;

  const office = flat_type === FlatType.OFFICE
    ? flat
    : undefined;

  dispatch(change(FORM_NAME, 'employer_index', postal_code));
  dispatch(change(FORM_NAME, 'employer_apartment_number', office));
}

export const update = data => reducers.app.update({ ...data })

export const sendSMSCode = async () => {
  dispatch(startAsyncValidation(FORM_NAME));
  const response = await request.post(api.authSMS, { body: SmsAuthorization.toBackendModel(
    getPhoneAsNumber(selectMobilePhone(getState())),
    selectSmsForce(getState())
  ), noPreloader: true });

  const errors = SmsAuthorization.toSubmissionError(response);

  if (errors) {
    dispatch(setSubmitFailed(FORM_NAME));
  } else {
    dispatch(setSubmitSucceeded(FORM_NAME));
  }

  dispatch(stopAsyncValidation(FORM_NAME, errors));

  return errors;
}

export const confirmSMSCode = async () => {
  const state = getState();
  const response = await request.get(api.authCode, { query: AuthorizationCode.toBackendModel(
    getPhoneAsNumber(selectMobilePhone(state)),
    selectSMSCode(state),
  ), noPreloader: true });

  const errors = AuthorizationCode.toSubmissionError(response);

  if (errors) {
    dispatch(setSubmitFailed(FORM_NAME));
  } else {
    dispatch(setSubmitSucceeded(FORM_NAME));
    reducers.app.update({ phoneConfirmed: true });
  }

  dispatch(stopAsyncValidation(FORM_NAME, errors));

  return errors;
}

// TODO: We must to use CSS media query instead of it. But It needs big efforts in current implementation
export const setMobile = () => {
  reducers.app.update({
    isMobile: document.documentElement.clientWidth < 720,
  });
}

export const toggleFeedbackForm = () => {
  const { app: { showFeedbackForm } } = getState();
  reducers.app.update({ showFeedbackForm: !showFeedbackForm });
}

export const setApplicationStep = (step) => {
  reducers.app.update({ step });
  scrollToElement("app-form");
}

export const setDealerToAppConfig = (dealer) =>
{
  const { app: { appConfig } } = getState();

  if (!dealer || appConfig.dealer_info === dealer){
    return;
  }

  reducers.app.update({ appConfig: {...appConfig, dealer_info: dealer, partner: dealer.partner} });
}

export const setFormInitializedState = (state) => {
  reducers.app.update({ formInitialized: state });
}

export const saveExperienceYearFromDate = (date, index) => {
  const matches = date.match(/(\d{2})\-(\d{2})\-(\d{4})/);
  if (date && matches){
    const experienceYears = (new Date()).getFullYear() - (new Date(matches[2] + '-' + matches[1] + '-' + matches[3])).getFullYear();
    const drivers = selectDrivers(getState());
    drivers[index].driving_experience = '' + experienceYears;

    const graylogMessage = JSON.stringify({
      date,
      matches,
      experienceYears,
      calcExperienceYears: '' + experienceYears,
      fullYearNow: new Date().getFullYear(),
      userDate: new Date(matches[2] + '-' + matches[1] + '-' + matches[3]),
      userYear: (new Date(matches[2] + '-' + matches[1] + '-' + matches[3])).getFullYear()
    });

    graylogNotify(graylogMessage, 'check_experience', 'saveExperienceYearFromDate')

    dispatch(change(FORM_NAME, 'drivers', drivers));
  }
}

export const initialFormWithClientFields = () => {
  const formData = AppOnline.customClientFieldsToFrontendModel();
  for(let i in formData){
    if (formData[i] && (formData[i].length || (formData[i].value && formData[i].value.length)) ){
      dispatch(change(FORM_NAME, i, formData[i]));
    }
  }

}
