import React from 'react'

import { AlternativeField } from 'components/UI/alternative-field/AlternativeField';
import { Flex } from 'components/layouts'

import './style.sass'


export const Flat = ({
  options,
  label,
  onChange,
  value: currentValue,
  meta,
  name,
}) =>
  <AlternativeField label={label} error={meta && meta.touched ? meta.error : ''}>
    <Flex alignCenter className={'group-container'}  name={name}>
    {
      options.map(({ name, id }) => {
        return (
          <label key={id}>
            <input
              className={'radio'}
              type="radio"
              checked={ id === currentValue }
              onChange={ () => onChange(id) }
            />
            <Flex center className={'input_pseudo'}>
              {
                id === currentValue && <div className={'dot'}></div>
              }
            </Flex>
            <span className="radio__label">{name}</span>
          </label>
        );
      })
    }
    </Flex>
  </AlternativeField>


export const Redux = ({ input, ...props }) => (
  <Flat {...input} {...props} />
)
