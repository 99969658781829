import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'redaction'
import {dictionary, car, app} from 'redux/actions'

import {Flex} from 'components/layouts'
import {Grid} from 'components/UI/grid/Grid';
import {Select, RadioGroup} from 'components/reduxForm'
import {Slider, Offers, AppForm} from './components'
import {FAQ} from 'modules/FAQ';
import {Introduction} from './components/introduction/Introduction';
import {Feedback} from './components/feedback/Feedback';
import {Typography, TypographyType} from 'components/UI';
import {Partners} from './components/introduction/components/partners/Partners';
import {CHERY_TIGGO8_PRO_THEME_NAME, isAnyChery} from "../../modules/app/utils/cheryTiggo8Pro";
import {isCheryTiggo8Pro} from "../../modules/app/utils/cheryTiggo8Pro";
import {isJaecoo} from "../../modules/app/utils/isJaecoo";

import {
  QUESTIONNAIRE_ELEMENT_ID,
  SELECT_CAR_ELEMENT_ID,
  SPECIFIC_DESIGN_BRANDS,
  SwitchType
} from 'helpers/constants';
import {goToNextFormFieldByEnter} from 'components/form/goToNextFieldByEnter';
import {scrollToElement} from 'helpers';
import {selectToken, selectIntercomSettings, selectDebugFlag} from 'modules/app';
import {Calculator} from "models/calculator";

import {DebugPanel} from "./components/AppForm/debug-panel/DebugPanel";

import {Hyundai, HyundaiMetricSteps} from "models";

import './styles.sass';
import {reInitCarSelects} from "modules/app/utils/reInitCarSelects";
import {isReInitCarSwitcherAvailable} from "modules/app/utils/isReInitCarSwitcherAvailable";
import {selectCarCondition} from "modules/app";
import {selectTheme} from "modules/app";
import { selectShowIntro } from "modules/app";
import { selectAnketaType } from "modules/app/selectors/selectAnketaType";
import { GosProgram } from "./components/gos-program/GosProgram";
import {Header} from "pages/Main/components/Header";
import {EquipmentSelector} from "pages/Main/components/EquipmentSelector";
import {AnketaHeader} from "./components/Header/AnketaHeader";

const Container = connect({
  brands: 'dictionary.brands',
  models: 'dictionary.models',
  configs: 'dictionary.configs',
  brand: 'car.brand',
  model: 'car.model',
  config: 'car.config',
  car: 'car',
  appConfig: 'app.appConfig',
  defaultParams: 'app.defaultParams',
  isMobile: 'app.isMobile',
  showFeedbackForm: 'app.showFeedbackForm',
  token: selectToken,
  debugFlag: selectDebugFlag,
  intercomSettings: selectIntercomSettings,
  step: 'app.step',
  carCondition: selectCarCondition,
  theme: selectTheme,
  showIntro: selectShowIntro,
  anketatype: selectAnketaType,
})(
  class ContainerComponent extends PureComponent {
    static propTypes = {
      isMobile: PropTypes.bool,
      showFeedbackFrom: PropTypes.bool,
    }

    state = {filteredModel: []}

    setPrice = async () => {
      if (this.props.brand && this.props.model && this.props.config) {
        this.calcFees();
      }
    }

    changeBrandHandler = () => {
      car.update({price: 0, initial_fee_money: 0, initial_fee: 30, offers: []});
      car.update({model: {}, config: {}});
      app.update({defaultParams: {...this.props.defaultParams, brand: '', model: '', configuration: ''}});
      dictionary.getModels(this.props.brand.id);
    }

    changeModelHandler = () => {
      car.update({
        price: 0,
        initial_fee_money: 0,
        initial_fee: 30,
        offers: [],
        config: {price: 0}
      });

      dictionary.getConfigs({
        id: this.props.model.id,
        brand_id: this.props.brand.id
      });
    }

    componentDidMount() {
      document.addEventListener('keydown', goToNextFormFieldByEnter);
    }

    componentWillUnmount() {
      document.removeEventListener('keydown', goToNextFormFieldByEnter);
    }

    componentDidUpdate(prevProps) {
      const {model, brand} = this.props;

      if (brand && model && this.props.config && !prevProps.config) {
        this.calcFees();
      }
    }

    goToQuestionnaire = () => {
      const elementId = this.props.appConfig.blocks.calculator.show === 0
        ? QUESTIONNAIRE_ELEMENT_ID
        : SELECT_CAR_ELEMENT_ID;
      scrollToElement(elementId);
    }

    calcFees(currentPrice) {
      const {
        config,
        brand,
        model,
        car: {
          initial_fee: carInitialFee,
          initial_fee_money: carInitialFeeMoney,
        },
      } = this.props;

      const {
        model: defaultModel,
        brand: defaultBrand,
        configuration: defaultConfiguration,
        price: defaultPrice,
      } = this.props.defaultParams;

      const configName = config ? config.name : '';
      const configPrice = config ? config.price : 0;
      const brandName = brand ? brand.name : '';
      const modelName = model ? model.name : '';

      const defaultCar = `${defaultBrand}${defaultModel}${defaultConfiguration}`.trim().toUpperCase();
      const selectedCar = `${brandName}${modelName}${configName}`.trim().toUpperCase();

      const price = currentPrice || (defaultCar === selectedCar && defaultPrice
        ? defaultPrice
        : configPrice);


      if (!price) {
        return;
      }

      let initialFeeMoney = price * (carInitialFee / 100);
      let initialFee = carInitialFee;

      if (defaultCar === selectedCar && carInitialFeeMoney) {
        initialFeeMoney = carInitialFeeMoney;
        initialFee = Math.floor(carInitialFeeMoney / (price / 100));
      }

      car.update({price, initial_fee_money: initialFeeMoney, initial_fee: initialFee});
      // Чтобы запрашивались офферы при ручном выборе автомобиля
      car.getOffers();
    }

    handleHelpLinkClick = () => {
      const {token, debugFlag} = this.props;
      Hyundai.sendMetric(HyundaiMetricSteps.CHAT_BUTTON_CLICKED, token, debugFlag);
    }

    handleSetPrice = ({price, id, name, brand_id, model_id}) => {
      car.update({config: {...this.props.config, id, name, price, brand_id, model_id}})
      this.calcFees(price);
    }

    render() {
      const {
        brands,
        models,
        configs,
        brand,
        model,
        config,
        appConfig,
        isMobile,
        showFeedbackForm,
        car,
        token,
        step,
        carCondition,
        theme,
        showIntro,
      } = this.props
      const {disableCarSelects} = car;
      //const price = config && config.price ? {id: '1', name: config.price} : undefined;
      const isCalculatorEnabledForStep = Calculator.isCalculatorEnabledOnStep(step);

      const carSourceRadioList = [
        {
          "id": 1,
          "name": "Новые"
        },
        {
          "id": 2,
          "name": "С пробегом"
        }
      ];

      const changeCarSource = (carSourceId) => {
        reInitCarSelects(carSourceId);
      };

      const switcherAvailable = isReInitCarSwitcherAvailable();
      const isCheryTiggo = theme === CHERY_TIGGO8_PRO_THEME_NAME || isCheryTiggo8Pro();

      const showCarSelector = appConfig.blocks.calculator &&
        appConfig.blocks.calculator.show === SwitchType.ON;

      return (
        <Flex column className={'container'}>
          <Header />
          {isCheryTiggo && (
            <EquipmentSelector onChange={this.handleSetPrice} />
          )}

          {appConfig.blocks.calculator && appConfig.blocks.calculator.show === SwitchType.ON && (
            <section className="calc">
              {!isCheryTiggo && showCarSelector && <>
                {!(isAnyChery() || isJaecoo()) &&
                  <Grid className={switcherAvailable ? '' : 'hidden'}>
                    <RadioGroup.Flat
                      label="Автомобили:"
                      name="carsource"
                      options={carSourceRadioList}
                      onChange={changeCarSource}
                      value={carCondition}
                    />
                  </Grid>
                }
                <Typography type={TypographyType.BLOCK_HEADER} text="1. Выберите автомобиль" />
                <Grid>
                  <Select.Flat
                    width="3"
                    wBorder
                    label="Марка"
                    name="brand"
                    options={brands}
                    selected={brand}
                    module="car"
                    field="brand"
                    onChange={this.changeBrandHandler}
                    defaultValue="Выберите марку"
                    disabled={SPECIFIC_DESIGN_BRANDS.includes(token) || brands.length < 2 || disableCarSelects ? true : false}
                  />
                  <Select.Flat
                    width="3"
                    wBorder
                    label="Модель"
                    name="model"
                    options={models}
                    selected={model}
                    module="car"
                    field="model"
                    onChange={this.changeModelHandler}
                    defaultValue="Выберите модель"
                    disabled={Boolean(disableCarSelects)}
                  />
                  <Select.Flat
                    className="complexity"
                    width="6"
                    wBorder
                    label="Комплектация"
                    name="configuration"
                    options={configs}
                    selected={config}
                    module="car"
                    field="config"
                    onChange={this.setPrice}
                    defaultValue="Выберите комплектацию"
                    disabled={Boolean(disableCarSelects)}
                  />
                </Grid>
              </>}

              <Slider isSliderEnabled={isCalculatorEnabledForStep}/>
              <Typography
                type={TypographyType.BLOCK_HEADER}
                text="2. Выберите комфортный платеж"
                className="main__initial-fee-header"
              />
              {config && config.name && (
                <>
                  <Flex
                    column={isMobile}
                    fullWidth={isMobile}
                    center
                    className={`offer_line ${isCalculatorEnabledForStep ? '' : 'blocked'}`}
                  >
                    <GosProgram programsVisible={car.gosProgramsAvailable} programsEnabled={isCalculatorEnabledForStep}></GosProgram>
                    {/* <Checkbox.Flat
                      label="КАСКО включено в кредит"
                      style={{ width: '256px' }}
                    />
                    <Checkbox.Flat
                      label="Платежи по кредиту защищены"
                      style={{ width: '256px' }}
                    /> */}
                  </Flex>
                </>
              )}
              <Offers isCarouselEnabled={isCalculatorEnabledForStep}/>
            </section>
          )}

          <AnketaHeader />

          {showIntro === SwitchType.ON && <Introduction />}
          <AppForm/>
          <section className="partners-section">
            <Partners/>
          </section>
          {appConfig.blocks.faq && appConfig.blocks.faq.show === SwitchType.ON && <FAQ/>}
          {/*{appConfig.blocks.help && appConfig.blocks.help.show === SwitchType.ON &&*/}
          {/*(<Flex column center className="text">*/}
          {/*  <p>Остались вопросы?</p>*/}
          {/*  <p onClick={app.toggleFeedbackForm}>Напишите нам</p>*/}
          {/*</Flex>)*/}
          {/*}*/}
          <Feedback isOpen={showFeedbackForm}/>
          <DebugPanel/>
        </Flex>
      )
    }
  }
)

export const Main = () => <Container/>
