class GoogleAnalytics
{
  static getClientId()
  {
    return typeof window.ga !== undefined && typeof window.ga.getAll === "function"
      ? window.ga.getAll()[0].get('clientId')
      : null;
  }
}

export  { GoogleAnalytics };
