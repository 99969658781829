import 'react-app-polyfill/ie9'
import 'core-js/features/global-this';
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { App } from 'containers/App'
import { store } from 'redux/store'
import * as serviceWorker from './serviceWorker'
import smoothscroll from "smoothscroll-polyfill"
import { env } from '.env';
import 'styles/index.scss'
import * as Sentry from "@sentry/react";
import { $_GET } from "./modules/app/utils/utils";
import { NotWorkingPage } from "./pages/notWorkingPage/not-working-page";

//if (env.envName != 'dev'){
//   Sentry.init({
//     dsn: "https://409d319dc0664e50966d0a3bd06e3a2a@o375882.ingest.sentry.io/5195990",
//     environment: env.envName
//   });
//}

let block = false;

if (env.blocked) {
  const params = env.blocked;
  const token = $_GET('token');

  if (params.token && params.token.length && params.token.includes(token)){
    block = true;
    console.log('Стоп по токену');
  }

  if (params.host && params.host.length && params.host.includes(window.location.hostname)){
    block = true;
    console.log('Стоп по хосту');
  }

  if (params.all){
    block = true;
    console.log('Стоп для всех');
  }

}

smoothscroll.polyfill()

ReactDOM.render(
  <Provider store={store}>
    {
      block ? <NotWorkingPage/> : <App/>
    }
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
