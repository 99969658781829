import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'components/UI';
import { Banks } from '../banks/Banks';

import { selectBirthDate, selectFullName } from 'modules/form';

export const CreditHistoryAgreement = () => {
  const birthDate = useSelector(selectBirthDate) || '_______________';
  const fullName = useSelector(selectFullName).trim() || '_______________';

  return (
    <div className="credit-history-agreement">
      <Typography text="Cогласие клиента на взаимодействие с Бюро кредитных историй" />
      <p>
        {`Я, ${fullName}, дата рождения ${birthDate}, настоящим даю свое согласие в соответствие с Федеральным законом от 30.12.2004 №218-ФЗ «О кредитных историях», на получение кредитного отчёта, сформированного на основании моей кредитной истории, и иной информации обо мне из любых бюро кредитных историй следующим юридическим лицам и/или индивидуальным предпринимателям (далее «Пользователь» или при совместном упоминании «Пользователи»):`}
      </p>
      <table>
        <tbody>
          <Banks />
        </tbody>
      </table>
      <p>
        Настоящее согласие дано Пользователю в целях проверки моей благонадежности, оценки кредитоспособности и рассмотрения вопроса и принятия решения о заключении кредитного договора, договора о выдаче займа Пользователем, принятия решения по заключению договору залога имущества в обеспечение по договору займа с Пользователем, принятия решения о выдаче поручительства, заключенного в обеспечение по договору займа с Пользователем, а также для анализа возможности предложения мне новых банковских услуг.
      </p>
      <p>
        Подписывая настоящее Согласие я предоставляю право Пользователям на получение обо мне информации из любых Бюро кредитных историй (одного или нескольких), содержащейся в основной части моей кредитной истории, в объеме и порядке, которые предусмотрены Федеральным законом от 30.12.2004 № 218-ФЗ «О кредитных историях».
      </p>
      <p>
        Согласие предоставлено в целях проверки сведений, предоставленных мной в Банк при приеме на банковское обслуживание и в процессе оказания мне банковских услуг, а также с целью формирования Банком для меня предложений по кредитным и иным банковским продуктам.
      </p>
      <p>
        Данное согласие действует в течение шести месяцев со дня его оформления и, в случае, если в течение указанного срока между Банком и Клиентом будет заключен кредитный договор, сохраняет силу в течение всего срока действия кредитного договора.
      </p>
      <p>
        Право выбора Бюро кредитных историй предоставляется мной Банку по его усмотрению и дополнительного согласования со мной не требуется.
      </p>
      <p></p>
      <p>
        Я подтверждаю, что, давая такое согласие на получение кредитных отчётов, действую по собственной воле и в своих интересах.
      </p>
    </div>
  );
};
