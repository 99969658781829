import React from 'react'
import { connect } from 'redaction'
import cx from 'classnames'
import { app } from 'redux/actions'
import { Flex } from 'components/layouts'

import styles from './styles.module.scss'


export const Switch = connect({
  gender: 'app.gender',
})(
  ({ gender, label }) => {
    const isMale = gender === 1
    const toggle = () => app.update({ gender: isMale ? 2 : 1 })

    return (
      <Flex column className={styles.container}>
        <div className={styles.label}>{label}</div>
        <Flex alignCenter justifyBetween className={styles.wrapper}>
          <div className={cx( isMale && styles.active )} onClick={toggle}>М</div>
          <Flex
            className={cx( styles.switcher, !isMale && styles.active )}
            onClick={toggle}
            alignCenter
            justifyBetween
            relative>
              <div className={styles.toggle} />
          </Flex>
          <div className={cx( !isMale && styles.active )} onClick={toggle}>Ж</div>
        </Flex>
      </Flex>
    )
  }
)
