import React from 'react';
import PropTypes from 'prop-types';
import { sendNotify } from "../../modules/notify/notify";
import { getState } from "../../helpers";
import { selectCurrentStep } from "../../modules/app";
import { selectToken } from "../../modules/app";

import './error-boundary.scss';

export class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  }

  state = { hasError: false };

  static getDerivedStateFromError(/* error */) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    const token = selectToken(getState());
    const step = selectCurrentStep(getState());
    let message = "Ошибка фронта\ntoken=" + token + "\nstep=" + step + "\nERROR=" + error.message;
    message += "\n\nstack trace:\n\n" + error.stack+"\n\n";
    sendNotify(message, null, false);
    console.log({ error, info });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <h1 className="error-boundary">Что-то пошло не так попробуйте повторить попытку позже</h1>
      )
    }

    return this.props.children;
  }
}
