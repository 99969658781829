import * as commonReducers from 'helpers/commonReducers'


const initialState = {
  brands: [],
  models: [],
  configs: [],
  dealers: [],
  brand: null,
  model: null,
  config:null,
}

export default {
  initialState,
  ...commonReducers,
}
