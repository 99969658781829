import {sendNotify} from "../../../../../../modules/notify/notify";
import {getState} from "helpers";
import {selectCustomerInfo} from "modules/customer";

export const sendAlertIfKaskoPriceEmpty = async (kasagoResponse) =>
{
  const customerInfo = selectCustomerInfo(getState());

  if (!kasagoResponse.kasko_price || kasagoResponse.kasko_price == 0){
    await sendNotify("kasko_price=0 на финальном расчете. \nКлиент  - " + customerInfo.fullName);
  }
}
