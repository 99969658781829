import React from 'react';
import PropTypes from 'prop-types';
import { AgreementType } from './constants';
import {
  PersonalDataAgreementFactory,
  CreditHistoryAgreement,
  ElectronicDocumentManagementAgreement,
  ClientAgreement,
  PersonalDataProcessingPolicy,
} from './components'

export const AgreementFactory = ({ type }) => {
  switch (type) {
    case AgreementType.PERSONAL_DATA:
      return <PersonalDataAgreementFactory />;
    case AgreementType.CREDIT_BUREAU:
      return <CreditHistoryAgreement />
    case AgreementType.ELECTRONIC_DOCUMENT_MANAGEMENT:
      return <ElectronicDocumentManagementAgreement />
    case AgreementType.CLIENT_AGREEMENT:
      return <ClientAgreement />
    case AgreementType.PERSONAL_DATA_PROCESSING_POLICY:
      return <PersonalDataProcessingPolicy />
    default:
      return null;
  }
}

AgreementFactory.propTypes = {
  type: PropTypes.oneOf(Object.values(AgreementType)),
};
