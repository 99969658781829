import reducers from 'redux/reducers';
import { CarConditionType } from "helpers/constants";

const availableCars = {
  'kia' : ['rio'],
  'volkswagen' : ['polo'],
  'skoda': ['rapid'],
  'škoda': ['rapid'],
  'hyundai': ['creta','solaris'],
  'chevrolet': ['2123'],
  'nive': ['2123'],
  'lada': ['2123','4x4','granta','largus','xray','vesta'],
  'лада': ['2123','4x4','granta','largus','xray','vesta'],
  'ваз': ['2123','4x4','granta','largus','xray','vesta'],
  'gaz': ['соболь','газель','sobol','2217','2310','2752','3302','2705','3321','a21r','a60r','a62r','a63r','a64r','a65r','a66r','a67r','a68r','a69r','',''],
  'газ': ['соболь','газель','sobol','2217','2310','2752','3302','2705','3321','a21r','a60r','a62r','a63r','a64r','a65r','a66r','a67r','a68r','a69r','',''],
  'renault': ['logan','sandero','duster','kaptur'],
  'uaz': ['патриот','patriot','hanter','хантер','пикап','pickap','профи','profi','3303','3741','3909','3962','2206']
};

const MAX_AVAILABLE_PRICE = 1500000;

const normilize = item => item.toUpperCase();

const normilizeSearchModel = item => {
  item = item.replace(/^[а-яё -]+/i,'');
  return item.toUpperCase();
}

const isModelExists = (dictionaryModel, searchModel) => normilize(dictionaryModel).indexOf(normilizeSearchModel(searchModel)) > -1;

const setGosProgramAvailable = (brandName, modelName, price, carCondition) => {
  const brand = availableCars[brandName.toLowerCase()] || [];
  const gosProgramsAvailable = carCondition === CarConditionType.USED
    ? false
    : brand.some(model => isModelExists(model, modelName)) && price < MAX_AVAILABLE_PRICE;
  reducers.car.update({ gosProgramsAvailable });
};

export { setGosProgramAvailable };
