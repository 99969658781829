export const QuestionaryType = {
  SHORT: "short",
  FULL: "full",
}

export const EmploymentType = {
  EMPLOYEE: 1,
  INDIVIDUAL_ENTERPRENEUER: 2,
  BUSINESS_OWNER: 3,
  WORKING_PENSIONER: 4,
  UNEMPLOYED_MILITARY_PENSIONER: 5,
  UNEMPLOYED_PENSIONER: 6,
  MATERNITY_LEAVE: 7,
};

export const EmploymentSpouseType = {
  EMPLOYEE: 1,
  WORKING: 2,
  WORKING_PENSIONER: 3,
  NOT_WORKING: 4
};

export const ResultType = {
  WAITING: "waiting",
  FAILED: "failed",
  DONE: "done",
};


export const AppStatusType = {
  BACKEND_ERROR: 0,
  ERROR_IN_FIELD: 1,
  INPUT_SMS: 2,
  REQUEST_SMS: 3,
  CONSIDERATION: 4,
  DECISION: 5,
  APPROVE: 6,
  DECLINE: 7,
  TIMEOUT: 8,
  UNKNOWN_ERROR: 9,
  REJECT: 10,
};

export const CarConditionType = {
  NEW: 1,
  USED: 2
};


export const ResponseCodeType = {
  SUCCESS: 200,
  INVALID_PHONE_NUMBER: 251,
  PHONE_NOT_FOUND: 252,
  PHONE_CHECK_SERVER_ERROR: 253,
  SMS_CODE_EXPIRED: 254,
  SMS_CODE_INVALID: 255,
};

export const ResponseErrorText = {
  [ResponseCodeType.INVALID_PHONE_NUMBER]: 'Указан невалидный номер телефона',
  [ResponseCodeType.PHONE_NOT_FOUND]: 'Телефон не найден',
  [ResponseCodeType.PHONE_CHECK_SERVER_ERROR]: 'Внутренняя ошибка проверки телефона в базе данных',
  [ResponseCodeType.SMS_CODE_EXPIRED]: 'Код просрочен. Необходимо запросить новый код',
  [ResponseCodeType.SMS_CODE_INVALID]: 'Указан неверный код'
};

export const SwitchType = {
  ON: 1,
  OFF: 0,
};

export const DealerIdType = {
  ECREDIT: 0,
  HOLDING: 1,
  MANUFACTURE: 2
};

export const USED_CAR_CONFIGURATION_DEFAULT = 'Комплектация не задана'
export const NEW_CAR_CONFIGURATION_DEFAULT = 'Комплектация не задана'

export const DEFAULT_INITIAL_FEE = 30;

export const MaritalStatus = {
  UNMARRIED: 1,
  MARRIED: 2,
  DIVORCE: 3,
  WINDOWER: 4
};

export const CarSourceIdType = {
  NEW: 21,
  USED: 999,
}


export const WorkingLifeDateType = {
  LESS_6_MONTHS: 'менее 6 месяцев',
  FROM_6_TO_12_MONTHS: 'от 6 месяцев до 1 года',
  FROM_1_TO_3_YEARS: 'от 1 года до 3 лет',
  FROM_3_TO_5_YEARS: 'от 3 лет до 5 лет',
  FROM_5_TO_10_YEARS: 'от 5 лет до 10 лет',
  MORE_10_YEARS: 'более 10 лет',
};

export const QUESTIONNAIRE_ELEMENT_ID = 'questionnaireElement';
export const SELECT_CAR_ELEMENT_ID = 'selectCarElement';
export const THANK_YOU_ELEMENT_ID = 'thankYouElement';
export const CONFIRM_PHONE_SMS_CODE_INPUT_ID = 'confirmPhoneSmsCodeInputElement';
export const DRIVERS_ELEMENT_ID = 'driversElement';
export const FINAL_CONDITIONS_ELEMENT_ID = 'finalConditions';

export const GenderType = {
  FEMALE: 2,
  MALE: 1,
}

export const SPECIFIC_DESIGN_BRANDS = ['mazda', 'kiamotors'];
export const HIDDEN_AUTO_PRICE_BY_BRANDS = ['kiamotors', 'mazda'];

export const APPLICATION_STATUS = {
  CREDIT_CALCULATION: 1,
  NEW: 2,
  APPROVED: 6
};
