import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {selectCarCondition, selectToken} from 'modules/app';
import {IntroductionStep} from './components/introduction-step';

import './introduction.scss';
import {selectCarBrandName} from "modules/car";
import {onlineBanks} from "api/online-banks";
import {IntroductionHeader} from "pages/Main/components/introduction/components/introduction-header/IntroductionHeader";

export const Introduction = () => {
  const [banks, setBanks] = useState([]);
  const brandName = useSelector(selectCarBrandName);
  const carCondition = useSelector(selectCarCondition);
  const token = useSelector(selectToken);

  useEffect(() => {
    const loadOnlineBanks = async () => {
      //const { banks } = await onlineBanks.loadBanks(carCondition, brandName);
      const {banks} = await onlineBanks.loadBanks();
      setBanks(banks);
    };
    loadOnlineBanks();
  }, [brandName, carCondition]);


  const steps = React.useMemo(() => [{
    step: 1,
    text: "Заполните анкету на кредит",
  }, {
    step: 2,
    text: `Получите одобрение ${banks && banks.length === 1 ? 'банка' : 'банков'}${token !== 'kiamotors' ? ' за 2 минуты' : ''}`,
  }, {
    step: 3,
    text: "Приходите в дилерский центр с одобренным кредитом",
  }], [banks]);

  return (
    <div className="introduction">
      {/*<IntroductionHeader />*/}
      <div className="introduction__content">
        <div className="introduction__steps">
          {steps.map(({step, text}) => <IntroductionStep key={step} step={step} text={text}/>)}
        </div>
      </div>
    </div>
  );
}
