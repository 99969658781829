import { InputType, InputModeType } from "./constants";

export const setInputMode = (type) => {
  switch (type) {
    case InputType.NUMBER:
      return InputModeType.NUMERIC;
    case InputType.EMAIL:
      return InputModeType.EMAIL;
    case InputType.TEL:
      return InputModeType.TEL;
    default:
      return InputModeType.TEXT;
  }
}
