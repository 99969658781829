import React from 'react';
import { useSelector } from "react-redux";
import { selectDebugData } from "modules/app/selectors";
import { getApiCredentials } from "../../../../../modules/app/utils/getApiCredentials";
import { selectApplicationId } from "modules/app/selectors";
import { selectClientApplicationId } from "modules/app/selectors";
import { selectApiApplicationId } from "modules/app/selectors";
import { isProcessKasko } from "modules/app/utils/isProcessKasko";

import './debug-panel.scss';

export const DebugPanel = (props) => {
  const debug = useSelector(selectDebugData);
  const processKasko = isProcessKasko();
  const applicationId = useSelector(selectApplicationId);
  const clientApplicationId = useSelector(selectClientApplicationId);
  const apiApplicationId = useSelector(selectApiApplicationId);
  const { apiUrl, token } = getApiCredentials();

  return (
    debug.show == 1 && <div className="debug-panel">
      <b>Включен режим отладки - флаг debug=1 в URL</b>
      <hr/>
      <table className="debug-panel__table">
        <tbody>
        <tr>
          <td>Режим наполнения моковыми данными [ параметр &mock=1 ]</td>
          <td>{debug.mock ? "Включен" : "Выключен"}</td>
        </tr>
        <tr>
          <td>Режим интеграции с сервисом страхования КАСАГО [ параметр &process_kasko=1 ]</td>
          <td>{processKasko ? "Включен" : "Выключен"}</td>
        </tr>
        <tr>
          <td>Кастоные данные для интеграции с сервисом страхования КАСАГО</td>
          <td>{debug.error_in_params}</td>
        </tr>
        <tr>
          <td>API Application ID</td>
          <td>{apiApplicationId}</td>
        </tr>
        <tr>
          <td>Application ID</td>
          <td>{applicationId}</td>
        </tr>
        <tr>
          <td>client_application_id</td>
          <td>{clientApplicationId}</td>
        </tr>
        <tr>
          <td>API url</td>
          <td><a className="debug-panel__link" href={apiUrl.replace('/v1','')} target="_blank">{apiUrl.replace('/v1','')}</a></td>
        </tr>
        <tr>
          <td>token</td>
          <td>{token}</td>
        </tr>
        <tr>
          <td>environment</td>
          <td>{debug.environment}</td>
        </tr>
        <tr>
          <td>ecredit_url</td>
          <td><a className="debug-panel__link" href={debug.ecredit_url} target="_blank">{debug.ecredit_url}</a></td>
        </tr>
        </tbody>
      </table>
      <div className="info">
        <b>Кастомные параметры для сервиса КАСАГО можно задать так (подставить строку в URL):</b>
        <br/>
        &process_kasko=1&engine_volume=2.5&engine_power=150&transmission_type=ATF&pts_series=1125&pts_number=123456&pts_issue_date=2019-01-01&car_issue_date=2019-01-01&vin=KMHBT31GP3U013758
        <br/>
        process_kasko=1 - включение режима КАСАГО, а все остальное - доп параметры
      </div>
    </div>
  );
};
