import { getState } from "helpers";
import { selectToken } from "../selectors";
import { isProcessKasko } from "./isProcessKasko";

export const HYUNDAI_DOS_TOKEN = 'hyundaionline';

export const isHyundaiDosProject = () => {
    const state = getState();
    const token = selectToken(state);

    return token == HYUNDAI_DOS_TOKEN && isProcessKasko();
};
