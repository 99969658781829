import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { formatCurrency } from "helpers";
import { selectCurrentOffer } from "modules/car/selectors";
import { useSelector } from "react-redux";

import "./final-conditions-info-block.scss";
import { FinalConditionsDetails } from '../details/FinalConditionsDetails';

export function FinalConditionsInfoBlock({ header, amount, details, plus, className, children, help }) {

  const currentOffer = useSelector(selectCurrentOffer);

  return (
    <div className={classnames("final-conditions-info-block", className)}>
      <p className="final-conditions-info-block__header">
        {header}
        {
          help &&
          <span title={help} style={{cursor: "pointer", marginLeft: "5px", marginTop: "10px"}}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="18" height="18" rx="9" fill="#C4C4C4"></rect><path d="M7.52 9.892H8.78V9.738C8.78 8.912 9.284 8.632 9.816 8.31C10.74 7.75 11.692 7.134 11.692 5.776C11.692 4.334 10.432 3.354 8.598 3.354C7.716 3.354 6.862 3.536 6.26 3.928L6.078 5.188H6.274C6.904 4.684 7.52 4.46 8.15 4.46C9.046 4.46 9.662 4.88 9.662 5.86C9.662 6.728 9.172 7.204 8.654 7.694C8.122 8.198 7.52 8.688 7.52 9.584V9.892ZM9.214 11.026H7.282V13.042H9.214V11.026Z" fill="black"></path></svg>
          </span>
        }
      </p>
      <p className="final-conditions-info-block__info">
        {plus && <span className="final-conditions-info-block__plus">+</span>}
        <span className="final-conditions-info-block__sum">
          {/*<span>{amount.toLocaleString('ru-RU')}</span>*/}
          <span>{formatCurrency(amount)}</span>
          <span className="final-conditions-info-block__units"> ₽/мес.</span>
        </span>
      </p>
      {children}
    </div>
  );
}

FinalConditionsInfoBlock.propTypes = {
  header: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  details: PropTypes.string,
  plus: PropTypes.bool,
  className: PropTypes.string,
};
