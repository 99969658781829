import { createApplicationFormSelector } from './createApplicationFormSelector';

export const selectFullNameAsObject = (state) => {
    const name = createApplicationFormSelector(state, 'name') || '';
    const middle_name = createApplicationFormSelector(state, 'middle_name') || '';
    const surname = createApplicationFormSelector(state, 'surname') || '';
    return {
        surname,
        name,
        middle_name,
    };
};
