export const InputType = {
  TEXT: 'text',
	NUMBER: 'number',
	CHECKBOX: 'checkbox',
	PASSWORD: 'password',
	HIDDEN: 'hidden',
	RANGE: 'range',
	RADIO: 'radio',
	TEL: 'tel',
	EMAIL: 'email',
}

export const InputModeType = {
	NONE: 'none',
	TEXT: 'text',
	DECIMAL: 'decimal',
	NUMERIC: 'numeric',
	TEL: 'tel',
	SEARCH: 'search',
	EMAIL: 'email',
	URL: 'url',
}
