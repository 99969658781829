import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { selectCar } from "modules/car";
import { choseProgram } from "modules/car";
import { BuyBack } from "./components/BuyBack";
import { GosProgramCheckbox } from "./components/GosProgramCheckbox";
import { getVisibleGosProgramsList } from "../../../../modules/app/utils/getVisibleGosProgramsList";
import { isHyundaiDosProject } from "../../../../modules/app/utils/isHyundaiDosProject";

export const GosProgram = ({ programsVisible, programsEnabled }) => {
  const car = useSelector(selectCar);
  const buyBackCheckbox = car.buyBackCheckbox;
  const visibleProgramsList = getVisibleGosProgramsList();

  const handleProgram = (evt) => {
    const { name } = evt.target;
    choseProgram(name);
  }

  return (
    <>
      {
        !isHyundaiDosProject() && <BuyBack programsEnabled={programsEnabled} handleProgram={handleProgram} buyBackCheckbox={buyBackCheckbox} />
      }
        {
          programsVisible &&
          visibleProgramsList.map( (gosProgramName) => {
            return <GosProgramCheckbox programsEnabled={programsEnabled} handleProgram={handleProgram} nameCheckbox={gosProgramName} />;
          })
        }
    </>
  );
};

GosProgram.propTypes = {
  programsVisible: PropTypes.bool,
  programsEnabled: PropTypes.bool
};
