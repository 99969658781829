import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";

import { selectToken } from "modules/app";

import { Typography } from "components/UI";

import RusFinanceIcon from "./icons/rusfinance.svg";
import SovcomBank from "./icons/sovkombank.svg";
import SovcomBankWhite from "./icons/sovkombank-white.png";
import VTBIcon from "./icons/vtb.svg";
import TinkoffIcon from "./icons/tinkoff.svg";
import MCBankRus from "./icons/mc-bank-rus.svg";
import SetelemIcon from "./icons/setelem.png";
import UnicreditIcon from "./icons/unicredit.svg";
import RosbankIcon from "./icons/rosbank.png";
import RosbankWhiteIcon from "./icons/rosbank-white.png";
import {kiaMotorsFilterIntroBank, cherryNewFilterIntroBank, omodaFilterIntroBank} from "modules/app/utils/isKia";
import BmwIcon from "./icons/bmw.svg";
//import VolkswagenIcon from "./icons/volkswagen.png";
import VolkswagenIcon from "./icons/volkswagen.png";
import KebIcon from "./icons/keb.png";
import UralsibIcon from "./icons/uralsib.png";
import MersedesIcon from "./icons/mersedes.png";
import OtpIcon from "./icons/otp.svg";
import RosgosstrahIcon from "./icons/rosgosstrah.svg";

import { isMitsubishi } from "modules/app/utils/isMitsubishi";
import { onlineBanks } from "api/online-banks";
import { selectCarCondition } from "modules/app";
import { selectCarBrandName } from "modules/car";
import { mmcMotorsFilterIntroBanks, isMmcMotors } from "../../../../../../modules/app/utils/isMmcMotors";
import isBlackTheme from "modules/app/utils/isBlackTheme";

import "./partners.scss";

const BANKS = {
  MITSUBISHI: "mcbankrus",
  SOVCOM: "sovkom",
  RUSFIN: "rusfin",
  ROSBANK: "rosbank",
  VTB: "vtb",
  TINKOFF: "tinkoff",
  SETELEM: "setelem",
  UNICREDIT: "unicredit",
  // BMW: "bmw",
  // VOLKSWAGEN: "volkswagen",
  // KEB: "keb",
  // URALSIB: "uralsib",
  // MERSEDES: "mersedes",
  // OTP: 'otp',
  // ROSGOSSTRAH: 'rosgosstrah',
};

const allPartners = [
  {
    name: BANKS.MITSUBISHI,
    icon: MCBankRus,
    isWhite: false,
  },
  {
    name: BANKS.SOVCOM,
    icon: SovcomBank,
    isWhite: false,
  },
  {
    name: BANKS.SOVCOM,
    icon: SovcomBankWhite,
    isWhite: true,
  },
  {
    name: BANKS.RUSFIN,
    icon: RusFinanceIcon,
    isWhite: false,
  },
  {
    name: BANKS.VTB,
    icon: VTBIcon,
    isWhite: false,
  },
  {
    name: BANKS.TINKOFF,
    icon: TinkoffIcon,
    isWhite: false,
  },
  {
    name: BANKS.SETELEM,
    icon: SetelemIcon,
    isWhite: false,
  },
  {
    name: BANKS.UNICREDIT,
    icon: UnicreditIcon,
    isWhite: false,
  },
  {
    name: BANKS.ROSBANK,
    icon: RosbankIcon,
    isWhite: false,
  },
  {
    name: BANKS.ROSBANK,
    icon: RosbankWhiteIcon,
    isWhite: true,
  },
  // {
  //   name: BANKS.BMW,
  //   icon: BmwIcon,
  //  isWhite: false,
  // },
  // {
  //   name: BANKS.OTP,
  //   icon: OtpIcon,
  //  isWhite: false,
  // },
  // {
  //   name: BANKS.MERSEDES,
  //   icon: MersedesIcon,
  //  isWhite: false,
  // },
  // {
  //   name: BANKS.URALSIB,
  //   icon: UralsibIcon,
  //  isWhite: false,
  // },
  // {
  //   name: BANKS.KEB,
  //   icon: KebIcon,
  //  isWhite: false,
  // },
  // {
  //   name: BANKS.VOLKSWAGEN,
  //   icon: VolkswagenIcon,
  //  isWhite: false,
  // },
  // {
  //   name: BANKS.ROSGOSSTRAH,
  //   icon: RosgosstrahIcon,
  //  isWhite: false,
  // },
];

export const Partners = () => {
  const token = useSelector(selectToken);
  const [banks, setBanks] = useState([]);
  const brandName = useSelector(selectCarBrandName);
  const carCondition = useSelector(selectCarCondition);
  const isWhite = isBlackTheme();

  useEffect(() => {
    const loadOnlineBanks = async () => {
      /**
       * ФИКС с банками временно. Много банков не влезают
       */
      //const { banks } = await onlineBanks.loadBanks(carCondition, brandName);
      let { banks } = await onlineBanks.loadBanks();

      banks = mmcMotorsFilterIntroBanks(banks);
      banks = kiaMotorsFilterIntroBank(banks);
      banks = cherryNewFilterIntroBank(banks);
      banks = omodaFilterIntroBank(banks);

      setBanks(banks);
    };
    loadOnlineBanks();
  }, [brandName, carCondition]);

  const partners = React.useMemo(
    () => {
      const items = allPartners.filter((p) =>
        banks.find((b) => b.is_partner && b.code === p.name && p.isWhite == isWhite)
      );

      /**
       * ФИКС с банками временно. Много банков не влезают
       */
      return items.slice(0,5);
    },
    [banks]
  );

  const highlighted = React.useMemo(() => {
    if (isMitsubishi(token)) {
      return BANKS.MITSUBISHI;
    }
  }, [token]);

  return (
    <div className="partners">
      <Typography text="Банки" className="partners__heading" />
      <div className="partners__container">
        {partners.map(({ icon, name }, index) => (
          <span key={index} className="partners__partner">
            <img
              src={icon}
              alt={name}
              className={classnames("partners__image", {
                "partners__image_highlighted": name === highlighted,
                "partners__image_lowlighted": highlighted && name !== highlighted
              })}
            />
          </span>
        ))}
      </div>
    </div>
  );
};
