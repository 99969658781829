import {formValueSelector} from 'redux-form'
import {FORM_NAME} from "../../form";

export const selectCustomerInfo = (state) => {
  const surname = formValueSelector(FORM_NAME)(state, 'surname');
  const name = formValueSelector(FORM_NAME)(state, 'name');
  const middleName = formValueSelector(FORM_NAME)(state, 'middle_name');

  const customer = {
    surname: surname ? surname.value : '',
    name: name ? name.value : '',
    middleName: middleName ? middleName.value : '',
  };

  customer.fullName = customer.surname + ' ' + customer.name + ' ' + customer.middleName;

  return customer;
}
