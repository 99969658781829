
export const timeStampToDate = (date) => {

  console.log(date);

  if (!date){
    return null;
  }

  const month = date.getMonth() + 1;
  const day = date.getDate();

  return date.getFullYear() + '-' + (month<10 ? '0' + month : month) + '-' + (day<10 ? '0' + day : day);
};

export const dateStringToTimeStamp = (dateString) => {
  return Date.parse(dateString);
};

/**
 *
 * @param date редактируемая дата
 * @param days кол-во дней смещения
 * @param direction направление смещения, true - вперед, false - назад
 */
export const shiftDaysInDate = (date, days, direction = true) => {
  return direction
    ? date.setDate(date.getDate() + days)
    : date.setDate(date.getDate() - days);
}
