import { scrollToElement } from './scrollToElement';

export const focusElement = (elementId) => {
  const element = document.getElementById(elementId);

  if (element) {
    scrollToElement(elementId);
    element.focus();
  }
};
