import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Typography, TypographyType } from 'components/UI';

import './form-section.scss';

export function FormSection({ className, header, children }) {
  return (
    <section className={classnames('form-section', className)}>
      <Typography type={TypographyType.SECTION_HEADER}>{header}</Typography>
      {children}
    </section>
  )
}

FormSection.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.node,
};
