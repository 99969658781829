//Слева - значение на сайте,  справа - значение в бикарс
const mapping = {
  'Granta': 'Granta sedan',
  'Niva Legend 3 дв': '4X4 Urban 3D',
  'Granta универсал': 'Granta wagon',
  'Granta учебная': 'Granta sedan',
  'Granta хэтчбек': 'Granta hatchback',
  'Granta Cross': 'Granta Cross',
  'Legend 5 дв': '4X4 Urban 5D',
  'Largus фургон': 'Largus Fourgon',
  'Largus': 'Largus wagon',
  'XRAY': 'XRAY hatchback',
  'Granta Drive Active': 'Granta sedan',
  'Vesta': 'Vesta sedan',
  'Vesta CNG': 'Vesta sedan',
  'Largus фургон CNG': 'Largus Fourgon',
  'XRAY Сross Instinct': 'XRAY Cross',
  'Vesta Sport': 'Vesta Sport',
  'К5': 'K5',
  'Rio': 'Rio (2021)',
  'Rio Х': 'Rio X',
  'Sorento': 'Sorento (2021)',
  'К900': 'K900'
};


export const pragmatikaModelMapping = (model) => {
  if (!model){
    return model;
  }

  model = model.trim();
  model = model.replace(/лифтбек/, 'liftback');
  model = model.replace(/\.$/, '');

  Object.entries(mapping).every((item) => {

    if (model.toLowerCase() == item[0].toLowerCase()){
      model = item[1];
      return false;
    }

    return true;
  });

  return model;
}
