import { splitPhone } from 'helpers'
import { EmploymentType, MaritalStatus } from 'helpers/constants';

import lists from 'helpers/lists';
import { addressToFrontendModel } from './addressToFrontendModel';
import { addressToBackendModel } from './addressToBackendModel';
import { passportToBackendModel } from './passportToBackendModel';
import { driverLicenseToBackendModel } from './driverLicenseToBackendModel';
import { driversToFrontendModel } from "./driversToFrontendModel";
import { addressSimpleFieldsToFrontendModel } from "./addressToFrontendModel";

import { toFrontendDate, toBackendDate } from "./dateModels";
import { toFrontendPhone } from './toFrontendPhone';
import {
  passportToFrontendModel,
  previousPassportToFrontendModel,
} from './passportToFrontendModel';
import { valueToString } from './valueToString';

import { selectDefaultParams } from "modules/app";
import { isProcessKasko } from "../modules/app/utils/isProcessKasko";
import { getState } from "helpers";
import { addressDadataToBackendModel } from "./addressDadataToBackendModel";
import { canShowKaskoFrameOffers } from "../modules/kasko/validateKaskoParams";
import { selectClientApplicationId } from "modules/app";
import {graylogNotify} from "../modules/notify/notify";

const stepForSendingToBank = 'DRIVERS';

const profFields = {
  'customer.employment_type': 'employment_type',
  'customer.employee_position': 'employee_position',
  'customer.rotation_work': 'rotation_work',
  'customer.education.id': 'education',
  'customer.primary_income_sum': 'primary_income_sum',
  'customer.officePhone.area_code': 'employer_phone',
  'customer.officePhone.phone_number': 'employer_phone',
  'customer.office_phone': 'employer_phone',
};

const docFields = {
  'customer.spendings_request': 'spendings_request',
  'customer.spendings_anycredit': 'spendings_anycredit',
  'customer.spouse_mobile': 'spouse_mobile',
  'customer.proxy_phone': 'proxy_phone',
  'customer.spouse_surname': 'spouse_surname',
  'customer.years_married': 'years_married',
  'customer.spouse_name': 'spouse_name',
  'customer.spouse_middle_name': 'spouse_middle_name',
  'customer.spouse_employment_type': 'spouse_employment_type',
  'customer.basic_monthly_income_spouse': 'basic_monthly_income_spouse',
  'customer.spouse_birth_place': 'spouse_birth_place',
  'customer.surname': 'surname',
  'customer.name': 'name',
  'customer.middle_name': 'middle_name',
  'customer.gender': 'gender',
  'customer.birth_date': 'birth_date',
  'customer.birth_place': 'birth_place',
  'customer.email.email': 'email',
  'customer.mobile_phone': 'mobile_phone',
  'customer.num_of_children_younger_than_21': 'num_of_children_younger_than_21',
  'customer.number_of_dependents': 'number_of_dependents',
  'customer.registration_address_date': 'registration_address_date',
  'customer.living_address_date': 'la_registration_address_date',
  'customer.registration_address.region_fias_id': 'registration_address.region_fias_id',
  // 'customer.registration_address.house_number': 'registration_address.house_number',
  'customer.maritalStatus.id': 'marital_status',
  'customer.realty_state': 'realty_state',
  'customer.relation_to_person.id': 'relation_to_person',
  'customer.living_address.region_fias_id': 'living_address.region_fias_id',
  // 'customer.living_address.house_number': 'living_address.house_number',
  'customer.no_previous_passport': 'no_previous_passport',
  'customer.matches_registration': 'matches_registration',
  'customer.fio_was_change': 'fio_was_change',
  'customer.previous_surname': 'previous_surname',
  'customer.official_person': 'official_person',
  'customer.spouce_official': 'spouce_official',
};

const driverFields = {
  'customer.driving_licence.issuer_name': 'issuer_name_2nd',
  'customer.driving_licence.number': 'driverLicense',
  'customer.driving_licence.series': 'driverLicense',
  'customer.driving_licence.issue_date': 'issue_date_2nd',
  'customer.driving_licence.experience_begin': 'experience_begin',
};

const prepareSendToBankFlag = (step) => {
  const driversPageName = 'DRIVERS';
  const finalPageName = 'FINAL_CONDITIONS';
  const processKasko = isProcessKasko();
  const cahShowKaskoFrameOffers = canShowKaskoFrameOffers();
  return (+(step === driversPageName) && (!processKasko || !cahShowKaskoFrameOffers)) || +(step === finalPageName)
}

export class AppOnline {
  static toBackendModel(formValues, appEntity, step, addDriversArray = false) {
    const {
      surname,
      name,
      middle_name,
      gender,
      birth_place = {},
      birth_date,
      fio_was_change,
      num_of_children_younger_than_21,
      number_of_dependents,
      mobile_phone,
      email,
      employer_inn,
      employer_index,
      employer_apartment_number,
      employer_phone,
      primary_income_sum,
      spendings_request,
      spendings_anycredit,
      passport,
      passport_issuer_code,
      passport_issuer_name,
      no_previous_passport,
      driverLicense,
      issuer_name_2nd,
      driving_experience,
      apartment_number,
      la_apartment_number,
      la_index,
      index,
      matches_registration,
      previous_passport,
      previous_issuer_code,
      previous_issuer_name,
      spouse_mobile,
      spouse_surname,
      years_married,
      spouse_name,
      spouse_middle_name,
      spouse_employment_type,
      basic_monthly_income_spouse,
      spouse_birth_place = {},
      previous_surname,
      official_person,
      rotation_work,
      employee_position,
      spouce_official,
      passport_issue_date,
      previous_issue_date,
      issue_date_2nd,
      spouse_birth_date,
      registration_address_date,
      la_registration_address_date,
      employer_number_of_employees = {},
      employer_business_type = {},
      employer_industry_branch = {},
      proxy_second_name = {},
      proxy_first_name = {},
      proxy_third_name = {},
      proxy_phone,
      proxy_birth_date,
      drivers = [],
      employment_type,
      education,
      employer_name = {},
      employer_type,
      employee_position_type,
      working_life_date,
      employer_legal_address = {},
      registration_address = {},
      living_address = {},
      marital_status,
      realty_state,
      relation_to_person,
    } = formValues;

    const {
      application_id,
      id_type
    } = appEntity;

    const processKasko = isProcessKasko();
    const clientApplicationId = selectClientApplicationId(getState());

    const model = {
      application_id: clientApplicationId,
      id_type: 1,
      process_kasko: Number(processKasko),
      from_our_front: 1,
      async: 1,
      send_to_bank: prepareSendToBankFlag(step),
      //send_to_bank: (+(step === stepForSendingToBank) && !processKasko) || +(step === 'FINAL_CONDITIONS'),
      customer: {
        surname: surname.value,
        name: name.value,
        middle_name: middle_name.value,
        gender,
        birth_date: toBackendDate(birth_date),
        birth_place: birth_place.value,
        email, num_of_children_younger_than_21,
        number_of_dependents,
        registration_address_date: toBackendDate(registration_address_date),
        employment_type: employment_type.id,
        rotation_work: +rotation_work,
        education: education.id,
        primary_income_sum: primary_income_sum.replace(/[^\d]/g, ''),
        // average_monthly_income - для РФБ, его никуда не надо больше выводить
        average_monthly_income: primary_income_sum.replace(/[^\d]/g, ''),
        spendings_request: spendings_request ? spendings_request.replace(/[^\d]/g, '') : '',
        spendings_anycredit: spendings_anycredit ? spendings_anycredit.replace(/[^\d]/g, '') : '',
        marital_status: marital_status && marital_status.id,
        realty_state: realty_state && realty_state.id,
        relation_to_person: relation_to_person && relation_to_person.id,
        no_previous_passport: +no_previous_passport,
        matches_registration: +matches_registration,
        fio_was_change: +fio_was_change,
        previous_surname: previous_surname,
        official_person: +official_person,
        spouce_official: +spouce_official,
        mobile_phone: {...splitPhone(mobile_phone)},
        registration_address: {
          dadata: addressDadataToBackendModel(registration_address, index)
        },
        passport: {
          ...passportToBackendModel(passport),
          issue_date: toBackendDate(passport_issue_date),
          issuer_code: passport_issuer_code && passport_issuer_code.data
            ? passport_issuer_code.data.code
            : passport_issuer_code,
          issuer_name: passport_issuer_code && passport_issuer_code.data
            ? passport_issuer_code.data.name
            : passport_issuer_name,
        },
      }
    };

    if (addDriversArray){
      model.customer = {
        ...model.customer,
        drivers: drivers.map(driver => ({
          ...driver,
          ...driverLicenseToBackendModel(driver.driverLicense),
          issue_date_2nd: toBackendDate(driver.issue_date_2nd),
          experience_begin: toBackendDate(driver.experience_begin),
          birth_date: toBackendDate(driver.birth_date),
          surname: driver.surname.value,
          name: driver.name.value,
          middle_name: driver.middle_name.value,
        })),
      }

      model.customer.drivers.map(driver => {
        if (!driver.driving_experience || isNaN(driver.driving_experience)){
          graylogNotify(JSON.stringify(model.customer.drivers), 'check_experience', 'apponline_error')
        }

        driver.driving_experience = (new Date()).getFullYear() - (new Date(driver.experience_begin)).getFullYear();

        if (!driver.driving_experience || isNaN(driver.driving_experience)){
          graylogNotify(JSON.stringify(driver), 'check_experience', 'apponline_detail')
          driver.driving_experience = 10;
        }
      });
    }

  if (marital_status && MaritalStatus.MARRIED === marital_status.id){
      model.customer = {
        marital_status,
        spouse_birth_date: toBackendDate(spouse_birth_date),
        years_married: +years_married,
        spouse_surname: spouse_surname && spouse_surname.value,
        spouse_name: spouse_name && spouse_name.value,
        spouse_middle_name: spouse_middle_name && spouse_middle_name.value,
        spouse_employment_type: spouse_employment_type && spouse_employment_type.id,
        basic_monthly_income_spouse: basic_monthly_income_spouse.replace(/\D/g,''),
        spouse_birth_place: spouse_birth_place.value,
        spouse_mobile: { ...splitPhone(spouse_mobile) },
        ...model.customer
      }
    } else {
      model.customer = {
        proxy_second_name: proxy_second_name.value,
        proxy_first_name: proxy_first_name.value,
        proxy_third_name: proxy_third_name.value,
        proxy_phone: splitPhone(proxy_phone || ""),
        proxy_birth_date: toBackendDate(proxy_birth_date),
        ...model.customer,
      };
    }

    if (employment_type.id && ![
      EmploymentType.UNEMPLOYED_MILITARY_PENSIONER,
      EmploymentType.UNEMPLOYED_PENSIONER,
    ].includes(employment_type.id)) {
      model.customer = {
        employer_name: employer_name.value,
        employee_position: employee_position,
        employer_inn,
        employee_position_type: employee_position_type.id,
        employer_type: employer_type.id,
        employer_number_of_employees: employer_number_of_employees.id,
        employer_business_type: employer_business_type.id,
        employer_industry_branch: employer_industry_branch.id,
        working_life_date: working_life_date.id,
        employer_legal_address: {
          dadata: employer_legal_address
        },
        office_phone: { ...splitPhone(employer_phone) },
        ...model.customer
      }
    }

    if (living_address) {
      model.customer.living_address = {
        dadata: addressDadataToBackendModel(living_address, la_index)
      };
      model.customer.living_address_date = toBackendDate(la_registration_address_date);
    }

    if (!no_previous_passport) {
      model.customer.previous_passport = {
        ...passportToBackendModel(previous_passport),
        issue_date: toBackendDate(previous_issue_date),
        issuer_code: previous_issuer_code && previous_issuer_code.data
          ? previous_issuer_code.data.code
          : previous_issuer_code,
        issuer_name: previous_issuer_code && previous_issuer_code.data
          ? previous_issuer_code.data.name
          : previous_issuer_name,
      }
    }

    return model;
  }

  static customClientFieldsToFrontendModel() {
    const values = selectDefaultParams(getState());
    values.client_phone = values.client_phone ? values.client_phone.replace(/[^\d]+/,'') : '';
    values.client_phone = values.client_phone.length<11 ? values.client_phone : values.client_phone.slice(0,10);

    const phone = values.client_phone && values.client_phone.length
      ? `+7 ${values.client_phone.slice(0,3)} ${values.client_phone.slice(3)}`
      : '';

    return {
      surname: {value: values.client_surname || ''},
      name: { value: values.client_name || '' },
      middle_name: { value: values.client_middle_name || '' },
      mobile_phone: phone
    };
  }

  static toFrontendModel(values) {
    const {
      mobile_phone,
      surname,
      name,
      middle_name,
      birth_date,
      employment_type,
      education,
      employer_name,
      employer_type,
      employee_position_type,
      working_life_date,
      employer_industry_branch,
      employer_number_of_employees,
      employer_business_type,
      office_phone,
      employer_legal_address,
      marital_status,
      passport,
      previous_passport,
      realty_state,
      relation_to_person,
      spouse_surname,
      spouse_name,
      spouse_middle_name,
      spouse_employment_type,
      spouse_birth_place,
      spouse_mobile,
      spouse_birth_date,
      registration_address,
      living_address,
      passport_issue_date,
      previous_issue_date,
      registration_address_date,
      proxy_second_name,
      proxy_first_name,
      proxy_third_name,
      proxy_birth_date,
      proxy_phone,
      issue_date_2nd,
      birth_place,
      primary_income_sum,
      years_married,
      num_of_children_younger_than_21,
      number_of_dependents,
      basic_monthly_income_spouse,
      average_monthly_income,
      rotation_work,
      drivers = [],
      ...rest
    } = values;

    return {
      surname: { value: surname },
      name: { value: name },
      middle_name: { value: middle_name },
      mobile_phone: toFrontendPhone(mobile_phone),
      birth_date: toFrontendDate(birth_date),
      employment_type: lists.employment_type.find(({ id }) => id === Number(employment_type)),
      education: lists.education.find(({ id }) => id === Number(education)),
      employer_name: { value: employer_name },
      employer_type: lists.employer_type.find(({ id }) => id === Number(employer_type)),
      employee_position_type: lists.employee_position_type.find(({ id }) => id === Number(employee_position_type)),
      working_life_date: lists.workingLifeDate.find(({ id }) => id === Number(working_life_date)),
      employer_industry_branch: lists.industryBranch.find(({ id }) => id === Number(employer_industry_branch)),
      employer_number_of_employees: lists.employerCountEmployees.find(({ id }) => id === Number(employer_number_of_employees)),
      employer_business_type: lists.employerBusinessType.find(({ id }) => id === Number(employer_business_type)),
      employer_phone: toFrontendPhone(office_phone),
      employer_legal_address: addressToFrontendModel(employer_legal_address),
      employer_index: employer_legal_address.index,
      marital_status: lists.marital_status.find(({ id }) => id === Number(marital_status)),
      realty_state: lists.realty_state.find(({ id }) => id === Number(realty_state)),
      birth_place: { value: birth_place },
      relation_to_person: lists.relation_to_person.find(({ id }) => id === Number(relation_to_person)),
      spouse_employment_type: lists.working_spouse_type.find(({ id }) => id === Number(spouse_employment_type)),
      spouse_birth_place: { value: spouse_birth_place },
      spouse_mobile: toFrontendPhone(spouse_mobile),
      spouse_surname: { value: spouse_surname },
      spouse_name: { value: spouse_name },
      spouse_middle_name: { value: spouse_middle_name },
      registration_address:  addressToFrontendModel(registration_address),
      registration_address_date: toFrontendDate(registration_address_date),
      ...addressSimpleFieldsToFrontendModel(registration_address),
      living_address: addressToFrontendModel(living_address),
      la_index: living_address.index,
      passport_issue_date: toFrontendDate(passport_issue_date),
      previous_issue_date: toFrontendDate(previous_issue_date),
      la_registration_address_date: toFrontendDate(registration_address_date),
      proxy_second_name: { value: proxy_second_name },
      proxy_first_name: { value: proxy_first_name },
      proxy_third_name: { value: proxy_third_name },
      proxy_birth_date: toFrontendDate(proxy_birth_date),
      proxy_phone: toFrontendPhone(proxy_phone),
      spouse_birth_date: toFrontendDate(spouse_birth_date),
      issue_date_2nd: toFrontendDate(issue_date_2nd),
      rotation_work: +rotation_work,
      drivers: driversToFrontendModel(drivers),
      ...passportToFrontendModel(passport),
      ...previousPassportToFrontendModel(previous_passport),
      primary_income_sum: valueToString(primary_income_sum),
      years_married: valueToString(years_married),
      num_of_children_younger_than_21: valueToString(num_of_children_younger_than_21),
      number_of_dependents: valueToString(number_of_dependents),
      basic_monthly_income_spouse: valueToString(basic_monthly_income_spouse),
      average_monthly_income: valueToString(average_monthly_income),
      ...rest,
    }
  }

  static toSubmissionError(response, formFields) {
    //Когда вернулись с 3-я вкладки на 2-ю и ошибок нет
    const responseFields = response && response.fields ? response.fields : {};
    const errors = Object.entries(responseFields || {});
    const fields = formFields || {
      ...profFields,
      ...docFields,
    };

    return errors.reduce((submissionErrors, [errorKey, errorValue]) => {
      const error = errorValue[0];
      if (!fields[errorKey]) {
        const formError = submissionErrors._error ?  `${submissionErrors._error}, ` : '';
        return {...submissionErrors, _error: `${formError}${error}`}
      }

      return {
        ...submissionErrors,
        [fields[errorKey]]: error,
      };
    }, {});
  }

  static toProfSubmissionError(response) {
    const errors = AppOnline.toSubmissionError(response, profFields);
    delete errors._error;

    if (Object.keys(errors).length > 0) {
      return errors;
    }
  }

  static toDocsSubmissionError(response) {
    const errors = AppOnline.toSubmissionError(response, docFields);
    delete errors._error;

    if (Object.keys(errors).length > 0) {
      return errors;
    }
  }

  static toDriversSubmissionError(response) {
    const errors = AppOnline.toSubmissionError(response, driverFields);
    delete errors._error;

    if (Object.keys(errors).length > 0) {
      errors.drivers = [];

      for(let i in errors){
        let someError = {};

        if (i == 'driverLicense'){
          errors[i] = "Проверьте правильность ввода";
        }

        someError[i] = errors[i];
        errors.drivers.push(someError);
      }
    }

    if (Object.keys(errors).length > 0) {
      return errors;
    }
  }
}
