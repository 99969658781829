import React from "react";
import PropTypes from "prop-types";
import { FieldArray, reduxForm, getFormValues } from "redux-form";
import { Form, FormFooter } from "components/form";
import { scrollToElement, getState } from "helpers";
import { DRIVERS_ELEMENT_ID } from "helpers/constants";
import { selectSecondDriverTypeIsDeleted } from "modules/drivers/selectors";
import { getNextButtonName } from "./utils/getNextButtonName";
import { isProcessKasko } from "modules/app/utils/isProcessKasko";

import { FormError } from "components/reduxForm";
import { createValidator, scrollToFirstError } from "validation";
import {
  FORM_NAME,
  selectFullNameAsObject,
  selectBirthDate,
  selectMobilePhone,
  selectContactPerson,
  selectDrivers,
} from "modules/form";

import DriversField from "./components/DriversField";
import { DriverType } from "./constants";
import { validationSchema } from "./validationSchema";

export const DriversComponent = (props) => {
  const { handleSubmit, goBack, error, submitFailed, initialize } = props;

  React.useEffect(() => scrollToElement(DRIVERS_ELEMENT_ID), []);

  React.useEffect(() => {
    const state = getState();
    const drivers = selectDrivers(state);
    const processKasko = isProcessKasko();

    const mainDriver = {
      type: DriverType.MAIN,
      ...selectFullNameAsObject(state),
      birth_date: selectBirthDate(state),
      mobile_phone: selectMobilePhone(state),
    };

    const secondDriverTypeIsDeleted = selectSecondDriverTypeIsDeleted(state);

    const proxyPersonDriver = {
      type: DriverType.SECOND,
      ...selectContactPerson(state),
    };

    let driversResultArray = [];

    driversResultArray.push({
      ...mainDriver
    });

    if (drivers && drivers.length>0){

      driversResultArray = [];

      for(let i in drivers){
        driversResultArray.push({
          ...drivers[i]
        });
      }

    }

    if (processKasko && !secondDriverTypeIsDeleted && driversResultArray.length<2){
      driversResultArray.push({
        ...proxyPersonDriver,
      });
    }

    initialize({
      ...getFormValues(FORM_NAME)(state),
      drivers: driversResultArray
    });
  }, []);

  const primaryButtonName = getNextButtonName();
  const showAddDriverButton = isProcessKasko();

  return (
    <Form id={DRIVERS_ELEMENT_ID}>
      <FieldArray name="drivers" component={DriversField} rerenderOnEveryChange showAddDriverButton={showAddDriverButton} />
      <FormError submitFailed={submitFailed} error={error} />
      <FormFooter onSubmit={handleSubmit} goBack={goBack} primaryButtonName={primaryButtonName} help={null} />
    </Form>
  );
};

DriversComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitFailed: PropTypes.bool.isRequired,
  initialize: PropTypes.func,
};

export const Drivers = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: createValidator(validationSchema),
  onSubmitFail: scrollToFirstError,
})(DriversComponent);
