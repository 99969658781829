import { string, object, boolean } from 'yup';
import { differenceInYears } from 'date-fns';
import { WorkingLifeDateType } from 'helpers/constants';
import { stringToDate } from 'helpers';

const START_WORKING_LIFE_AGE = 14;

const workingLifeDateToYears = workingLifeDate => {
  switch(workingLifeDate) {
    case WorkingLifeDateType.FROM_1_TO_3_YEARS:
      return 1;
    case WorkingLifeDateType.FROM_3_TO_5_YEARS:
      return 3;
    case WorkingLifeDateType.FROM_5_TO_10_YEARS:
      return 5;
    case WorkingLifeDateType.MORE_10_YEARS:
      return 10;
    default:
      return 0;
  }
};

export const validationSchema = object().shape({
  'birth_date': string() // This field is not visible on Docs form (part of Info), if form will be splitted schema must be changed
    .required()
    .date()
    .adult(),
  'employment_type': object()
    .required(),
  'education': object()
    .required(),
  'employer_name': object()
    .dadataRequired()
    .thereAreRussianLettersDadata(),
  'employer_legal_address': object()
    .dadataRequired()
    .thereAreRussianLettersDadata()
    .accuracyToHouseNumber(),
  'employer_inn': string()
    .required()
    .inn(),
  'employer_type': object()
    .required(),
  'employer_index': string()
    .nullable()
    .required()
    .postIndex(),
  'employer_apartment_number': string()
    .thereAreRussianLettersOrNumbers(),
  'employee_position': string()
    .required()
    .thereAreLetters(),
  'employee_position_type': object()
    .required(),
  'working_life_date': object()
    .required()
    .when('birth_date', function (birthDateString) {
      return this.test({
        test: ({ name } = {}) => {
          const birthDate = stringToDate(birthDateString);
          const age = differenceInYears(new Date(), birthDate);
          return (age - workingLifeDateToYears(name)) >= START_WORKING_LIFE_AGE;
        },
        message: 'Трудовой стаж не может начинаться раньше 14 лет',
      });
    }),
  'employer_phone': string()
    .required()
    .phone(),
  'primary_income_sum': string()
    .required(),
});
