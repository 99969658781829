import React from 'react';
import cx from 'classnames';

import './style.scss';


export const Error = ({ error }) =>
  <div className={cx('error', { hide: !error } )}>
    {error}
  </div>
