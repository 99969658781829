export const replaceEnToRu = (value) => {
  const replacer = {
      "q":"й", "w":"ц", "e":"у", "r":"к", "t":"е", "y":"н", "u":"г",
      "i":"ш", "o":"щ", "p":"з", "[":"х", "]":"ъ", "a":"ф", "s":"ы",
      "d":"в", "f":"а", "g":"п", "h":"р", "j":"о", "k":"л", "l":"д",
      ";":"ж", "'":"э", "z":"я", "x":"ч", "c":"с", "v":"м", "b":"и",
      "n":"т", "m":"ь", ",":"б", ".":"ю", ":":"ж", "<":"б", ">":"ю",
      "\"":"э"
  };

  return value.replace(/[A-z/,.;:<>'"[\]]/g, char => char === char.toLowerCase()
    ? replacer[char]
    : replacer[char.toLowerCase()].toUpperCase());
}
