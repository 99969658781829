import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { GridCell } from 'components/UI/grid/components/GridCell';
import { useAlternativeInput } from './useAlternativeInput';

import './style.scss'

export const Redux = ({ input, width, meta, notRequired, wBorder, ...props }) => {
  const error = meta && meta.touched ? meta.error : '';
  const Input = useAlternativeInput();

  return (
    <GridCell width={width}>
      <Input
        {...input}
        {...props}
        inputClassName={classnames('redux-form-input-field', {
          'redux-form-input-field__wBorder': wBorder,
        })}
        error={error}
        required={!notRequired}
      />
    </GridCell>
  );
}

Redux.propTypes = {
  input: PropTypes.object,
  width: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  notRequired: PropTypes.bool,
  wBorder: PropTypes.bool,
};
