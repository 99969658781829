import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { createMetricName } from "../../field-metric-name";

import './Button.scss';


export const Button = ({ children, flat, className, submit, disabled, ...props }) => {
  const classNames = classnames('button', className, {
    'button_flat': flat,
    'button_disabled': disabled,
  });

  props['data-id'] = createMetricName(props.name);

  return (
    <button
      className={classNames}
      type={submit ? 'submit' : 'button'}
      disabled={disabled}
      {...props}>
        {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  flat: PropTypes.bool,
  className: PropTypes.string,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
};
