import React, { Component } from 'react'
import { connect } from 'redaction'
import { formatCurrency, range } from 'helpers'

import { Flex } from 'components/layouts'
import { ReactComponent as Check } from 'assets/checkbox.svg'

import './styles.sass'


export const Sidebar = connect({
  brand: 'car.brand',
  model: 'car.model',
  initial_fee: 'car.initial_fee',
  initial_fee_money: 'car.initial_fee_money',
  price: 'car.price',
  period: 'car.period',
  chosen: 'car.chosen',
})(
  class extends Component {
    state = {
      osago: false,
      kasko: false,
    }

    osago = () => this.setState({ osago: true })
    kasko = () => this.setState({ kasko: true })

    render() {
      const { brand, chosen, initial_fee, model, period, price } = this.props
      const { kasko, osago } = this.state

      return (
        <div className={'sidebar-container'} style={{"display":"none"}}>
          <Flex column className={'sticky'}>
            <Flex column className={'wrapper'}>
              <h3>Ваш выбор</h3>
              <h4>Автомобиль</h4>
              <div style={{ marginBottom: 0 }}>{brand && brand.name}</div>
              <div>{model && model.name}</div>
              <h4>Стоимость</h4>
              <div>{formatCurrency(price)} ₽</div>
              <h4>Первоначальный взнос</h4>
              <div>{formatCurrency(price)} ₽ / {initial_fee} %</div>
              <h4>Срок кредита</h4>
              <div>{range(period)}</div>
              <h4>Ежемесячный платёж</h4>
              <p className={'monthly'}>
                {formatCurrency(Math.round(chosen.payment))} ₽
              </p>
            </Flex>

            <Flex column className={'block'}>
              <h4>ОСАГО</h4>
              <h5>Росгосстрах</h5>
              <p className={'monthly'}>
                {formatCurrency(price / 100 * .5)} ₽
              </p>
              {
                osago
                  ? <Flex alignCenter className={'added'}><Check />
                    <span>Добавлено</span></Flex>
                  : <div onClick={this.osago}>Добавить</div>
              }
            </Flex>
            <Flex column className={'block'}>
              <h4>КАСКО</h4>
              <h5>Ингосстрах</h5>
              <p className={'monthly'}>
                {formatCurrency(Math.round(price / 100 / 3))} ₽<span>/мес.</span>
              </p>
              {
                kasko
                  ? <Flex alignCenter className={'added'}><Check /><span>Добавлено</span></Flex>
                  : <div onClick={this.kasko}>Добавить</div>
              }
            </Flex>
          </Flex>
          <div className={'fix'}></div>
      </div>
      )
    }
  }
)
