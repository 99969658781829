import React from 'react';
import { Flex } from 'components/layouts';

import './styles.sass';

export const Header = ({creditonline, eosago}) => {
	return (
	<Flex center className={'header'}>
		<Flex alignCenter justifyBetween className={'wrapper'}>
			<div className={'header__logo'}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='151'
					height='20'
					viewBox='0 0 151 20'
				>
					<text
						transform='translate(0 15)'
						fill='#fff'
						fontSize='20'
						fontFamily='Helvetica'
					>
						<tspan x='0' y='0'>
							F&I Hub
						</tspan>
					</text>
				</svg>
			</div>
			<Flex className={'nav'}>
				{creditonline && (<a href='https://www.hyundai.ru/start'>Онлайн одобрение кредита</a>)}
			</Flex>
		</Flex>
	</Flex>
)};
