import React from 'react';
import { selectTheme } from 'modules/app';
import { SPECIFIC_DESIGN_BRANDS } from 'helpers/constants';
import { Field } from 'components/UI';
import { AlternativeField } from 'components/UI/alternative-field/AlternativeField';
import { useSelector } from 'react-redux';

export const useAlternativeField = () => {
  const theme = useSelector(selectTheme);
  const specificBrandDesign = SPECIFIC_DESIGN_BRANDS.includes(theme);

  const FieldComponent = React.useMemo(
    () => specificBrandDesign ? AlternativeField : Field,
    [theme],
  );

  return {
    FieldComponent,
    alternativeDesign: specificBrandDesign,
  };
};
