import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Error } from 'components/reduxForm';
import { GridCell } from 'components/UI/grid/components/GridCell';

import { Tooltip } from 'components/UI';
import { ReactComponent as HelpIcon } from 'components/UI/field/help.svg';
import { ReactComponent as Check } from 'assets/checkbox.svg';

import './style.scss';

export const Flat = ({ onLabelClick, label, width, meta, checked, help, className, ...props }) => {
  return (
    <GridCell width={width}>
      <div className={cx('checkbox-container', className, (props.value || checked) && 'checked')}>
        <label>
          <input type="checkbox" {...props} />
          <Check className={cx('checkbox', { 'checkbox_active': meta && meta.active })} />
        </label>
        <span onClick={onLabelClick} className={cx('checkbox__label', {
           checkbox__label_clickable: onLabelClick,
        })}>
          {label}
        </span>
        {help && <Tooltip content={help}><HelpIcon /></Tooltip>}
      </div>
      <Error error={meta && meta.touched && meta.error} />
    </GridCell>

  );
}

Flat.propTypes = {
  onLabelClick: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  width: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    active: PropTypes.bool,
    error: PropTypes.string,
  }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  checked: PropTypes.bool,
  help: PropTypes.string,
  className: PropTypes.string,
};

export const Redux = ({ input, ...props }) => <Flat {...input} {...props} />

Redux.propTypes = {
  input: PropTypes.object.isRequired,
};
