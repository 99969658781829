import {getState} from "../helpers";
import {selectCar, selectRate, selectPeriod, selectCarYear} from "modules/car/selectors";
import {fetchWrapper} from "../api/fetch";
import {createApiMethodUrl} from "../api/createApiMethodUrl";
import {ApiMethods} from "../api/createApiMethodUrl";
import {MethodType} from "../api/fetch";
import {timeStampToDate} from "modules/utils";
import { getOsagoBrandAndModelId } from "./kasago-utils/brandModelToKasagoReference";
import { getDrivers } from "./kasago-utils/getDrivers";
import { getRegistrationAddress } from "./kasago-utils/getRegistrationAddress";
import { createOwner, createInsurer } from "./kasago-utils/ownerInsurer";
import { getCarDocument } from "./kasago-utils/getCarDocument";
import { getCalculatedCarIssueDate } from "./kasago-utils/getCalculatedCarIssueDate";
import { getPolicyStartDate } from "./kasago-utils/getPolicyStartDate";
import { selectCarVin } from "modules/car/selectors";
import { selectCarPrice } from "modules/car/selectors";
import { selectCarEnginePower } from "modules/car/selectors";
import { getDateRun } from "./kasago-utils/getDateRun";
import { getSellDealerAddress } from "./kasago-utils/getSellDealerAddress";

const KILOMETERS_DEFAULT = 10;

class Kasago {

  static calculateExperienceDate(years) {
    const date = new Date();
    date.setYear(date.getFullYear() - years)
    return timeStampToDate(date);
  }

  static selectDriversKasago() {
    const state = getState();
    const period = +selectPeriod(state);
    const rate = +selectRate(state);
    const vin = selectCarVin(state);
    const price = selectCarPrice(state);
    const enginePower = selectCarEnginePower(state);
    const carYear = selectCarYear(state);
    const registrationAddressDadata = getRegistrationAddress();

    const car = selectCar(state);
    const {brandId, modelId} = getOsagoBrandAndModelId(car.brand.name, car.model.name);

    const polisyStartDate = getPolicyStartDate();

    const dealerAddress = getSellDealerAddress();

    return {
      brandId: brandId,
      modelId: modelId,
      year: carYear,
      //dateRun: getCalculatedCarIssueDate(),
      /**
       * Чтобы выводилось ВСК. Убрать после проверки фикса!!!
       */
      //dateRun: polisyStartDate,
      dateRun: getDateRun(),
      enginePower: enginePower,
      policyStartDate: polisyStartDate,
      citySales: dealerAddress.address,
      vin: vin,
      cost: price,
      kilometers: KILOMETERS_DEFAULT,
      registrationAddress: registrationAddressDadata,
      gettingAddress: registrationAddressDadata,
      driversInfo: {
        amount: 0,
        drivers: getDrivers()
      },
      creditCar: true,
      'new': true,
      //ПТС
      carDocument: getCarDocument(),
      insurer: createInsurer(),
      owner: createOwner(),
      prolongation: false,

      //Для каско
      autolaunch: false,
      useAntiTheftSystem: true,

      //Параметры для фронта Касаго
      creditPeriod: period,
      creditRate: rate,
      brand: car.brand.name,
      model: car.model.name,

      //Проверка кода телефона
      params: {
        key: 'lhkasdlkh134lkh123lkk',
      },

      //Идентификатор банка
      creditCarBank: '043469743',
    }
  }

  static async getKasagoSavedParams(client_application_id) {
    let kasagoParams = {};

    if (client_application_id) {
      const result = await fetchWrapper(
        createApiMethodUrl(
          ApiMethods.getInsurancePrepare, {client_application_id}
        ), {
          method: MethodType.GET,
          headers: {}
        });
      const {response} = await result.json();

      if (response.prepare_insurance) {
        kasagoParams = response.prepare_insurance;
      }
    }

    return kasagoParams ? kasagoParams : {};
  }

  static async saveKasagoParams(clientApplicationId, data) {
    await fetchWrapper(
      createApiMethodUrl(
        ApiMethods.saveInsurancePrepare
      ), {
        method: MethodType.POST,
        headers: {},
        body: JSON.stringify({prepare: data, client_application_id: (clientApplicationId)})
      });
  }

}

export {Kasago};
