import reducers from 'redux/reducers'


export const setLocale = locale => {
  window.localStorage.setItem('locale', locale)
  reducers.user.update({ locale })
};

export const setError = message => {
  reducers.user.update({ message })
  setTimeout( () => reducers.user.update({ message: null }), 5000)
};

export const setLoading = () => reducers.user.update({ isFetching: true })
export const clearLoading = () => reducers.user.update({ isFetching: false })

export const update = data => reducers.user.update({ ...data })
