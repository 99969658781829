import React from 'react';
import { Field } from 'redux-form';

import { Input, Dadata } from 'components/reduxForm';

import { normalizePersonName, formatMobilePhone, normalizeMobilePhone } from '../../utils';
import { InputMaskType } from '../../constants';
import { InputModeType } from 'components/UI';
import { Grid } from 'components/UI/grid/Grid';
import { FormSection } from "components/form/FormSection";

export function ContactPerson() {
  return (
    <FormSection header="Ваше доверенное лицо">
      <Grid>
        <Field
          component={Dadata.Redux}
          type="fio"
          name="proxy_first_name"
          label="Фамилия"
          width="4"
          mask={InputMaskType.PERSON_NAME}
          autoReplace={normalizePersonName}
          parts="SURNAME"
        />
        <Field
          component={Dadata.Redux}
          type="fio"
          name="proxy_second_name"
          label="Имя"
          width="4"
          mask={InputMaskType.PERSON_NAME}
          autoReplace={normalizePersonName}
          parts="NAME"
        />
        <Field
          component={Dadata.Redux}
          type="fio"
          name="proxy_third_name"
          label="Отчество"
          width="4"
          mask={InputMaskType.MIDDLE_NAME}
          autoReplace={normalizePersonName}
          parts="PATRONYMIC"
        />
        <Field
          component={Input.Redux}
          name="proxy_phone"
          label="Номер телефона"
          prefix="+7"
          placeholder="(___) ___ ____"
          mask={InputMaskType.MOBILE_PHONE_WITHOUT_COUNTRY_CODE}
          width="4"
          inputMode={InputModeType.NUMERIC}
          format={formatMobilePhone}
          normalize={normalizeMobilePhone}
        />
        <Field
          component={Input.Redux}
          label="Дата рождения"
          name="proxy_birth_date"
          mask={InputMaskType.DATE}
          width="4"
          placeholder="дд.мм.гггг"
          inputMode={InputModeType.NUMERIC}
        />
      </Grid>
    </FormSection>
  );
}
