import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export function GridCell({ className, width, children }) {
  return (
    <div className={classnames(`grid__col_width-${width}`, className)}>
      {children}
    </div>
  )
}

GridCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  width: PropTypes.string,
};
