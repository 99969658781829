import qs from 'qs';

import { request, api, isRequestFailed } from 'helpers';
import reducers from 'redux/reducers';
import { dictionary } from 'redux/actions';
import { Hyundai } from '../../../models/Hyundai';
import { SwitchType } from "helpers/constants";
import { QuestionaryStepType } from "../../../pages/Main/components/AppForm/constants";
import { getHyundaiShowRoomUrl } from "../utils/getHyundaiShowRoomUrl";
import { APPLICATION_STATUS } from "helpers/constants";
import { isHyundaiDosProject } from "../utils/isHyundaiDosProject";

import {
  DealerIdType,
  CarConditionType,
  USED_CAR_CONFIGURATION_DEFAULT,
  NEW_CAR_CONFIGURATION_DEFAULT,
  DEFAULT_INITIAL_FEE,
} from 'helpers/constants';

import { env } from '../../../.env';
import { isMazda } from '../utils/isMazda';
import {setDefaultParams, setUntouchedInitialParams} from "../reducers";
import {fetchWrapper, MethodType} from "../../../api/fetch";
import {ApiMethods, createApiMethodUrl} from "../../../api/createApiMethodUrl";
import {Kasago} from "../../../models";
import { CHERY_TIGGO8_PRO_TOKEN, CHERY_TIGGO8_PRO_FULL_ANKETA_TOKEN } from "../utils/cheryTiggo8Pro";
import { isAnyChery } from "../utils/isChery";
import {pragmatikaModelMapping} from "../../../mapping/pragmatikaModelMapping";
import {PRAGMATIKA_TOKEN} from "../utils/isPragmatika";
import {selectClientApplicationId, selectDefaultParams} from "modules/app/selectors";
import {setDefaultCheryTiggo7InDefaultParams} from "../utils/isChery";
import {getState} from "helpers";
import {getBuyBackAmount} from "../utils/getBuyBackAmount";
import {isVolvoOnline} from "../utils/isVolvo";
import {validateKaskoParams} from "../../kasko/validateKaskoParams";
import {selectShowFormImmediate} from "modules/app/selectors";

const ALTERNATIVE_DESIGN_FLAG = '2';

const initAlternativeDesign = flag => {
  return flag === ALTERNATIVE_DESIGN_FLAG;
};

const initPrice = (defaultPrice) => {
  const price = Number(defaultPrice);

  return price ? price : 0;
};

const normalizeInitialFee = (initialFee) => initialFee > 99 || initialFee < 1 ? DEFAULT_INITIAL_FEE : initialFee;

const initInitialFee = (defaultPrice, defaultInitialFee, defaultInitialFeeMoney) => {
  const initialFee = Number(defaultInitialFee);

  if (initialFee) {
    return normalizeInitialFee(initialFee);
  }

  const price = Number(defaultPrice);
  const initialFeeMoney = Number(defaultInitialFeeMoney);

  if (price && initialFeeMoney) {
    return normalizeInitialFee(Math.floor(100 * initialFeeMoney / price));
  }

  return DEFAULT_INITIAL_FEE;
};

const normalizeInitialFeeMoney = (initialFeeMoney, price) =>
  price && initialFeeMoney > price ? Math.floor(price / 2) : initialFeeMoney;

const initInitialFeeMoney = (defaultPrice, defaultInitialFee, defaultInitialFeeMoney) => {
  const price = Number(defaultPrice);
  const initialFee = normalizeInitialFee(Number(defaultInitialFee));

  if (price && initialFee) {
    return Math.floor(price / 100 * initialFee);
  }

  const initialFeeMoney = Number(defaultInitialFeeMoney);

  if (initialFeeMoney) {
    return normalizeInitialFeeMoney(initialFeeMoney, price);
  }

  return 0;
};

const initDealerIdType = (defaultParams) => {
  if (isMazda(defaultParams.token)) {
    return DealerIdType.MANUFACTURE;
  }

  if (!defaultParams.dealer_id_type) {
    return DealerIdType.ECREDIT;
  }

  return defaultParams.dealer_id_type;
};

function getCookieSimple(name, value) {
  var cookies = document.cookie.split(/;/);
  var cookie;
  for (var i = 0; i < cookies.length; i++) {
    cookie = cookies[i].split(/=/);
    cookie[0] = cookie[0].replace(/^\s+/, '').replace(/\s+$/, '')
    if (name == cookie[0]) {
      return cookie[1];
    }
  }

  if (value !== undefined){
    setCookieSimple(name, value, '+2y', '/');
    return value;
  }

  return null
}

function setCookieSimple(name, value) {
  var str = name + "=" + value;
  for (var i = 2; i < arguments.length; i++) {
    var arg = arguments[i]
    switch (i) {
      case 2:
        if (typeof (arg) != 'undefined') {
          if (typeof (arg) != 'object') {
            var s = 1000, m = 60 * s, h = 60 * m, d = 24 * h, mon = 30 * d, y = 12 * mon
            arg = new Date(new Date().getTime() + eval('0' + arg.toString().toLowerCase().replace(/([0-9]+)([a-z]+)/g, '$1*$2')))
          }
          str += "; expires=" + arg.toGMTString()
        }
        break
      case 3:
        str += "; path=" + arg
        break
      case 4:
        str += "; domain=" + arg
        break
      case 5:
        str += "; secure"
        break
    }
  }
  document.cookie = str
}

export const setIntercomSettingsToDefaultParams = () => {

  const defaultParams = selectDefaultParams(getState());

  defaultParams.intercomSettings = {
    appID: 'c60o2n8y',
    custom_launcher_selector: '#show_intercom_button',
    hide_default_launcher: true
  };

  reducers.app.setDefaultParams(defaultParams);

};

const getInitialStep = (configuration, defaultStep, defaultParams) => {
  let initialStep = QuestionaryStepType.INFO;

  if (configuration.application_info && configuration.application_info.show_frame_immediate == SwitchType.ON){
    initialStep = QuestionaryStepType.DOCUMENTS;
  }

  if (defaultStep){
    initialStep = defaultStep;
  }

  try{
    if (sessionStorage.getItem('is_page_reload') == '1' && isHyundaiDosProject()){
      initialStep = QuestionaryStepType.THANK_YOU_PAGE;
    }
  }catch (e){

  }

  return initialStep;
}

const setDecryptedClientFields = (defaultParams, configuration) =>
{
  defaultParams.model_image = configuration.model_image ? configuration.model_image : '';

  for (let i in configuration.decoded_client_fields){
    defaultParams[i] = configuration.decoded_client_fields[i];
  }

  return defaultParams;
}

const setDefaultCheryTiggo8ProCar = (defaultParams) => {

  if (defaultParams.token != CHERY_TIGGO8_PRO_TOKEN && defaultParams.token != CHERY_TIGGO8_PRO_FULL_ANKETA_TOKEN){
    return defaultParams;
  }

  if (!defaultParams.brand || !defaultParams.model || !defaultParams.price) {
    defaultParams.brand = 'Chery';
    defaultParams.model = 'Tiggo 8 Pro';
    defaultParams.price = '1999900';
  }

  defaultParams.configuration = !defaultParams.configuration || defaultParams.configuration=='' || defaultParams.configuration == NEW_CAR_CONFIGURATION_DEFAULT
    ? '2.0T CVT Prestige'
    : defaultParams.configuration;

  return defaultParams;
}

const normilizeBuybackPayment = (buybackPayment, price) => {

  buybackPayment = buybackPayment ? Number(buybackPayment) : null;

  if (!price || price<100){
    buybackPayment = null;
  }

  if (buybackPayment && buybackPayment > 1 && buybackPayment < 99){
    buybackPayment = Math.round(price * buybackPayment / 100);
  }

  return buybackPayment;
}

// TODO: Should be refactored
export const setAppConfig = async (query) => {
  const { error_code: errorCode , configuration } = await request.get(api.config, { query });

  if (isRequestFailed(errorCode)) {
    return;
  }

  const defaultParams = Object.assign({}, query, { token: configuration.token });

  if (configuration.recognized_car) {
    defaultParams.brand = configuration.recognized_car.brand.name;
    defaultParams.model = configuration.recognized_car.model.name;
    defaultParams.configuration = configuration.recognized_car.config.name.length ? configuration.recognized_car.config.name : NEW_CAR_CONFIGURATION_DEFAULT;
    if (!defaultParams.price && configuration.recognized_car.config.price) {
      defaultParams.price = configuration.recognized_car.config.price;
    }
  } else if (configuration.normalized_brand) {
    defaultParams.brand = configuration.normalized_brand ;
  }

  //Если переданы все параметры автомобиля, то блокировать смену автомобиля в селектах, кроме отдельных кейсов
  //Пока что не понятно, как определять такие кейсы
  defaultParams.blockCarSeletsFromCard = false;

  if (isAnyChery(configuration.token)) {
    defaultParams.blockCarSeletsFromCard = false;
  }

  setDecryptedClientFields(defaultParams, configuration);

  reducers.app.setDefaultParams(defaultParams);
  const clientApplicationId = selectClientApplicationId(getState());
  const kasagoParams = await Kasago.getKasagoSavedParams(clientApplicationId);

  const hyundaiShowRoomUrl = getHyundaiShowRoomUrl();

  // if (configuration.application_info.fields && configuration.application_info.fields.application_state){
  //   const stateId = Number(configuration.application_info.fields.application_state.id);
  // }

  await reducers.app.setAppConfig(configuration);

  //Установка начального статуса именно после обновления configuration, чтобы в сторе появился флаг process_kasko
  const initialStep = getInitialStep(configuration, defaultParams.step, defaultParams);

  reducers.app.update({
    application_id: configuration.application_info.fields ? configuration.application_info.fields.id : null,
    api_application_id: configuration.application_info.fields ? configuration.application_info.fields.api_application_id : null,
    initialStep,
    kasagoParams
  })

  reducers.car.update({initial_fee: configuration.initial_fee, initial_fee_money: configuration.initial_fee_money});

  let docsCarInfo = {};
  if (configuration.application_info && configuration.application_info.fields && configuration.application_info.fields.car){
    const car = configuration.application_info.fields.car;
    docsCarInfo = {
      pts: {
        status: car.pts.status ? car.pts.status : 1,
        issue_date: car.pts.issue_date,
        number: car.pts.number,
        series: car.pts.series,
      },
      transmission_type: car.transmission_type,
      issue_date: car.issue_date,
      engine_power: car.engine_power,
      engine_volume: car.engine_volume,
      vin: car.vin || defaultParams.vin,
    };
  }

  const buyBackPayment = normilizeBuybackPayment(defaultParams.buyback_payment, defaultParams.price);
  let buyBackCheckbox = buyBackPayment && Number(buyBackPayment) > 0 ? true : false;

  if (isVolvoOnline()){
    buyBackCheckbox = true;
  }

  //У сузуки ломается тема. Временно ставим всегда выбор дефолтного
  let suzukiSetDefault = false;
  if (window.location.hostname == 'suzuki-online.e-credit.one' || (window.location.search && window.location.search.includes('token=suzuki'))) {
    suzukiSetDefault = true;
  }

  //Сразу ставить дилера в контекст. Если селекты не отображены, то дилера надо вытаскивать для запросов и придется костылить с проверкой из defaultParams
  //Но в темах, где отображен geo блок дилера по дефолту быть не должно. Он принудительно должен быть выбран
  //И если передан defaultParams.dealer_id, то тоже сразу ставить в контекст. Иначе на mmc селекты блокируются и дилера никак не выбрать
  if (configuration.blocks.dealer_block.show == SwitchType.OFF || defaultParams.dealer_id || suzukiSetDefault){
    reducers.car.update({city: configuration.current_city, dealer: configuration.current_dealer});
  }

  reducers.car.update({buyBackPayment, buyBackCheckbox, ...docsCarInfo});

  // if (defaultParams.dealer_id || suzukiSetDefault){
  //   reducers.car.update({buyBackPayment, buyBackCheckbox, city: configuration.current_city, dealer: configuration.current_dealer, ...docsCarInfo});
  // }else{
  //   reducers.car.update({buyBackPayment, buyBackCheckbox, ...docsCarInfo});
  // }

  reducers.app.setAppInitialized();

  if (configuration.application_info && configuration.application_info.message_for_client && configuration.application_info.message_for_client.length){
    if (defaultParams.debug){
      alert(configuration.application_info.message_for_client);
    }
  }

  let successReturnUrl = null;

  if (configuration.process_kasko == 1){
    successReturnUrl = hyundaiShowRoomUrl + '/cabinet?from=ecredit';
  }


  if (configuration.application_info && configuration.application_info.fields && configuration.application_info.fields.id){
    reducers.app.update({ application_id: configuration.application_info.fields.id })
    if (configuration.application_info.show_frame_immediate){
      successReturnUrl = hyundaiShowRoomUrl + '/cabinet?from=ecredit&product=polis';
    }
  }

  reducers.app.update({successReturnUrl});

  // TODO: It shouldn't be here
  dictionary.getBrands();

}

/**
 * Если в запросе вставили два раза model=lada&model=lada, то такое значение сохраняется, как массив
 * model=["lada","lada"]
 * Нужно сохранять только одно значение
 */
const arrayItemsToOneStringValue = (data) => {
  for(let i in data){
    if (Array.isArray(data[i])){
      let value = null;
      data[i].forEach( el => { value = el && el.length ? el : value; } )
      data[i] = value;
    }
  }
  return data;
}

const getEnvironment = (defaultParams) => {
  return !defaultParams.environment && env.environment
    ? env.environment
    : defaultParams.environment;
}

const mappingModel = (defaultParams) => {
  if (defaultParams && defaultParams.model){
    switch (defaultParams.token){
      case PRAGMATIKA_TOKEN:
        defaultParams.model = pragmatikaModelMapping(defaultParams.model);
        break;
    }

    if (defaultParams.model && defaultParams.model.length){
      defaultParams.model = defaultParams.model.replace(/^[а-яё\s]+/ig,'');
    }
  }

  return defaultParams;
};

export const initApp = async () => {
  //Здесь записывается сервисный токен. Настоящий токен приедет с бека
  const { api_url: apiUrl, envName } = env;
  let defaultParams = arrayItemsToOneStringValue(qs.parse(window.location.search.replace('?', '')));

  reducers.app.setUntouchedInitialParams(defaultParams);

  //Это должно быть до любой логики
  Hyundai.toStandartParams(defaultParams);

  defaultParams = mappingModel(defaultParams);

  if (defaultParams.vin && defaultParams.vin.length){
    defaultParams.vin = defaultParams.vin.toUpperCase();
  }


  if (window!=window.top) {
    defaultParams.in_iframe = true;
  } else {
    defaultParams.in_iframe = false;
  }

  defaultParams.dealer_id_type = initDealerIdType(defaultParams);
  defaultParams.envName = envName;

  if (!defaultParams.api_url) {
    defaultParams.api_url = apiUrl;
  }

  defaultParams = setDefaultCheryTiggo8ProCar(defaultParams);
  defaultParams = setDefaultCheryTiggo7InDefaultParams(defaultParams);

  defaultParams.smsForce = defaultParams.sms_force ? defaultParams.sms_force : 0;
  defaultParams.price = initPrice(defaultParams.price);
  defaultParams.initial_fee = initInitialFee(defaultParams.price, defaultParams.initial_fee, defaultParams.initial_fee_money);

  // Эти данные устанавливаются на беке в конфигурационном методе
  // defaultParams.initial_fee_money = initInitialFeeMoney(
  //   defaultParams.price,
  //   defaultParams.initial_fee,
  //   defaultParams.initial_fee_money,
  // );
  //
  // defaultParams.initial_fee = initInitialFee(
  //   defaultParams.price,
  //   defaultParams.initial_fee,
  //   defaultParams.initial_fee_money,
  // );

  if (!defaultParams.configuration){
    defaultParams.configuration = USED_CAR_CONFIGURATION_DEFAULT;
  }

  defaultParams.alternativeDesign = initAlternativeDesign(defaultParams.param);

  defaultParams.environment = getEnvironment(defaultParams);

  reducers.app.setDefaultParams(defaultParams);

  if (defaultParams.token){
    reducers.app.update({
      appConfig: {token: defaultParams.token}
    });
  }

  await setAppConfig(defaultParams);
  setIntercomSettingsToDefaultParams();

  validateKaskoCarIfShowFrameImmediate();

  // TODO: Why do we do it here?
  dictionary.getDealers();
}

const validateKaskoCarIfShowFrameImmediate = () =>
{
  const showFrameImmediate = selectShowFormImmediate(getState());

  if (showFrameImmediate){
    validateKaskoParams();
  }

}
