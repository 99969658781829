import { addParamsToUrl } from "./addParamsToUrl";
import { getApiCredentials } from 'modules/app/utils/getApiCredentials';
import { selectCurrentStep } from "../modules/app";
import { selectClientApplicationId } from "../modules/app";
import { selectCarVin } from "../modules/car";
import { getState } from "../helpers";

const ApiMethods = {
  'agreement': 'documents/agreement',
  'saveInsurancePrepare': 'kasko/save-insurance-prepare',
  'saveFrameCommunicationData': 'kasko/save-frame-communication-data',
  'getInsurancePrepare': 'kasko/get-insurance-prepare',
  'getOnlineBanks': 'bank/online-banks',
  'sendTelegramNotify': 'telegram-notify/post-message-notify',
  'validateKaskoParams': 'kasko/validate-kasko-params',
  'graylogNotify': 'graylog/logg',
};

export function createApiMethodUrl(methodName, params = {}) {
  const { apiUrl, token } = getApiCredentials();
  const currentStep = selectCurrentStep(getState());
  const clientApplicationId = selectClientApplicationId(getState());
  const carVin = selectCarVin(getState());
  const { environment } = getState().app.defaultParams

  if (!params.client_application_id || params.client_application_id == '' || params.client_application_id === undefined){
    params.client_application_id = clientApplicationId;
  }

  if (!params.vin || params.vin == '' || params.vin === undefined){
    params.vin = carVin;
  }

  if (environment){
    params.environment = environment;
  }

  let url = apiUrl + '/' + methodName;
  url = addParamsToUrl(url, {'access-token':token, step: currentStep, ...params});
  return url;
}

export { ApiMethods };
