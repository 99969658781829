import { createApplicationFormSelector } from "./createApplicationFormSelector";
import { marital_status } from "helpers/lists";
import { MaritalStatus } from "helpers/constants";

export const selectMaritalStatus = (state) => {
  const martialStatus = createApplicationFormSelector(state, "marital_status");
  return martialStatus
    ? martialStatus
    //: marital_status.find(({ id }) => MaritalStatus.UNMARRIED === id);
    : null
};
