import request from 'superagent'
import { user } from 'redux/actions'
import { getState, isRequestFailed } from 'helpers';
import { ResponseCodeType } from 'helpers/constants';
import { selectCurrentStep } from "../modules/app";
import { selectClientApplicationId } from "../modules/app";
import { selectCarVin } from "../modules/car";
import { selectToken } from "../modules/app";

const SERVICE_TOKEN = `fihubenvironment`;
// Читай README(local)
// const SERVICE_TOKEN = `exeed`;

const doRequest = (url, options) => {
  /*const token = getState().user.token;
  const environment=getState().user.environment;
  const dealer_id=getState().user.dealer_id;
  const user_id=getState().user.user_id;
  const query = { 'access-token': token }
  */
  const { environment, dealer_id, user_id, /*token,*/ api_url, dealer_id_type } = getState().app.defaultParams;
  const { cron_request_id } = getState().app.appConfig;
  const currentStep = selectCurrentStep(getState());
  const clientApplicationId = selectClientApplicationId(getState());
  const carVin = selectCarVin(getState());

  let token = selectToken(getState());
  token = token ? token : SERVICE_TOKEN;

  const query = { 'access-token': token, 'dealer_id_type': dealer_id_type, 'cron_request_id': cron_request_id, 'step': currentStep, client_application_id: clientApplicationId, vin: carVin };

  if (environment)
      query.environment=environment;

  if (dealer_id)
      query.dealer_id=dealer_id;

  if (user_id)
      query.user_id=user_id;

  if (!options.method) options.method = 'get'

  if (!url.includes('http') && !url.includes('https')) {
    url = `${api_url}/${url}`;
  }

  const req = request[options.method.toLowerCase()](url)

  req.query({...query, ...options.query})

  if (options.headers) req.set(options.headers)
  if (options.body) req.send(options.body)
  if (!options.noPreloader) user.setLoading()

  return req
}

const requestByMethod = method => (url, options) => {
  options = options || {}

  return new Promise((resolve, reject) =>
    doRequest(url, { ...options, method })
      .end((err, data) => {
        const isLegit = typeof data === 'object' && 'body' in data

        if (!isLegit) {
          user.clearLoading()
          user.setError("Response doesn't have «body»")
          return reject(data && data.body)
        }

        if (!options.carfin && data.body) {
          data.body.response.error_code = data.body.error_code || ResponseCodeType.SUCCESS_CODE;
        }

        const body = options.carfin || isRequestFailed(data.body.response.error_code)
          ? JSON.parse(data.text)
          : data.body.response

        // TODO: This logic mustn't be here
        if (!options.noPreloader) {
          user.clearLoading()
        }

        if (isLegit) {
          return resolve(body || { data: data.text })
        }
    }),
  )
}

doRequest.get = requestByMethod('get')
doRequest.post = requestByMethod('post')
doRequest.patch = requestByMethod('patch')
doRequest.put = requestByMethod('put')
doRequest.delete = requestByMethod('delete')
doRequest.upload = requestByMethod('post')

export default doRequest
