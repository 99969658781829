import React from 'react'
import cx from 'classnames'
import { Flex } from 'components/layouts'
import { Backdrop } from 'components/UI'
import {ReactComponent as CloseSvg} from "assets/close-light.svg"
import s from './styles.module.sass'


export const Modal = ({ onClose, className, children, ...props }) =>
  <Flex center className={s.container}>
    <Backdrop onClick={onClose} active={true} />
    <Flex relative column center className={cx( s.modal, className )} {...props} style={{display:`block`}}>
      <div className={s.content}>{children}</div>
      <CloseSvg className={s.close} onClick={onClose} />
    </Flex>
  </Flex>
