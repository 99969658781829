import React from 'react';
import PropTypes from 'prop-types';
import ReactDadata from './Dadata';

import { GridCell } from 'components/UI/grid/components/GridCell';
import { useAlternativeInput } from '../Input/useAlternativeInput';

import './styles.scss';

// TODO: It shouldn't be here. May be an environment variable
const token = 'f5d81d5eb31f628e81d688fed7beb9428984a41f';

export const Flat = ({
  width,
  placeholder = 'Введите',
  meta,
  value = {},
  ...props
}) => {
  const query = typeof value === 'string' ? value :  value.value;
  const error = meta && meta.touched ? meta.error : '';
  const inputField = useAlternativeInput();

  return (
    <GridCell width={width}>
      <ReactDadata
        token={token}
        placeholder={placeholder}
        query={query}
        error={error}
        inputField={inputField}
        {...props}
      />
    </GridCell>
  );
}

Flat.propTypes = {
  label: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  notRequired: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
    }),
    PropTypes.string,
  ]),
};

export const Redux = ({ input, ...props }) =>
  <Flat {...input} {...props} />

Redux.propTypes = {
  input: PropTypes.object,
};
