import reducers from "redux/reducers";
import { getState } from "helpers";
import { dictionary } from "redux/actions";
import { selectAppConfig } from "../selectors";

export const reInitCarSelects = (carCondition) => {
  const appConfig = selectAppConfig(getState());
  reducers.app.update({ appConfig: {...appConfig, car_condition: carCondition} });
  dictionary.getBrands();
};
