import { getState } from "../../helpers";
import { selectCar } from "../../modules/car";
import {timeStampToDate} from "../../modules/utils";

export const getPts = calculatedCarIssueDate => {
  const car = selectCar(getState());
  const pts = car.pts;

  let carIssueDateTimeStamp = Date.parse(calculatedCarIssueDate);
  let ptsIssueDateTimeStamp = Date.parse(pts.issue_date);

  // Дата выдачи ПТС должна быть меньше или равна carIssueDate
  if (ptsIssueDateTimeStamp > carIssueDateTimeStamp) {
    ptsIssueDateTimeStamp = carIssueDateTimeStamp;
  }

  pts.issue_date = timeStampToDate(new Date(ptsIssueDateTimeStamp));

  return pts;
};
