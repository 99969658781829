import { QuestionaryStepType } from "../pages/Main/components/AppForm/constants";

class Calculator
{
  static isCalculatorEnabledOnStep(step)
  {
    return step === QuestionaryStepType.INFO;
  }
}

export {Calculator};
