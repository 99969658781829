export const TypographyType = {
  MAIN_HEADER: 'main-header',
  BLOCK_HEADER: 'block-header',
  RESULT_HEADER: 'result-header',
  SECTION_HEADER: 'section-header',
  SUB_HEADER: 'sub-header',
  PARAGRAPH: 'paragraph',
  MARGIN_PARAGRAPH: 'margin-paragraph',
  H5: 'h5',
  CAPTION: 'caption',
}
