import {getApplicationFormValues} from "./getApplicationFormValues";
import {passportToBackendModel} from "../passportToBackendModel";
import {toBackendDate} from "../dateModels";

export const getPassport = () => {
  const formValues = getApplicationFormValues();

  return {
    ...passportToBackendModel(formValues.passport),
    issueDate: toBackendDate(formValues.passport_issue_date),
    issuer: formValues.passport_issuer_name,
    issuerCode: formValues.passport_issuer_code
  };
};
