import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from "components/reduxForm";

export const BuyBack = ({ programsEnabled, handleProgram, buyBackCheckbox }) => {

  return (
    <Checkbox.Flat
      label="Кредит с остаточным платежом"
      value={buyBackCheckbox}
      onChange={handleProgram}
      help="Вид кредита, при котором часть кредита исключается из ежемесячных платежей. Это позволяет существенно снизить ежемесячные платежи. Такой вид кредита подойдет вам, если вы не планируете владеть автомобилем больше срока кредита. В конце срока вы продаете автомобиль дилеру, погашаете кредит, а оставшуюся сумму используете в качестве первоначального взноса за новый автомобиль."
      name="buyBackCheckbox"
      disabled={!programsEnabled}
    />
  );
};

BuyBack.propTypes = {
  buyBackCheckbox: PropTypes.bool,
  programsEnabled: PropTypes.bool,
  handleProgram: PropTypes.func
};
