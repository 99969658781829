import { string, object, boolean } from 'yup';
import { differenceInYears } from 'date-fns';
import { MaritalStatus } from 'helpers/constants';
import { stringToDate } from 'helpers';

const MARRIED_START_AGE = 16;

const isNotMarried = val => val !== MaritalStatus.MARRIED;

export const validationSchema = object().shape({
  'birth_date': string() // This field is not visible on Docs form (part of Info), if form will be splitted schema must be changed
    .required()
    .date()
    .adult(),
  'marital_status': object()
    .required(),
  'proxy_second_name': string()
    .when('marital_status.id', {
      is: isNotMarried,
      then: string().required(),
    }),
  'proxy_first_name': string()
    .when('marital_status.id', {
      is: isNotMarried,
      then: string().required(),
    }),
  'proxy_third_name': string()
    .when('marital_status.id', {
      is: isNotMarried,
      then: string().required(),
    }),
  'proxy_phone': string()
    .when('marital_status.id', {
      is: isNotMarried,
      then: string()
        .required()
        .phone(),
    }),
  'proxy_birth_date': string()
    .when('marital_status.id', {
      is: isNotMarried,
      then: string()
        .required()
        .date()
        .adult(),
    }),
  'num_of_children_younger_than_21': string()
    .required(),
  'number_of_dependents': string()
    .required()
    .when('num_of_children_younger_than_21', function (numOfChildrenLessThan21) {
      return this.test({
        test: (value) => value >= numOfChildrenLessThan21,
        message: 'Не может быть меньше кол-ва детей до 21 года',
      });
    }),
  'years_married': string()
    .when('marital_status.id', {
      is: MaritalStatus.MARRIED,
      then: string().required()
        .when(['birth_date', 'spouse_birth_date'], function (birthDate, spouseBirthDate) {
          return this.test({
            test: value => {
              if (!birthDate || !spouseBirthDate) {
                return false;
              }
              const clientBirthYear = stringToDate(birthDate);
              const spouseBirthYear = stringToDate(spouseBirthDate);
              const yearsMarried = Number(value);
              const clientAge = differenceInYears(new Date(), clientBirthYear);
              const spouseAge = differenceInYears(new Date(), spouseBirthYear);
              if (clientAge - MARRIED_START_AGE > yearsMarried && spouseAge - MARRIED_START_AGE > yearsMarried) {
                return true;
              }
              return false;
            },
            message: 'Супругам должно быть не менее 16 лет.',
          });
        })
    }),
  'spouse_surname': string()
    .when('marital_status.id', {
      is: MaritalStatus.MARRIED,
      then: string().required(),
    }),
  'spouse_name': string()
    .when('marital_status.id', {
      is: MaritalStatus.MARRIED,
      then: string().required(),
    }),
  'spouse_middle_name': string()
    .when('marital_status.id', {
      is: MaritalStatus.MARRIED,
      then: string().required(),
    }),
  'spouse_mobile': string()
    .when('marital_status.id', {
      is: MaritalStatus.MARRIED,
      then: string()
        .required()
        .phone(),
    }),
  'spouse_birth_date': string()
    .when('marital_status.id', {
      is: MaritalStatus.MARRIED,
      then: string()
        .required()
        .date()
        .adult(),
    }),
  'spouse_birth_place': object()
    .when('marital_status.id', {
      is: MaritalStatus.MARRIED,
      then: object()
        .dadataRequired()
        .thereAreRussianLettersDadata(),
    }),
  'spouse_employment_type': object()
    .when('marital_status.id', {
      is: MaritalStatus.MARRIED,
      then: object()
        .required(),
    }),
  'passport': string().required().passport(),
  'passport_issue_date': string()
    .nullable()
    .required()
    .date()
    .notFutureDate()
    .minDateDependsOnOtherDate('birth_date', 14)
    .when('birth_date', function (birthDateString) {
      const birthDate = stringToDate(birthDateString);
      const age = differenceInYears(new Date(), birthDate);

      if (age < 20) {
        return this.minDateDependsOnOtherDate('birth_date', 14)
      } else if (age < 45) {
        return this.minDateDependsOnOtherDate('birth_date', 20)
      } else {
        return this.minDateDependsOnOtherDate('birth_date', 45)
      }
    })
    .when(['no_previous_passport', 'previous_issue_date'], function (
      noPreviousIssuerDate,
      previousIssuerDate,
    ) {
      return this.test({
        test: value => {
          if (noPreviousIssuerDate || previousIssuerDate === undefined) {
            return true;
          }
          const passportIssuerDate = stringToDate(value);
          return passportIssuerDate > stringToDate(previousIssuerDate);
        },
        message: 'Дата выдачи паспорта должна быть больше даты выдачи предыдущего паспорта',
      })
    }),
  'passport_issuer_code': string()
    .nullable()
    .required()
    .passportIssuerCode(),
  'passport_issuer_name': string()
    .nullable()
    .required()
    .thereAreRussianLetters(),
  'birth_place': object()
    .dadataRequired()
    .thereAreRussianLettersDadata(),
  'no_previous_passport': boolean(),
  'fio_was_change': boolean(),
  'previous_passport': string().when("no_previous_passport", {
    is: false,
    then: string().required().passport(),
  }),
  'previous_issue_date': string()
    .when('no_previous_passport', {
      is: false,
      then: string()
        .required()
        .date()
        .notFutureDate()
        .minDateDependsOnOtherDate('birth_date', 14),
  }),
  'previous_issuer_code': string()
  .when('no_previous_passport', {
    is: false,
    then: string()
      .required()
      .passportIssuerCode(),
  }),
  'previous_issuer_name': string()
    .when('no_previous_passport', {
      is: false,
      then: string()
        .required()
        .thereAreRussianLetters(),
    }),
  'previous_surname': string()
    .when('fio_was_change', {
      is: true,
      then: string().required(),
    }),
  'registration_address': object()
    .dadataRequired()
    .thereAreRussianLettersDadata()
    .accuracyToHouseNumber(),
  'index': string()
    .nullable()
    .required()
    .postIndex(),
  'realty_state': object()
    .required(),
  'registration_address_date': string()
    .required()
    .date()
    .notFutureDate()
    .minDateDependsOnOtherDate('birth_date'),
  'matches_registration': boolean(),
  'living_address': object()
    .when('matches_registration', {
      is: false,
      then: object()
        .dadataRequired()
        .thereAreRussianLettersDadata(),
    }),
  'la_index': string()
    .nullable()
    .when('matches_registration', {
      is: false,
      then: string()
        .required()
        .postIndex(),
  }),
  'la_registration_address_date': string()
    .when('matches_registration', {
      is: false,
      then: string()
        .required()
        .date()
        .notFutureDate()
        .minDateDependsOnOtherDate('birth_date'),
  }),
  'spendings_anycredit': string()
    .required(),
  'spendings_request': string()
    .required(),
});
