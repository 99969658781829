import React from 'react'
import PropTypes from 'prop-types';

import { Button, Modal } from 'components/UI';
import { AgreementType } from './constants';

import './agreement.scss';
import { AgreementFactory } from './AgreementFactory';

export const Agreement = ({ type, onClose }) => {
  if (!type) {
    return null;
  }

  return (
    <Modal onClose={onClose} className="agreement">
      <AgreementFactory type={type} />
      <Button onClick={onClose}>Закрыть</Button>
    </Modal>
  )
};

Agreement.propTypes = {
  type: PropTypes.oneOf(Object.values(AgreementType)),
  onClose: PropTypes.func,
};
