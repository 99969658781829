import { getState } from "../../../helpers";
import { selectToken } from "../selectors";
import {NEW_CAR_CONFIGURATION_DEFAULT} from "../../../helpers/constants";
import {car} from 'redux/actions'
import {CHERY_TOKEN2} from "./cheryTiggo8Pro";

export const CHERY_TOKEN = 'hyt67gfr43edfr65dd';

export const CHERY_ONLINE_TOKEN = 'cheryonline';

export const CHERY_CALC_TOKEN = 'cherycalc';

export const CHERY_FULL_TOKEN = 'cheryfull';

export const CHERYNEW = 'cherynew';

export const isChery = (token) => {
  token = token && token.length ? token : selectToken(getState());
  return token === CHERY_TOKEN;
}

export const isCheryOnline = () => {
  const token = selectToken(getState());
  return token === CHERY_ONLINE_TOKEN;
}

export const isCheryCalc = () => {
  const token = selectToken(getState());
  return token === CHERY_CALC_TOKEN;
}

export const isCheryFull = () => {
  const token = selectToken(getState());
  return token === CHERY_FULL_TOKEN;
}

export const isAnyChery = (token) =>
{
  token = token && token.length ? token : selectToken(getState());
  return isChery(token) || isCheryOnline(token) || isCheryFull(token) || isCheryCalc(token);
}

export const replacePercentForChery = (allOffers) => {

  if (!(isChery() || isCheryOnline() || isCheryCalc() || isCheryFull()) || !allOffers || !allOffers.offers){
    return allOffers;
  }

  for(let i in allOffers.offers){
    for(let j in allOffers.offers[i]){
      if(allOffers.offers[i][j].interest_rate == 0.01){
        allOffers.offers[i][j].interest_rate = 0;
      }
    }
  }

  return allOffers;
}

export const setDefaultCheryTiggo7InDefaultParams = (defaultParams) => {
  if (defaultParams.token != CHERY_TOKEN && defaultParams.token != CHERY_ONLINE_TOKEN && defaultParams.token != CHERY_CALC_TOKEN){
    return defaultParams;
  }

  if (!defaultParams.brand || !defaultParams.model || !defaultParams.price) {
    defaultParams.brand = 'Chery';
    defaultParams.model = 'Tiggo 7 Pro';
    //defaultParams.configuration = '1.5T CVT Elite  (1 659 900 ₽)';
    //defaultParams.price = 1659900;
  }

  return defaultParams;
}

export const getDefaultCheryConfigurationForCurrentModel = (configuration, allConfigs) => {
  let config = false;

  if (!isChery() && !isCheryOnline() && !isCheryCalc() && !isCheryFull()){
    return config;
  }

  if (configuration == NEW_CAR_CONFIGURATION_DEFAULT && allConfigs && allConfigs.length){
    config = allConfigs[0];
    car.update({price: config.price, initial_fee_money: config.price/2});
  }

  return config;
};
