import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types'
import axiosRequest from "helpers/axiosRequest";

import {Card} from "components/UI/Card";
import {FilesUploader} from "components/UI/files-uploader";
import {Button, Modal} from "components/UI";
import {useSelector} from "react-redux";
import {selectApplicationId, selectToken} from "modules/app/selectors";

import "./styles.scss";
import {Preloader} from "components/layouts/Preloader";

import {HYUNDAI_DOS_TOKEN} from "modules/app/utils/isHyundaiDosProject";
import {scrollToElement} from "helpers";

export const DocumentsUploader = ({onSuccess = () => {}}) => {
  const applicationId = useSelector(selectApplicationId) || '295';
  const accessToken = useSelector(selectToken) || HYUNDAI_DOS_TOKEN;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState({
    passport: [],
    carDocument: []
  });

  const messagesConfig = {
    serverError: 'Возникла ошибка на сервере. Попробуйте позже',
    formatSizeNotice: 'Максимальный размер файла: 2 мб. Поддерживаемые форматы: .pdf, .png, .jpeg, .jpg',
    maxFileSizeMessage: 'Размер файла не должен превышать 2 мегабайта'
  }

  const allowedUploadFormats = ['image/x-png', 'image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
  const isButtonDisabled = useCallback(() => !selectedFiles.passport.length || !selectedFiles.carDocument.length, [selectedFiles])

  useEffect(() => {
    scrollToElement("app-form");
  }, [])

  // eslint-disable-next-line react/prop-types
  const CardHead = ({title = '', desc = ''}) => (
    <div className="card-head">
      <div className="card-head__title">{title}</div>
      <div className="card-head__description">{desc}</div>
    </div>
  );

  const handleFilesSelect = ({files, type}) => {
    setSelectedFiles({...selectedFiles, [type]: files});
  }

  const sendFiles = async () => {
    setLoading(true);
    const formData = new FormData;
    formData.append('application_id', applicationId)
    selectedFiles.passport.forEach(file => formData.append('passportFile[]', file));
    selectedFiles.carDocument.forEach(file => formData.append('drivingFile[]', file));
    try {
      const {data} = await axiosRequest.post(`/uploader/customer-files-upload?access-token=${accessToken}`, formData);
      const response = data.response.data;
      console.log("File upload response", response);
      setLoading(false);
      if (response.error_code !== 200) {
        return setError(response.error_text);
      }
      onSuccess();
      return setError(null);
    } catch (e) {
      setError(messagesConfig.serverError);
      setLoading(false);
    }
  }

  const handleUploadError = (error) => {
    setError(error);
  }

  return (
    <div className="documents-uploader">
      <h2>Загрузите документы для проведения сделки</h2>
      <div className="documents-uploader__cards">
        <Card title={<CardHead title='Паспорт РФ' desc='Все заполненные страницы паспорта'/>}
              selected={!!selectedFiles.passport.length}>
          <div className="card-content">
            <FilesUploader onChange={(files) => handleFilesSelect({files, type: 'passport'})}
                           onError={handleUploadError}
                           allowedFormats={allowedUploadFormats}
                           maxFileSizeMessage={messagesConfig.maxFileSizeMessage} />
          </div>
        </Card>
        <Card title={<CardHead title='Водительское удостоверение' desc='Обе стороны'/>}
              selected={!!selectedFiles.carDocument.length}>
          <div className="card-content">
            <FilesUploader onChange={(files) => handleFilesSelect({files, type: 'carDocument'})}
                           onError={handleUploadError}
                           allowedFormats={allowedUploadFormats}
                           maxFileSizeMessage={messagesConfig.maxFileSizeMessage} />
          </div>
        </Card>
      </div>
      <div className="documents-uploader__caption">
        {messagesConfig.formatSizeNotice}
      </div>
      <Button className="documents-uploader__button" disabled={isButtonDisabled()} onClick={sendFiles}>
        Перейти к оплате ОСАГО
      </Button>
      {error && <Modal onClose={() => setError(null)}>
        <div className="error">
          <div className="error__header">Ошибка</div>
          {error}</div>
      </Modal>}
      <Preloader isShow={loading}/>
    </div>
  )
}

DocumentsUploader.propTypes = {
  onSuccess: PropTypes.func
}
