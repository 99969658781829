import React from 'react'
import cx from 'classnames'

import './styles.scss'


export const Flex = ({
  children,
  f1,
  column,
  center,
  justifyBetween,
  justifyEvenly,
  alignCenter,
  alignLeft,
  fullWidth,
  half,
  third,
  third2,
  quarter,
  stretch,
  wrap,
  relative,
  className,
  ...props
  }) =>
  <div
    className={ cx(
      'flex',
      className,
      f1              && 'f1',
      column          && 'column',
      center          && 'center',
      justifyBetween  && 'jcsb',
      justifyEvenly   && 'jcse',
      alignCenter     && 'aic',
      alignLeft       && 'ail',
      fullWidth       && 'w100',
      half            && 'half',
      third           && 'third',
      third2          && 'third2',
      quarter         && 'quarter',
      stretch         && 'stretch',
      wrap            && 'wrap',
      relative        && 'relative',
    )}
    {...props}
  >
    {children}
  </div>
