import React, {useState, useCallback, useMemo, useEffect} from "react";
import {Field} from "redux-form";
import {CONFIRM_PHONE_SMS_CODE_INPUT_ID} from "helpers/constants";
import {Input} from "components/reduxForm";
import {Button, InputModeType} from "components/UI";
import {useTimer} from "pages/Main/components/AppForm/info/useTimer";
import {
  confirmSMSCode,
  selectSmsCodeLifeTime,
  sendSMSCode
} from "modules/app";
import {focusElement} from "helpers";
import {useSelector} from "react-redux";
import {selectSMSCode} from "modules/form";
import "./sms-confirmation.scss";
import {clearInput} from "helpers/clearInput";

export const SmsConfirmation = ({text = '', agreementText = '', mobilePhoneError, onConfirmed = () => {}}) => {
  const [isCodeFieldActive, setCodeFieldActive] = useState(false);
  const [isCodeSent, setCodeSent] = useState(false);
  const smsCode = useSelector(selectSMSCode);
  const smsCodeLifeTime = useSelector(selectSmsCodeLifeTime);
  const {onStart, onStop, isStarted, timerString} = useTimer();

  useEffect(() => {
    sendSMS().then(() => true);
  }, [])

  const confirmCode = useCallback(async () => {
    const errors = await confirmSMSCode();
    if (!errors) {
      onStop();
      setCodeFieldActive(false);
      onConfirmed({confirmed: true});
    }
  }, [onStop, setCodeFieldActive]);

  useMemo(() => {
    if(!isStarted) {
      setCodeFieldActive(true);
      setCodeSent(false);
    }
  }, [isStarted])

  const sendSMS = async () => {
    if (!mobilePhoneError) {
      const errors = await sendSMSCode();

      if (errors) {
        return;
      }
      onStart(smsCodeLifeTime);
      setCodeFieldActive(true);
      setCodeSent(true);
      focusElement(CONFIRM_PHONE_SMS_CODE_INPUT_ID);
      clearInput(CONFIRM_PHONE_SMS_CODE_INPUT_ID);
    }
  }

  const isSMSCodeValid = smsCode && smsCode.length >= 4;
  return (
    <div className="sms-confirmation">
      <div className="sms-confirmation__block">
        <div className="sms-confirmation__text">
          {text}
        </div>
        <Field
          id={CONFIRM_PHONE_SMS_CODE_INPUT_ID}
          component={Input.Redux}
          name="sms_code"
          label="Код из смс"
          placeholder=""
          width="2"
          mask={Number}
          inputMode={InputModeType.NUMERIC}
          disabled={!isCodeFieldActive}
        />
        {isCodeSent && isStarted &&
        <div className="sms-confirmation__timer">{timerString}</div> || ''}
        {!isCodeSent && !isStarted && <Button
          flat
          onClick={sendSMS}
          disabled={isStarted}
          className="info__repeat-sms-code-button"
        >
          Отправить код повторно
        </Button> || ''}
      </div>
      <div className="sms-confirmation__bottom">
        <small>{agreementText}</small>
        <Button className="sms-confirmation__submit" onClick={confirmCode}
                disabled={!isSMSCodeValid}>Продолжить</Button>
      </div>
    </div> || ''
  );
}
