import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tippy from "@tippyjs/react";


import 'tippy.js/dist/tippy.css';
import './tooltip.scss';

export const Tooltip = ({ content, children, className, ...props }) => {
  return (
    <Tippy
      content={content}
      {...props}
      className={classnames("tooltip", className)}
    >
      {children}
    </Tippy>
  );
}

Tooltip.propTypes = {
  content: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};
