import { toFrontendDate } from "./dateModels";

export function passportToFrontendModel(passport) {
  if (typeof passport !== "object") {
    return;
  }

  const {
    series,
    number,
    issue_date,
    issuer_code,
    issuer_name,
  } = passport;

  return {
    passport: series + '' + number,
    passport_issue_date: toFrontendDate(issue_date),
    passport_issuer_code: issuer_code,
    passport_issuer_name: issuer_name,
  };
}

export function previousPassportToFrontendModel(passport) {
  if (typeof passport !== "object") {
    return;
  }

  const {
    series,
    number,
    issue_date,
    issuer_code,
    issuer_name,
  } = passport;

  return {
    previous_passport: series + '' + number,
    previous_issue_date: toFrontendDate(issue_date),
    previous_issuer_code: issuer_code,
    previous_issuer_name: issuer_name,
  };
}
