import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCarBrandName } from "modules/car";
import { selectCarCondition } from "modules/app";
import { onlineBanks } from "api/online-banks";

export const EDocBanks = () => {
  const [banks, setBanks] = useState([]);
  const brandName = useSelector(selectCarBrandName);
  const carCondition = useSelector(selectCarCondition);

  useEffect(() => {
    const loadOnlineBanks = async () => {
      //const { legals } = await onlineBanks.loadBanks(carCondition, brandName);
      const { legals } = await onlineBanks.loadBanks();
      setBanks(legals);
    };
    loadOnlineBanks();
  }, [brandName, carCondition]);

  return banks.map(({ legal_name: legalName, legal_type: legalType, inn, ogrn, face }, i) => (
    <tr key={legalName}>
      <td>{ i+1 }</td>
      <td>{`${legalType} ${legalName}`}</td>
      <td>{inn}</td>
      <td>{ogrn}</td>
    </tr>
  ))
};
