const normalizeValue = (value) => value.replace(/null|undefined/g, "").trim();

export const addressSimpleFieldsToFrontendModel = (address) => {
  return address.dadata && address.dadata && address.dadata.data
  ? {
    index: address.dadata.data.postal_code
    }
  : {};
};

export const addressToFrontendModel = address => {
  return address && address.dadata && address.dadata.data
    ? {
      value: address.dadata.value,
      unrestricted_value: address.dadata.unrestricted_value,
      data: address.dadata.data
    }
    : {};
}
