import reducers from 'redux/reducers';
import { getOffers } from './actions';
import { getState } from 'helpers';

export const choseProgram = (name) => {
  const { car } = getState();
  const value = !car[name];

  const program = {
    firstAuto: value ? false : car.firstAuto,
    familyAuto: value ? false : car.familyAuto,
    medicalWorker: value ? false : car.medicalWorker,
    tradeIn: value ? false : car.tradeIn,
    period: null,
    chosen: null,
    [name]: value,
  };

  reducers.car.update(program);
  getOffers();
};
