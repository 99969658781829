import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss'
import {CarouselButton} from "components/UI/carousel/carousel-button";
export const Carousel = ({children, className, options}, ref) => {
  if (!children || !children.length) return;
  const mergedOptions = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <CarouselButton direction='next' />,
    prevArrow: <CarouselButton direction='previous' />,
    ...options
  }
  return (
      <Slider ref={ref} className={className} {...mergedOptions}>
        {children}
      </Slider>
  )
}

export default React.forwardRef(Carousel)
