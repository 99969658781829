import {addMethod, string, object, boolean} from 'yup';
import {addYears, isValid} from 'date-fns';
import {stringToDate} from 'helpers';


function selected() {
  return this.test(
    'selected',
    'Должно быть выбрано',
    value => value,
  );
}

addMethod(boolean, 'selected', selected);

function dadataRequired() {
  return this.test(
    'dadataRequired',
    'Обязательное поле',
    (value) => value && !!value.value,
  );
}

addMethod(object, 'dadataRequired', dadataRequired);

function thereAreRussianLettersDadata() {
  return this.test(
    'thereAreRussianLettersDadata',
    'Невалидное значение',
    (value) => value && /[А-я]/g.test(value.value),
  );
}

addMethod(object, 'thereAreRussianLettersDadata', thereAreRussianLettersDadata);

function accuracyToHouseNumber()
{
  return this.test(
    'accuracyToHouseNumber',
    'Необходимо ввести адрес с точностью до дома',
    (value) => {
      return value && value.data && value.data.house && value.data.house.length > 0;
    }
  );
}

addMethod(object, 'accuracyToHouseNumber', accuracyToHouseNumber);

function phone() {
  return this.test(
    'phone',
    'Неправильный номер',
    (value) => {
      if (!value) {
        return false;
      }
      const phone = value.replace(/[^\d]/g, '');
      return phone.length === 11;
    },
  );
}

addMethod(string, 'phone', phone);

function date() {
  return this.test(
    'date',
    'Неправильная дата',
    (value) => {
      if (value === undefined) {
        return true;
      }

      if (!value || value.length !== 10) {
        return false;
      }

      const date = stringToDate(value);
      return isValid(date);
    }
  )
}

addMethod(string, 'date', date);

function notFutureDate() {
  return this.test(
    'notFutureDate',
    'Дата не может быть в будущем',
    (value) => {
      const date = stringToDate(value);

      if (!isValid(date)) {
        return true;
      }

      return date < new Date();
    }
  )
}

addMethod(string, 'notFutureDate', notFutureDate);

function adult() {
  return this.test(
    'adult',
    'Вам должно быть больше 18 лет',
    (value) => {
      const birthDate = stringToDate(value);
      return addYears(birthDate, 18) <= new Date();
    }
  );
}

addMethod(string, 'adult', adult);


function inn() {
  return this.test(
    'inn',
    'Неправильный ИНН',
    (value) => {
      // TODO: Check INN by check sum
      return /^\d{10}|\d{12}$/.test(value);
    }
  );
}

addMethod(string, 'inn', inn);

function postIndex() {
  return this.test(
    'postIndex',
    'Неправильный индекс',
    (value) => /^\d{6}$/.test(value),
  );
}

addMethod(string, 'postIndex', postIndex);

function thereAreLetters() {
  return this.test(
    'thereAreLetters',
    'Невалидное значение',
    (value) => /[А-яA-z]/g.test(value),
  );
}

addMethod(string, 'thereAreLetters', thereAreLetters);

function thereAreRussianLetters() {
  return this.test(
    'thereAreRussianLetters',
    'Невалидное значение',
    (value) => /[А-я]/g.test(value),
  );
}

addMethod(string, 'thereAreRussianLetters', thereAreRussianLetters);

function thereAreRussianLettersOrNumbers() {
  return this.test(
    'thereAreRussianLettersOrNumbers',
    'Невалидное значение',
    (value) => !value || /[А-я0-9]/g.test(value),
  );
}

addMethod(string, 'thereAreRussianLettersOrNumbers', thereAreRussianLettersOrNumbers);

function passportSeries() {
  return this.test(
    'passportSeries',
    'Невалидное значение',
    (value) => /^\d{4}$/.test(value) && Number(value),
  );
}

addMethod(string, 'passportSeries', passportSeries);

function passportNumber() {
  return this.test(
    'passportNumber',
    'Невалидное значение',
    (value) => /^\d{6}$/.test(value) && Number(value),
  );
}

addMethod(string, 'passportNumber', passportNumber);

function passportIssuerCode() {
  return this.test(
    'passportIssuerCode',
    'Невалидное значение',
    (value) => /^\d{3}-\d{3}$/.test(value),
  );
}

addMethod(string, 'passportIssuerCode', passportIssuerCode);


function passport() {
  return this.test(
    'passport',
    'Невалидное значение',
    (value) => /^\d{4}\d{6}$/.test(value) && Number(value),
  );
}

addMethod(string, 'passport', passport);

function minDateDependsOnOtherDate(dependsDateFiled, years = 0, message: 'Дата выдачи меньше минимально допустимой') {
  return this.when(dependsDateFiled, function (dependsDate) {
    return this.test({
      test: value => {
        const date = stringToDate(value);

        if (!isValid(date)) {
          return true;
        }

        const minDate = addYears(stringToDate(dependsDate), years);
        return date >= minDate;
      },
      message: message || 'Дата меньше минимально допустимой',
    })
  })
}

addMethod(string, 'minDateDependsOnOtherDate', minDateDependsOnOtherDate);

function onlyNumbers() {
  return this.test(
    'onlyNumbers',
    'Ожидается числовое значение',
    (value) => {
      if (value === undefined) {
        return true;
      }
      return /^\d*$/.test(value);
    },
  );
}

addMethod(string, 'onlyNumbers', onlyNumbers);
