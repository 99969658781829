import { getState } from "helpers";
import { selectDrivers } from "../../modules/form";
import { toBackendDate } from "../dateModels";
import { DriverType } from "../../pages/Main/components/AppForm/drivers/constants";
import { driverLicenseToKasagoModel } from "../driverLicenseToBackendModel";
import { getFamilyState } from "./getFamilyState";
import { getChildrenCount } from "./getChildrenCount";
import { getApplicationFormValues } from "./getApplicationFormValues";
import { getPassport } from "./getPassport";

const mockPassport = {
  series: "1001",
  number: "100100",
  issueDate: "2010-01-01",
  issuer: "УФМС"
};

export const getDrivers = () => {
  const drivers = [];
  const driversFromForm = selectDrivers(getState());
  const formValues = getApplicationFormValues();

  driversFromForm.forEach((item) => {

    const passport = getPassport();

    const driver = {
      birthDate: toBackendDate(item.birth_date),
      firstName: item.name && item.name.value || '',
      middleName: item.middle_name && item.middle_name.value || '',
      lastName: item.surname && item.surname.value || '',
      drivingDateBegin: toBackendDate(item.experience_begin),
      passport: item.type === DriverType.MAIN ? passport : mockPassport,
      license: {
        ...driverLicenseToKasagoModel(item.driverLicense),
        issueDate: toBackendDate(item.issue_date_2nd),
      },

      //Для Каско
      familyState: getFamilyState(),
      childrenCount: getChildrenCount(formValues)
    };

    drivers.push(driver);
  });
  return drivers;
};
