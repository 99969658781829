import React from 'react'
import cx from 'classnames'
import { Transition } from 'react-transition-group'

import './styles.scss'

const animate = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:  { opacity: 0 },
}

export const Backdrop = ({ className, active, ...props }) =>
  <Transition in={active} timeout={100} mountOnEnter unmountOnExit>
    {
      state =>
        <div
          className={cx('backdrop', className )}
          style={animate[state]}
          {...props} />
    }
  </Transition>
