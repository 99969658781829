import React, {useState, useCallback, useEffect, useMemo} from 'react';
import reducers from 'redux/reducers';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import classnames from 'classnames';
import {Field, reduxForm, getFormSyncErrors} from 'redux-form';

import {InputModeType, Typography, TypographyType, InputType, Modal} from 'components/UI'
import {Checkbox, Input, RadioGroup, Dadata, FormError} from 'components/reduxForm'
import {gender} from 'helpers/lists'
import {FORM_NAME, selectFullName} from 'modules/form';
import {initialFormWithClientFields} from 'modules/app/actions';

import {
  selectSmsVerifyRequired,
  selectBirthDate,
  selectPhoneConfirmed,
  selectPredefinedCustomerData,
  setFormInitializedState,
  selectFormInitialized,
  selectDealerBlock
} from 'modules/app';
import {GenderType, SwitchType} from 'helpers/constants';
import {normalizePersonName, formatMobilePhone, normalizeMobilePhone} from '../utils';
import {InputMaskType} from '../constants';

import {createValidator, scrollToFirstError} from 'validation';
import {validationSchema} from './validationSchema';
import {Agreement} from '../../agreement/Agreement';
import {AgreementType} from '../../agreement/constants';
import {Grid} from 'components/UI/grid/Grid';
import {GridCell} from 'components/UI/grid/components/GridCell';
import {FormSection} from 'components/form/FormSection';
import {Form} from 'components/form/Form';
import {FormFooter} from 'components/form/FormFooter';

import {onlyEng} from 'redux/form';

import './info.scss';
import {AppOnline} from 'models';

import {SmsConfirmation} from "pages/Main/components/AppForm/info/components/sms-confirmation/SmsConfirmation";
import {scrollToElement} from "helpers";
import {sendNotify} from "../../../../../modules/notify/notify";
import {selectCar} from "../../../../../modules/car";
import {getState} from "helpers";
import {selectOffers} from "../../../../../modules/car";
import { PersonalDataProcessingPolicy } from '../../agreement/components'

const InfoForm = ({
                    handleSubmit,
                    submitFailed,
                    change,
                    error,
                    initialize,
                  }) => {
  const birthDate = useSelector(selectBirthDate);
  const {mobile_phone: mobilePhoneError} = useSelector(getFormSyncErrors(FORM_NAME));
  const {agreement} = useSelector(getFormSyncErrors(FORM_NAME));
  const formErrors = useSelector(getFormSyncErrors(FORM_NAME));

  const hasErrors = formErrors && Object.keys(formErrors).length;

  const predefinedCustomerData = useSelector(selectPredefinedCustomerData);
  const formInitialized = useSelector(selectFormInitialized);
  const phoneConfirmed = useSelector(selectPhoneConfirmed);
  const car = useSelector((state) => state.car);
  const app = useSelector((state) => state.app);
  const {city, dealer} = useSelector((state) => state.car);
  const dealerBlock = useSelector(selectDealerBlock);


  const [agreementType, setAgreementType] = useState(null);
  const [genderHidden, setGenderHidden] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (!formInitialized && predefinedCustomerData) {
      const formData = AppOnline.toFrontendModel(predefinedCustomerData);
      initialize(formData);
      setFormInitializedState(true);

      const {gender, name} = formData;
      if (!gender && name && name.value) {
        setGenderHidden(false);
      }
    }

    initialFormWithClientFields();

    // if (!formInitialized){
    //   const formData = AppOnline.customClientFieldsToFrontendModel(defaultParams);
    //   initialize(formData);
    //   setFormInitializedState(true);
    // }

  }, [initialize, predefinedCustomerData, setGenderHidden]);

  const closeAgreement = useCallback(() => setAgreementType(null), [setAgreementType]);

  const openPersonalDataAgreement = useCallback(() => {
    setAgreementType(AgreementType.PERSONAL_DATA);
  }, [setAgreementType]);

  const openPersonalDataProcessingPolicy = useCallback(() => {
    setAgreementType(AgreementType.PERSONAL_DATA_PROCESSING_POLICY);
  }, [setAgreementType]);


  const openCreditBureauAgreement = useCallback(() => {
    setAgreementType(AgreementType.CREDIT_BUREAU);
  }, [setAgreementType]);

  const openClientAgreement = useCallback(() => {
    setAgreementType(AgreementType.CLIENT_AGREEMENT);
  }, [setAgreementType]);

  const openElectronicDocumentManagementAgreement = useCallback(() => {
    setAgreementType(AgreementType.ELECTRONIC_DOCUMENT_MANAGEMENT);
  }, [setAgreementType]);

  const checkGender = useCallback(({selectedSuggestion = {data: {}}}) => {
    const selectedGender = GenderType[selectedSuggestion.data.gender];
    change('gender', selectedGender || null);
    if (!selectedGender) {
      setGenderHidden(false);
    }
  }, [setGenderHidden]);

  const handleFormSubmit = ({confirmed}) => {
    console.log('city', city);
    console.log('dealer', dealer);

    if (dealerBlock?.show === SwitchType.ON && (!city || !dealer)){
      scrollToElement('dealersBlock');
      reducers.app.update({dealerNotChosenWarning: true})
      sendNotify('Фронт. Попытка пройте первый шаг без выбранного дилера')
      return ;
    }

    if (!car.chosen && app.appConfig.blocks.calculator.show === 1) {
      scrollToElement('offersBlock');

      const offers = selectOffers(getState());
      const keys = Object.keys(offers)
      const issetOffers = offers[keys[0]] && offers[keys[0]].id;

      const car = selectCar(getState());
      if (car.price && !issetOffers){
        sendNotify('Фронт. Нельзя пройти первый шаг. Нет рассчетов, но автомобиль выбран')
      }

      reducers.app.update({offerNotChosenWarning: true})
      return;
    }

    if (hasErrors){
      if (formErrors.gender){
        scrollToElement('app-form')
        reducers.app.update({genderNotSelectedWarning: true});
        return ;
      }
    }

    if (!phoneConfirmed && selectSmsVerifyRequired && !confirmed) {
      return setShowConfirmation(true);
    }

    handleSubmit();
  }

  const handleCloseConfirmationModal = () => {
    setShowConfirmation(false);
  }

  // const isPrimaryButtonDisabled = useMemo(() => {
  //   const dealerCondition = dealerBlock?.show === SwitchType.ON && (!city || !dealer);
  //   return dealerCondition || hasErrors;
  // }, [hasErrors, city, dealer]);

  const confirmationModalText = `${useSelector(selectFullName)}, введите код из смс для продолжения оформления кредита`;
  const confirmationModalAgreementText = 'Отправляя настоящую заявку, я подтверждаю, что \n' +
    'действую от своего имени и с целью получения решения \n' +
    'Банка по вопросу предоставления мне кредита на \n' +
    'приобретение автомобиля.';

  return (
    <Form>
      <FormSection header="Личная информация">
        <Grid>
          <Field
            component={Dadata.Redux}
            type="fio"
            name="surname"
            label="Фамилия"
            width="4"
            mask={InputMaskType.PERSON_NAME}
            autoReplace={normalizePersonName}
            parts="SURNAME"
          />
          <Field
            component={Dadata.Redux}
            type="fio"
            name="name"
            label="Имя"
            width="4"
            onBlur={checkGender}
            mask={InputMaskType.PERSON_NAME}
            autoReplace={normalizePersonName}
            parts="NAME"
          />
          <Field
            component={Dadata.Redux}
            type="fio"
            name="middle_name"
            label="Отчество"
            width="4"
            mask={InputMaskType.MIDDLE_NAME}
            autoReplace={normalizePersonName}
            parts="PATRONYMIC"
          />
          <GridCell width="12">
            <Field
              component={RadioGroup.Redux}
              name="gender"
              options={gender}
            />
          </GridCell>
          <Field
            component={Input.Redux}
            label="Дата рождения"
            name="birth_date"
            date={birthDate}
            width="4"
            mask={InputMaskType.DATE}
            placeholder="дд.мм.гггг"
            inputMode={InputModeType.NUMERIC}
          />
        </Grid>
      </FormSection>
      <FormSection header="Контакты">
        <Grid>
          <Field
            type={InputType.EMAIL}
            component={Input.Redux}
            name="email"
            label="Адрес электронной почты"
            placeholder="your@mail.com"
            normalize={onlyEng}
            width="12"
          />
          <Field
            component={Input.Redux}
            name="mobile_phone"
            label="Мобильный телефон"
            prefix="+7"
            placeholder="(___) ___ __ __"
            mask={InputMaskType.MOBILE_PHONE_WITHOUT_COUNTRY_CODE}
            width="4"
            //disabled={phoneConfirmed}
            className={classnames('info__mobile-phone', {'info__mobile-phone_disabled': phoneConfirmed})}
            inputMode={InputModeType.NUMERIC}
            format={formatMobilePhone}
            normalize={normalizeMobilePhone}
            disabled={phoneConfirmed}
          />
        </Grid>
        <Field
          className="info__agreement"
          component={Checkbox.Redux}
          name="agreement"
          label={(
            <label>
              Я соглашаюсь с условиями <span className="info__agreement-item" onClick={openClientAgreement}>соглашения</span>, <span className="info__agreement-item" onClick={openPersonalDataProcessingPolicy}>политикой обработки персональных данных</span> и даю свое согласие на обработку и <span
              className="info__agreement-item" onClick={openPersonalDataAgreement}>использование моих персональных данных</span>
              , и разрешаю сделать <span className="info__agreement-item" onClick={openCreditBureauAgreement}>запрос в бюро кредитных историй</span>
              с помощью <span className="info__agreement-item" onClick={openElectronicDocumentManagementAgreement}>простой электронной подписи</span>
            </label>
          )}
        />
        <Typography type={TypographyType.CAPTION} className="info__agreement_caption">
          Отправляя настоящую заявку, я подтверждаю, что действую от своего имени и с целью получения решения Банка по
          вопросу предоставления мне кредита на приобретение автомобиля.
        </Typography>
        {phoneConfirmed && (
          <Typography type={TypographyType.PARAGRAPH} text="Спасибо! Телефон подтверждён" className="info__phone-confirmed-text" />
        )}
        {selectSmsVerifyRequired && showConfirmation &&
        <Modal className="sms-confirmation-modal" onClose={handleCloseConfirmationModal}>
          <SmsConfirmation text={confirmationModalText} agreementText={confirmationModalAgreementText}
                           onConfirmed={handleFormSubmit} mobilePhoneError={mobilePhoneError}/>
        </Modal>}
      </FormSection>
      <FormError submitFailed={submitFailed} error={error}/>
      <FormFooter onSubmit={handleFormSubmit} help={null} />
      <Agreement
        type={agreementType}
        onClose={closeAgreement}
      />
    </Form>
  )
};

InfoForm.propTypes = {
  error: PropTypes.string,
  syncErrors: PropTypes.shape({
    mobile_phone: PropTypes.string,
  }),
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  initialize: PropTypes.func.isRequired,
};

export const Info = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  keepDirtyOnReinitialize: true,
  validate: createValidator(validationSchema),
  onSubmitFail: scrollToFirstError,
})(InfoForm);
