import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CarConditionType } from '../../../helpers/constants';

import { Modal } from '../Modal';
import { Select } from 'components/reduxForm'

export const CarConditionPopup = ( { isOpen, onClose } ) => {

  const [carCondition, setCarCondition] = useState(CarConditionType.NEW);

  if (!isOpen) {
    return null;
  }

  const newItem = {
    id: CarConditionType.NEW,
    value: CarConditionType.NEW,
    name: "Новый"
  };

  const usedItem = {
    id: CarConditionType.USED,
    value: CarConditionType.USED,
    name: "С пробегом"
  };

  const options = [newItem, usedItem];

  const CarConditionSelected = carCondition === CarConditionType.USED ? usedItem : newItem;

  const changeCarCondition = (selected) => {
    setCarCondition(selected.id);
  }

  const handleClose = () => {
    onClose(carCondition);
  };

  return (
    <Modal className="info-popup" onClose={handleClose}>
      <p>Автомобиль новый или с пробегом?</p>
      <Select.Flat
        wBorder
        name="car_condition"
        options={options}
        selected={CarConditionSelected}
        onChange={changeCarCondition}
      />
    </Modal>
  );
}

CarConditionPopup.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
