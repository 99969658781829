import { string, object, boolean, number } from 'yup';

export const validationSchema = object().shape({
  'surname': object()
    .dadataRequired(),
  'name': object()
    .dadataRequired(),
  'middle_name': object()
    .dadataRequired(),
  'gender': number()
    .typeError("Необходимо указать пол")
    .required(),
  'birth_date': string()
    .required()
    .date()
    .adult(),
  'email': string() // Alternative: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(mail)
    .email()
    .required(),
  'mobile_phone': string()
    .required()
    .phone(),
  'agreement': boolean()
    .selected(),
});
