import React from 'react'
import { useSelector } from 'react-redux';

import { Typography } from 'components/UI';

import { selectBirthDate, selectFullName } from 'modules/form';

import { EDocBanks } from '../e-doc-banks/EDocBanks';

export const ElectronicDocumentManagementAgreement = () => {
  const birthDate = useSelector(selectBirthDate) || '_______________';
  const fullName = useSelector(selectFullName).trim() || '_______________';

  return (
    <div>
      <Typography text="СОГЛАШЕНИЕ ОБ ИСПОЛЬЗОВАНИИ ПРОСТОЙ ЭЛЕКТРОННОЙ ПОДПИСИ" />
      <p>
        Я, {fullName}, дата рождения {birthDate}, (далее – <b>«Клиент»</b>) с одной стороны,
        Общество с ограниченной ответственностью <b>«Кредитит»</b>, в лице Генерального директора Ларина Кирилла Олеговича, действующего на основании Устава, ИНН 9710013385, ОГРН 1167746577019 (далее – «Кредитит») с другой стороны, и
        Следующие юридические лица, совместно именуемые <b>«Поставщики услуг»</b>:
      </p>

      <table>
        <tbody>
          <tr>
            <td><b>№</b></td>
            <td><b>Наименование</b></td>
            <td><b>ИНН</b></td>
            <td><b>ОГРН</b></td>
          </tr>
          <EDocBanks />
        </tbody>
      </table>

      <p>далее совместно именуемые <b>«Стороны»</b>, а по отдельности – <b>«Сторона»</b> заключили настоящее Соглашение (далее – <b>«Соглашение»</b>) о нижеследующем:</p>

      <p>
        1. Клиент уведомлен, что Кредитит по поручению Клиента направляет его анкету Поставщикам услуг и иным лицам с использованием программного обеспечения «Платформа «е-Кредит», зарегистрированного в Федеральной службе по интеллектуальной собственности РФ, номер регистрации 2017613391, дата регистрации: 17.03.2017 (далее – <b>«Платформа «е-Кредит»</b>);
      </p>
      <p>
        2. Стороны согласились, что Клиент вправе подписывать любые документы по форме Кредитит, в том числе, но не ограничиваясь Согласие на обработку персональных данных, Согласие на запрос в Бюро кредитных историй, Анкету и иные документы (далее – <b>«Заявочная документация»</b>), в электронном виде с использованием простой электронной подписи.
      </p>
      <p>
        3. Простая электронная подпись (далее – <b>«ПЭП»</b>) представляет собой код из каких-либо символов (цифр и/или букв, и/или иных символов), направляемый Клиенту СМС-сообщением на номер мобильного телефона, представленный Клиентом при заполнении Заявочной документации.
      </p>
      <p>
        4. Клиент осознаёт и выражает согласие, что Кредитит направляет Заявочную документацию третьим лицам, которые непосредственно вступают в договорные отношения с Клиентом.
      </p>
      <p>
        5. Стороны согласились, что Клиент вправе подписывать любые документы по форме Поставщиков услуг (далее – <b>«Документы»</b>) в электронном виде с использованием ПЭП на условиях, установленных каждым из Поставщиков услуг.
      </p>
      <p>
        6. Клиент соглашается, что Заявочная документация и Документы в электронном виде, подписанные ПЭП Клиента, признаются равносильным исполнением на бумажном носителе, подписанным собственноручной подписью Клиента в отношениях с Кредитит, Поставщиками услуг и/или любыми третьими лицами.
      </p>
      <p>
        7. Одной ПЭП могут быть подписаны несколько связанных между собой бланков Заявочной документации и/или Документов.
      </p>
      <p>
        8. Датой подписания любого из бланков Заявочной документации и/или Документов с помощью ПЭП является дата проставления (ввода) Клиентом соответствующей ПЭП.
      </p>
      <p>
        9. Стороны согласились, что какая-либо Заявочная документация и/или Документы, подписанные Клиентом ПЭП, не могут быть признаны не имеющими юридической силы на основании, что такая ПЭП создана с использованием средств для автоматического создания и/или автоматической проверки ПЭП.
      </p>
      <p>
        10. Стороны обязаны соблюдать конфиденциальность ПЭП и несут ответственность за последствия необеспечения такой конфиденциальности.
      </p>
      <p>
        11. В целях сохранения сведений о юридически значимых действиях, совершенных между Клиентом и Кредитит, Кредитит осуществляет хранение Заявочной документации, которая была подписана ПЭП.
      </p>
      <p>
        12. В целях сохранения сведений о юридически значимых действиях, совершенных между Клиентом и Поставщиком услуг, каждый из Поставщиков услуг осуществляет хранение Документов, которые были подписаны ПЭП
      </p>
      <p>
        13. Автоматическая система журналирования (создания логов) активности Клиента, находящаяся у Кредитит, позволяет Сторонам достоверно определить, каким Клиентом, и в какое время был сформирован, подписан или отправлен тот или иной бланк Заявочной документации и/или Документ.
      </p>
      <p>
        14. Стороны соглашаются, что указанные способы определения Клиента, создавшего и подписавшего ПЭП Заявочную документацию и/или Документ, являются достаточными для цели достоверной идентификации Клиента и исполнения Соглашения.
      </p>
      <p>
        15. Акцепт настоящего Соглашения осуществляется путем начала использования Клиентом ПЭП на условиях настоящего Соглашения.
      </p>
      <p>
        16. Все вопросы, не затронутые настоящим Соглашением, регулируются в соответствии с действующим законодательством РФ.
      </p>
      <p>
        17. Все возникающие споры Стороны постараются урегулировать путём переговоров. В случае невозможности урегулирования путём переговоров, такие споры передаются на рассмотрение в суд по месту нахождения Кредитит.
      </p>
    </div>
  );
}
