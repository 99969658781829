import { selectApplicationInfo } from "./selectApplicationInfo";

export const selectSellDealerAddress = state => {
  const resultMock = {
    city: null,
    address: null
  };

  const applicationInfo = selectApplicationInfo(state);

  const result = isDealerAddressValid(applicationInfo);

  return result ? result : resultMock;
}

const isDealerAddressValid = applicationInfo => {
  const address = applicationInfo.fields && applicationInfo.fields.sell_dealer_address;

  return address && address.city && address.city.length && address.address && address.address.length
    ? address
    : null;
}
