import set from "lodash/set";
import has from "lodash/has";
import { setLocale } from "yup";
import { isProcessKasko } from "modules/app/utils/isProcessKasko";

import "./extraValidators";
import "./driverLicenseValidators";

setLocale({
  mixed: {
    required: "Обязательное поле",
  },
  string: {
    email: "Неправильный email",
    min: "Должно быть минимум 60 символов",
  },
});

export const createValidator = (validator) => {
  return (values) => {
    try {
      validator.validateSync(values, {
        abortEarly: false,
        context: { processKASKO: isProcessKasko() },
      });
    } catch (err) {
      return err.inner.reduce((errors, { path, message }) => {
        if (has(errors, path)) {
          // Show only first error if it will be needed show all error we must replace return to:
          //  set(lensProp(path), `${get([path], errors)} ${message}`, errors); get => path
          return errors;
        }

        return set(errors, path, message);
      }, {});
    }
  };
};
