import {toFrontendDate} from "./dateModels";

export const driversToFrontendModel = (drivers) => {
  return drivers.map(driver => ({
    driverLicense: driver.series_2nd + '' + driver.number_2nd,
    type: driver.type,
    issuer_name_2nd: driver.issuer_name_2nd,
    driving_experience: driver.driving_experience,
    mobile_phone: driver.mobile_phone,
    birth_date: toFrontendDate(driver['birth_date']),
    experience_begin: toFrontendDate(driver['experience_begin']),
    issue_date: toFrontendDate(driver['issue_date']),
    issue_date_2nd: toFrontendDate(driver['issue_date_2nd']),
    surname: { value: driver.surname },
    name: { value: driver.name },
    middle_name: { value: driver.middle_name },
  }))
};
