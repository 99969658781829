import { ResponseCodeType, ResponseErrorText } from 'helpers/constants';

export class AuthorizationCode {
  static toBackendModel(mobilePhone, code) {
    return {
      client_phone: mobilePhone,
      code,
    }
  }

  static toSubmissionError({error_code: errorCode, error_text: errorText}) {
    const errorMessage = ResponseErrorText[errorCode] || errorText;

    switch(errorCode) {
      case ResponseCodeType.INVALID_PHONE_NUMBER:
      case ResponseCodeType.PHONE_CHECK_SERVER_ERROR:
      case ResponseCodeType.PHONE_NOT_FOUND:
        return { mobile_phone: errorMessage };
      case ResponseCodeType.SMS_CODE_EXPIRED:
      case ResponseCodeType.SMS_CODE_INVALID:
        return { sms_code: errorMessage };
      case ResponseCodeType.SUCCESS:
        return;
      default:
        return {
          _error: errorMessage,
        };
    }

  }
}
