import * as commonReducers from 'helpers/commonReducers'


const initialState = {
  brand: null,
  model: null,
  config: null,

  price: 0,
  initial_fee: 30,
  initial_fee_money: null,

  offers: {},
  allOffers: {},

  gosProgramsAvailable: false
}

export default {
  initialState,
  ...commonReducers,
}
