import { selectToken } from "../selectors";
import { getState } from "../../../helpers";

const MMC_MOTORS_TOKEN = "mitsubishimotors";
const MMC_MOTORS_DOMAIN = "mitsubishi-online.e-credit.one";

export const isMmcMotors = (token) => {
  token = token ? token : selectToken(getState());
  const domain = window.location.host;

  return token === MMC_MOTORS_TOKEN || domain.includes(MMC_MOTORS_DOMAIN);
}

export const mmcMotorsFilterIntroBanks = (banks) => {

  if (!isMmcMotors()){
    return banks;
  }

  return banks.filter(item => item.bank_sys_name.toLowerCase().indexOf('ms-bank') > -1);
}
