import React, {useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Tooltip} from "components/UI";
import {ReactComponent as InfoSvg} from "assets/info.svg";
import {ReactComponent as CloseSvg} from "assets/close-light.svg";
import "./equipment-item.scss";

export const EquipmentItem = ({
  name = '',
  price = 0,
  description,
  active = false,
  onClick = () => {}
}) => {
  const [showTip, setShowTip] = useState(false);
  const handleClick = () => {
    onClick();
  }
  const toggleTip = (e) => {
    e.stopPropagation();
    setShowTip(!showTip);
  }
  return (
    <div className={classNames(['equipment-item', {active}])} onClick={handleClick}>
      <div className="equipment-item__name">{name}</div>
      {price && <div className="equipment-item__price">{price}</div>}
      {description && (
        <>
          <Tooltip content={description} placement='right' offset={[0, 25]} className="equipment-item__tip">
            <InfoSvg className="equipment-item__tip-toggle" />
          </Tooltip>
          <InfoSvg
            className="equipment-item__tip-toggle equipment-item__tip-toggle--mobile"
            onClick={toggleTip} />
          {showTip && (
            <div className="equipment-item__tip equipment-item__tip--mobile">
              <div>
                <CloseSvg onClick={toggleTip} />
                <div>{description}</div>
              </div>
            </div>
          )}
          {showTip && <div className="equipment-item__tip-overlay" onClick={toggleTip} />}
        </>
      )}
    </div>
  )
}

EquipmentItem.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string,
  description: PropTypes.func,
  active: PropTypes.bool
}
