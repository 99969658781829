
const newAppFields = {
  'client_email': 'email',
  'gender': 'gender',
  'client_surname': 'surname',
  'client_name': 'name',
  'client_phone': 'mobile_phone',
  'client_middle_name': 'middle_name',
  'birth_date': 'birth_date',
};


export class NewApp
{
  static toSubmissionError(response) {

    const responseFields = response && response.fields ? response.fields : {};
    const errors = Object.entries(responseFields || {});
    const fields = newAppFields;

    return errors.reduce((submissionErrors, [errorKey, errorValue]) => {
      const error = errorValue[0];
      if (!fields[errorKey]) {
        const formError = submissionErrors._error ?  `${submissionErrors._error}, ` : '';
        return {...submissionErrors, _error: `${formError}${error}`}
      }

      return {
        ...submissionErrors,
        [fields[errorKey]]: error,
      };
    }, {});
  }
}
