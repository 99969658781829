import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IMaskInput } from 'react-imask';

import { AlternativeField } from 'components/UI/alternative-field/AlternativeField';
import { InputType } from '../input-field/constants';
import { setInputMode } from '../input-field/setInputMode';

import { createMetricName } from "../../field-metric-name";

import './alternative-input-field.scss';

export const AlternativeInputField = ({
  label,
  error,
  help,
  type = InputType.TEXT,
  placeholder = 'Введите',
  component: Input = IMaskInput,
  className,
  inputClassName,
  required,
  onChange,
  onFocus = () => {},
  onBlur = () => {},
  mask,
  prefix,
  postfix,
  name,
  ...restProps
}) => {
  const [active, setActive] = React.useState(false);

  const inputProps = React.useMemo(
    () => mask
      ? { onAccept: onChange, mask }
      : { onChange },
    [mask, onChange],
  );

  const handleFocus = React.useCallback((event) => {
    setActive(true);
    onFocus(event);
  });

  const handleBlur = React.useCallback((event) => {
    setActive(false);
    onBlur(event);
  });

  const dataId = createMetricName(name);
  const inputId = `alternativeInput-${name}`;

  return (
    <AlternativeField
      label={label}
      error={error}
      className={classnames('alternative-input-field', className)}
      help={help}
      required={required}
    >
      <span className={classnames('alternative-input-field__input-wrapper', inputClassName, {
        'alternative-input-field__input-wrapper_error': error,
        'alternative-input-field__input-wrapper_focus': active,
      })}>
        {prefix && <span className="alternative-input-field__prefix">{prefix}</span>}
        <Input
          id={inputId}
          type={type}
          data-id={dataId}
          className="alternative-input-field__input"
          placeholder={placeholder}
          inputMode={setInputMode(type)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...restProps}
          {...inputProps}
        />
        {postfix && <span className="alternative-input-field__postfix">{postfix}</span>}
      </span>
    </AlternativeField>
  )
}

AlternativeInputField.propTypes = {
  type: PropTypes.oneOf(Object.values(InputType)),
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  mask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
  ]),
  component: PropTypes.node,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  help: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  prefix: PropTypes.node,
  postfix: PropTypes.node,
};
