import { isProcessKasko } from "../../../../../../modules/app/utils/isProcessKasko";
// import { isHyundaiDosProject } from "../../../../../../modules/app/utils/isHyundaiDosProject";

const nextButtonNames = {
  withKasko: "Продолжить",
  withoutKasko: "Отправить заявку в банк"
};

export const getNextButtonName = () => {
  // return isHyundaiDosProject() ? nextButtonNames.withKasko : nextButtonNames.withoutKasko;
  return isProcessKasko() ? nextButtonNames.withKasko : nextButtonNames.withoutKasko;
};
