import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { TypographyType } from './constants';

import './typography.scss';

export const Typography = ({ text, type, className, id, children }) => {
  const classNames = classnames('typography', { [`typography__${type}`]: type }, className);
  const content = text || children;

  switch (type) {
    case TypographyType.MAIN_HEADER:
    case Typography.RESULT_HEADER:
      return <h2 id={id} className={classNames}>{content}</h2>;
    case TypographyType.BLOCK_HEADER:
      return <h2 id={id} className={classNames}>{content}</h2>;
    case TypographyType.H5:
      return <h5 className={classNames}>{content}</h5>;
    case TypographyType.SECTION_HEADER:
      return <h3 className={classNames}>{content}</h3>;
    case TypographyType.SUB_HEADER:
      return <span className={classNames}>{content}</span>;
    case TypographyType.PARAGRAPH:
      return <p className={classNames}>{content}</p>;
    case TypographyType.CAPTION:
      return <small className={classNames}>{content}</small>;
    default:
      return <span className={classNames}>{text}</span>;
  }
};

Typography.propTypes = {
  id: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.oneOf(Object.values(TypographyType)),
  className: PropTypes.string,
  children: PropTypes.node,
};
