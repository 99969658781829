import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './styles.scss';

export const Card = ({className = '', title = '', onSelect = () => {}, selected = false, children}) => {
  return (
    <div className={cn(["card", className, {selected: selected}])}>
      <div className="card__title" onClick={onSelect}>{title}</div>
      <div className="card__content">{children}</div>
    </div>
  )
}

Card.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  onSelect: PropTypes.func,
  children: PropTypes.any,
  selected: PropTypes.bool
}

