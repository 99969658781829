import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './grid.scss';

export function Grid({ className, children }) {
  return (
    <div className={classnames('grid', className)}>
      {children}
    </div>
  )
}

Grid.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
