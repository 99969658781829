import * as commonReducers from 'helpers/commonReducers'
import { QuestionaryStepType } from "pages/Main/components/AppForm/constants";

function $_GET(key, def) {

  if (def===undefined)
    def=false;

  let p = window.location.href;
  p = p.replace(/#\w+$/, '');
  p = p.match(new RegExp(key + '=([^&=]+)'));
  return p ? p[1] : def;
}


const initialState = {
  needManualCarCondition: null,
  last_success_carfin_url: null,
  step: QuestionaryStepType.INFO,
  application_id: null,
  kaskoApplicationId: null,
  result: null,
  defaultParams: {

  },
  untouchedInitialParams: {

  },
  phoneConfirmed: false,
  isMobile: true,
  showFeedbackForm: false,
  showIndustryBranchFields: false,
  showEmployerAddressFields: false,
  initialized: false,
  formInitialized: false,
  appConfig: {
    "partner": "4",
    "stock":{
      "used": {
        "cars": []
      }
    },
    "application":
    {
      "can_short": "1",
      "can_full": "1"
    },
    "blocks": {
      "calculator": {
        "show": 1
      },
      "anketa_short": {
        "show": 1
      },
      "anketa_full": {
        "show": 0
      },
      "header": {
        "show": 0,
        "items": {
          "eosago": { "show": 1 }, "creditonline": ["show"]
        }
      },
      footer: {
        "show": 1,
      },
      intro_header: 1
    },
    "theme": "default",
    "user_ip": "",
    offerNotChosenWarning: false
  }
}

export const setDefaultParams = (state, payload) => ({
  ...state,
  defaultParams: { ...payload },
});

export const setUntouchedInitialParams = (state, payload) => ({
  ...state,
  untouchedInitialParams: { ...payload },
});

export const setAppConfig = (state, payload) => ({
  ...state,
  appConfig: { ...payload },
});

export const setAppInitialized = (state) => ({
  ...state,
  initialized: true,
});


export default {
  initialState,
  ...commonReducers,
  setDefaultParams,
  setAppConfig,
  setAppInitialized,
  setUntouchedInitialParams
};
