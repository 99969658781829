import React from "react";
import { useSelector } from "react-redux";
import { selectSuccessReturnUrl } from "modules/app/selectors";
import { Typography } from "components/UI/typography/Typography";
import {Button, TypographyType} from "components/UI";
import { SwitchType } from "helpers/constants";
import { isProcessKasko } from "modules/app/utils/isProcessKasko";

import "./thank-you-short.scss";
import {isKia} from "../../../../modules/app/utils/isKia";

export const ThankYouShort = () => {
  const successReturnUrl = useSelector(selectSuccessReturnUrl);
  const processKasko = isProcessKasko();
  return (
    <div className="thank-you">
      <Typography type={TypographyType.RESULT_HEADER} text="Спасибо!" />
      <Typography
        type={TypographyType.SUB_HEADER}
        text="Мы получили вашу заявку на кредит."
      />
      <Typography
        type={TypographyType.SUB_HEADER}
        text="Скоро с вами свяжется сотрудник дилерского центра."
      />
      {
        (processKasko || isKia()) &&
        <a href={successReturnUrl} className='thank-you__closebutton'>
          <Button>{processKasko ? "Продолжить" : "Вернуться назад"}</Button>
        </a>
      }
    </div>
  );
}
