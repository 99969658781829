import React from 'react';
import PropTypes from 'prop-types';

import {ReactComponent as ArrowIcon} from "assets/arrow-new.svg"

import './carousel-button.scss';

export const CarouselButton = ({style, onClick, direction = 'next'}) => {
  return (
    <ArrowIcon
      {...style}
      onClick={onClick}
      className={`carousel-button carousel-button__${direction}`}
    />
  );
};

CarouselButton.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func,
};
