import {selectToken} from "../selectors"
import {getState} from "../../../helpers"

export const KIA_MOTORS_TOKEN = 'kiamotors'
export const KIA_INSURANCE_TOKEN = 'kiainsurance'
export const CHERRY_NEW_TOKEN = 'cherynew'
export const OMODA_TOKEN = 'omoda'
export const KIA_DOMAIN = 'approval-online.e-credit.one'
export const KIA_SUCCESS_RETURN_URL = "https://www.kia.ru";

const NEED_ECREDIT_BANK_IDS = [291, 837];
const CHERRY_NEW_BANK_IDS = [291];
const OMODA_BANK_IDS = [291];

export const isKia = (token) => {
  token = token ? token : selectToken(getState())
  const domain = window.location.host

  return token === KIA_MOTORS_TOKEN || token === KIA_INSURANCE_TOKEN || domain.includes(KIA_DOMAIN)
}

export const isCheryNew = (token) => {
  token = token ? token : selectToken(getState())
  const domain = window.location.host

  return token === CHERRY_NEW_TOKEN
}

export const isOmoda = (token) => {
  token = token ? token : selectToken(getState())
  const domain = window.location.host

  return token === OMODA_TOKEN
}

export const kiaMotorsFilterIntroBank = banks => !isKia() || !banks || !banks.length || !NEED_ECREDIT_BANK_IDS.length
  ? banks
  : banks.filter(item => NEED_ECREDIT_BANK_IDS.indexOf(+item.ecredit_bank_id) !== -1)

export const cherryNewFilterIntroBank = banks => !isCheryNew() || !banks || !banks.length || !CHERRY_NEW_BANK_IDS.length
  ? banks
  : banks.filter(item => CHERRY_NEW_BANK_IDS.indexOf(+item.ecredit_bank_id) !== -1)

export const omodaFilterIntroBank = banks => !isOmoda() || !banks || !banks.length || !OMODA_BANK_IDS.length
  ? banks
  : banks.filter(item => OMODA_BANK_IDS.indexOf(+item.ecredit_bank_id) !== -1)
