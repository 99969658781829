import { getState } from "helpers";
import { selectToken } from "../selectors";

export const HYUNDAI_RU_TOKEN = 'hyundai';
export const HYUNDAI_RU_DOMAIN = 'credit-approval.ecredit.one';

export const isHyundaiRu = token => {
  token = token ? token : selectToken(getState());
  const domain = window.location.host;

  return token === HYUNDAI_RU_TOKEN || domain.includes(HYUNDAI_RU_DOMAIN);
}
