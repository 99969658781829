import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { selectCar } from "modules/car";
import { Checkbox } from "components/reduxForm";
import { GosProgramLabels, GosProgramHelpText } from "../constants";

export const GosProgramCheckbox = ({ programsEnabled, handleProgram, nameCheckbox }) => {
  const label = GosProgramLabels[nameCheckbox];
  const car = useSelector(selectCar);
  const valueCheckbox = car[nameCheckbox];
  const helpText = GosProgramHelpText[nameCheckbox];

  return (
    <Checkbox.Flat
      label={label}
      value={valueCheckbox}
      onChange={handleProgram}
      name={nameCheckbox}
      help={helpText}
      disabled={!programsEnabled}
    />
  );
};

GosProgramCheckbox.propTypes = {
  nameCheckbox: PropTypes.string,
  programsEnabled: PropTypes.bool,
  handleProgram: PropTypes.func
};
