import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IMaskInput } from 'react-imask';

import { Field } from 'components/UI/field/Field';
import { InputType } from './constants';
import { setInputMode } from './setInputMode';

import './input-field.scss';

export const InputField = ({
  label,
  error,
  help,
  type = InputType.TEXT,
  component: Input = IMaskInput,
  className,
  inputClassName,
  required,
  onChange,
  onFocus = () => {},
  onBlur = () => {},
  mask,
  prefix,
  postfix,
  value,
  name,
  placeholder,
  disabled,
  ...restProps
}) => {
  const [active, setActive] = React.useState(false);

  const inputProps = React.useMemo(
    () => mask
      ? { onAccept: onChange, mask }
      : { onChange },
    [mask, onChange],
  );

  const handleFocus = React.useCallback((event) => {
    setActive(true);
    onFocus(event);
  });

  const handleBlur = React.useCallback((event) => {
    setActive(false);
    onBlur(event);
  });

  const inputId = `input-${name}`;
  const isActive = active || value;
  return (
    <Field
      label={label}
      error={error}
      className={classnames('input-field', className)}
      help={help}
      required={required}
      active={isActive}
      inputId={inputId}
    >
      <span className={classnames('input-field__input-wrapper', inputClassName, {
        'input-field__input-wrapper_error': error,
        'input-field__input-wrapper_focus': active,
        disabled: disabled
      })}>
        {prefix && (
          <span className={classnames('input-field__prefix', {
            'input-field__prefix_hidden': !isActive,
            'input-field__prefix_disabled': disabled,
          })}>
            {prefix}
          </span>
        )}
        <Input
          id={inputId}
          type={type}
          className="input-field__input"
          inputMode={setInputMode(type)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          name={name}
          disabled={disabled}
          {...restProps}
          {...inputProps}
          placeholder={isActive ? placeholder : ''}
        />
        {postfix && <span className="input-field__postfix">{postfix}</span>}
      </span>
    </Field>
  )
}

InputField.propTypes = {
  type: PropTypes.oneOf(Object.values(InputType)),
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  mask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
  ]),
  component: PropTypes.node,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  help: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  prefix: PropTypes.node,
  postfix: PropTypes.node,
  value: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};
