import {fetchWrapper, MethodType} from "../../api/fetch";
import {ApiMethods, createApiMethodUrl} from "../../api/createApiMethodUrl";

export const sendNotify = async (message, client_application_id, send_to_telegram = 1) => {
  message += "\nurl: " + window.location.href + "\n";
  await fetchWrapper(
    createApiMethodUrl(
      ApiMethods.sendTelegramNotify, {message, client_application_id, send_to_telegram}
    ), {method: MethodType.GET});
}

export const graylogNotify = async (message, method_name, step) => {
  await fetchWrapper(
    createApiMethodUrl(
      ApiMethods.graylogNotify, {message, method_name, step}
    ), {method: MethodType.GET});
}
