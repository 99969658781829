import IMask from 'imask';
import { format, parse, getYear } from 'date-fns';

export const InputMaskType = {
  BRANCH_CODE: '000-000',
  PERSON_NAME: /^[A-zА-Яа-яЁё,.<>:"';](?:[A-zА-Яа-яЁё,.<>:"';-]+)*$/,
  MIDDLE_NAME: /^[A-zА-Яа-яЁё,.<>:"';-]+$/,
  PHONE: '+{7} (000) 000 0000',
  MOBILE_PHONE: [{
    mask: 'COUNTRY (XXX) 000 0000',
    blocks: {
      COUNTRY: { mask: '+{7}' },
      XXX: { mask: [{ mask: '\\8\\1\\2' }, { mask: '\\900' }] },
    },
  }],
  MOBILE_PHONE_WITHOUT_COUNTRY_CODE: [{
    mask: '(XXX) 000 0000',
    blocks: {
      XXX: { mask: [{ mask: '\\8\\1\\2' }, { mask: '\\900' }] },
    },
  }],
  INN: '000000000[000]',
  OFFICE: /^[А-яЁёA-z0-9]+$/,
  DATE: [{
    mask: Date,
    pattern: 'DD-MM-YYYY',
    blocks: {
      YYYY: {
        mask: IMask.MaskedRange,
        from: 1950,
        to: getYear(new Date()),
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12
      },
      DD: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31
      }
    },
    format: (date) => format(date, 'dd-MM-yyyy'),
    parse: (value) => parse(value, 'dd-MM-yyyy', new Date()),
  }],
  DRIVE_LICENSE: [{
    mask: 'XXYY000000',
    blocks: {
      XX: { mask: '00' },
      YY: { mask: [{ mask: '00' }, { mask: 'aa'}]},
    },
  }],
  DRIVE_LICENSE_SERIES: [{
    mask: 'XXYY',
    blocks: {
      XX: { mask: '00' },
      YY: { mask: [{ mask: '00' }, { mask: 'aa'}]},
    },
  }],
  DRIVE_LICENSE_NUMBER: '000000',
  POST_INDEX: '000000',
  PASSPORT_SERIES: '0000',
  PASSPORT_NUMBER: '000000',
  PASSPORT: '0000000000',
}

export const QuestionaryStepType = {
  INFO: 'INFO',
  PROF: 'PROF',
  DOCS: 'DOCS',
  DRIVERS: 'DRIVERS',
  KASKO: 'KASKO',
  FINAL_CONDITIONS: 'FINAL_CONDITIONS',
  BANK_DECISIONS: 'BANK_DECISIONS',
  DOCUMENTS: 'DOCUMENTS',
  DOCUMENTS_SUCCESS_PAGE: 'DOCUMENTS_SUCCESS_PAGE',
  THANK_YOU_PAGE: 'THANK_YOU'
};

export const QUESTIONARY_TABS = [
  { tab: QuestionaryStepType.INFO, name: 'Контактные данные', nameMobile: 'Контакты' },
  { tab: QuestionaryStepType.PROF, name: 'Профессиональная деятельность', nameMobile: 'Работа' },
  { tab: QuestionaryStepType.DOCS, name: 'Персональные данные', nameMobile: 'Документы' },
  { tab: QuestionaryStepType.DRIVERS, name: 'Водители', nameMobile: 'Водители' },
  { tab: QuestionaryStepType.DOCUMENTS, name: 'Загрузить документы', nameMobile: 'Загрузить' },
  { tab: QuestionaryStepType.KASKO, name: 'Страхование', nameMobile: 'Страхование' },
  { tab: QuestionaryStepType.FINAL_CONDITIONS, name: 'Отправить в банк', nameMobile: 'Отправка' }
]
