import { selectCar } from './selectCar';
import { selectAppConfig } from "../../app/selectors";

export const selectDealer = state => {
  const dealerInfo = selectCar(state).dealer || selectAppConfig(state).dealer_info;

  let fullName = (dealerInfo.legal_type ? dealerInfo.legal_type + ' ' : '') + dealerInfo.legal_name;

  return { ...dealerInfo, fullName }
};
