import { createApplicationFormSelector } from './createApplicationFormSelector';
import { selectMaritalStatus } from './selectMaritalStatus';
import { MaritalStatus } from 'helpers/constants';

export const selectContactPerson = (state) => {
    const maritalStatus = selectMaritalStatus(state);

    if (maritalStatus && maritalStatus.id === MaritalStatus.MARRIED) {
        return {
            surname: createApplicationFormSelector(state, 'spouse_surname'),
            name: createApplicationFormSelector(state, 'spouse_name'),
            middle_name: createApplicationFormSelector(state, 'spouse_middle_name'),
            mobile_phone: createApplicationFormSelector(state, 'spouse_mobile'),
            birth_date: createApplicationFormSelector(state, 'spouse_birth_date'),
        };
    }

    return {
        surname: createApplicationFormSelector(state, 'proxy_first_name'),
        name: createApplicationFormSelector(state, 'proxy_second_name'),
        middle_name: createApplicationFormSelector(state, 'proxy_third_name'),
        mobile_phone: createApplicationFormSelector(state, 'proxy_phone'),
        birth_date: createApplicationFormSelector(state, 'proxy_birth_date'),
    };
};
