import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Tooltip } from 'components/UI';
import { ReactComponent as HelpIcon } from './help.svg';

import './field.scss';

export const Field = ({ children, label, error, className, help, active, inputId, required = true }) => {

  return (
    <div className={classnames('field', className)}>
      <label htmlFor={inputId} className={classnames('field__label', { 'field__label_active': active })}>
        {label}
        {required && <span className="field__required-star">*</span>}
      </label>
      <span className="field__content">
        {children}
        {help && <Tooltip content={help}><HelpIcon /></Tooltip>}
      </span>
      {error && (
        <div className="field__error">
          {error}
        </div>
      )}
    </div>
  );
}

Field.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  help: PropTypes.string,
  active: PropTypes.bool,
  inputId: PropTypes.string,
}
