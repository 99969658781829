import { GosPrograms } from "../../../pages/Main/components/gos-program/constants";
import { isHyundaiDosProject } from "./isHyundaiDosProject";

export const getVisibleGosProgramsList = () => {

  // Силой данной мне с ваше отключаю все гос программы. Ибо нефиг!
  return [];

  let programs = [
    GosPrograms.FIRST_AUTO, GosPrograms.FAMILY_AUTO, GosPrograms.MEDICAL_WORKER
  ];

  if (!isHyundaiDosProject()){
    programs.push(GosPrograms.TRADE_ID);
  }

  if (isHyundaiDosProject()){
    programs = [];
  }

  return programs;
}
