import React from "react";
import { Field } from "redux-form";
import { useSelector } from "react-redux";

import { app } from "redux/actions";
import { Input, Dadata, Checkbox } from "components/reduxForm";
import { toCapitalize } from "helpers";
import { InputModeType } from "components/UI";
import { Grid } from "components/UI/grid/Grid";
import { FormSection } from "components/form/FormSection";
import { selectFioWasChanged } from "modules/form";

import { normalizePersonName } from "../../utils";
import { InputMaskType } from "../../constants";

export function Passport() {
  const fioWasChanged = useSelector(selectFioWasChanged);
  const normalizePassportIssuerCode = React.useCallback(
    ({ data, value }) => (data ? data.code : value),
    []
  );

  return (
    <FormSection header="Ваш паспорт">
      <Grid>
        <Field
          component={Input.Redux}
          name="passport"
          label="Серия, номер"
          placeholder="7788123456"
          width="4"
          inputMode={InputModeType.NUMERIC}
          help="Ваши персональные данные защищены и никуда не передаются"
          mask={InputMaskType.PASSPORT}
          unmask
        />
        <Field
          component={Input.Redux}
          name="passport_issue_date"
          label="Дата выдачи"
          mask={InputMaskType.DATE}
          width="4"
          placeholder="дд.мм.гггг"
          inputMode={InputModeType.NUMERIC}
        />
        <Field
          component={Dadata.Redux}
          name="passport_issuer_code"
          label="Код подразделения"
          type="fms_unit"
          onChange={app.issuer_code}
          paramToShow="code"
          placeholder="123-987"
          mask={InputMaskType.BRANCH_CODE}
          width="4"
          normalize={normalizePassportIssuerCode}
          inputMode={InputModeType.NUMERIC}
        />
        <Field
          component={Input.Redux}
          name="passport_issuer_name"
          label="Кем выдан"
          width="12"
          normalize={toCapitalize}
        />
        <Field
          component={Dadata.Redux}
          name="birth_place"
          label="Место Рождения"
          width="8"
        />
        <Field
          component={Checkbox.Redux}
          label="Фамилия менялась"
          name="fio_was_change"
          width="4"
          className="hidden"
        />
        {fioWasChanged && (
          <Field
            component={Input.Redux}
            name="previous_surname"
            label="Прежняя фамилия"
            mask={InputMaskType.PERSON_NAME}
            width="12"
            normalize={normalizePersonName}
          />
        ) || ''}
      </Grid>
    </FormSection>
  );
}
