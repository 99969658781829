import { getState } from "helpers";
import { selectCar } from "../../car";

export const getBuyBackAmount = () => {
  const { buyBackPayment, buyBackCheckbox } = selectCar(getState());

  if (buyBackCheckbox && buyBackPayment) {
    return buyBackPayment;
  }

  if (buyBackCheckbox){
    return 1;
  }

  return null;
};
