import React from "react";
import {Typography, TypographyType} from "../../../../components/UI";
import {isVolvoOnline} from "../../../../modules/app/utils/isVolvo";
import {selectAppConfig} from "../../../../modules/app";
import {getState} from "../../../../helpers";
import {SwitchType} from "../../../../helpers/constants";

export const AnketaHeader = () => {
  const appConfig = selectAppConfig(getState());

  return (
    <>
      {
        !isVolvoOnline() && appConfig.blocks.calculator.show == SwitchType.ON &&
        <>
          <Typography
            type={TypographyType.BLOCK_HEADER}
            text="3. Заполните анкету на автокредит"
          />
          <Typography
            type={TypographyType.MARGIN_PARAGRAPH}
            text="Заполнение анкеты займет у вас не больше 5 минут"
          />
        </>
      }
    </>
  )
}
