import React from "react";
import { Field } from "redux-form";
import { Input } from "components/reduxForm";
import { InputModeType } from "components/UI";
import { Grid } from "components/UI/grid/Grid";
import { FormSection } from "components/form/FormSection";
import { monthlyIncome } from "redux/form";

export function ObligatoryPayments() {
  return (
    <FormSection header="Платежи">
      <Grid>
        <Field
          component={Input.Redux}
          name="spendings_request"
          normalize={monthlyIncome}
          label="Обязательные платежи"
          width="4"
          help="ЖКХ, мобильная связь, арендные платежи, алименты, учеба и другие регулярные ежемесячные расходы."
          inputMode={InputModeType.NUMERIC}
        />
        <Field
          component={Input.Redux}
          name="spendings_anycredit"
          normalize={monthlyIncome}
          label="Погашение кредитов"
          width="4"
          help="Сумма ваших ежемесячных платежей по погашению кредитов в банках."
          inputMode={InputModeType.NUMERIC}
        />
      </Grid>
    </FormSection>
  );
}
