export const defaultHeaders = {
  'Content-Type': 'application/json',
};

export const fetchWrapper = async (URL, options) => {
  const response = await fetch(URL, {
    ...options,
    headers: {
      ...defaultHeaders,
      ...options.headers
    }
  });

  if (response.ok) {
    return response;
  }

  throw response;
};
