const splitDriverLicense = (driverLicense) => {
  return {
    series_2nd: driverLicense.slice(0, 4),
    number_2nd: driverLicense.slice(4),
  }
}

export function driverLicenseToBackendModel(driverLicense) {

  if (driverLicense && driverLicense.length === 10) {
    return splitDriverLicense(driverLicense);
  }

  return {
    series_2nd: driverLicense,
    number_2nd: "",
  };
}

export function driverLicenseToKasagoModel(driverLicense) {
  return {
    series: driverLicense.slice(0, 4),
    number: driverLicense.slice(4),
  }
}
