import React from 'react';
import { selectTheme } from 'modules/app';
import { SPECIFIC_DESIGN_BRANDS } from 'helpers/constants';
import { InputField } from 'components/UI';
import { AlternativeInputField } from 'components/UI/alternative-input-field/AlternativeInputField';
import { useSelector } from 'react-redux';

export const useAlternativeInput = () => {
  const theme = useSelector(selectTheme);
  const Input = React.useMemo(
    () => SPECIFIC_DESIGN_BRANDS.includes(theme) ? AlternativeInputField : InputField,
    [theme],
  );

  return Input;
};
