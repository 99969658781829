import { fetchWrapper, MethodType } from '../fetch';

const BASE_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/';
// TODO: It shouldn't be here. May be an environment variable
const TOKEN = 'f5d81d5eb31f628e81d688fed7beb9428984a41f';

const headers = {
  Authorization: `Token ${TOKEN}`,
  Accept: 'application/json',
};

export const DaDataAPI = {
  async getSuggestions(type, payload, signal) {
    try {
      const response = await fetchWrapper(`${BASE_URL}suggest/${type}`, {
        method: MethodType.POST,
        headers,
        signal,
        body: JSON.stringify(payload),
      });
      return response.json();
    } catch (error) {
      if (error.name !== 'AbortError') {
        throw error;
      }
    }
  },

  async getLocation() {
    const response = await fetchWrapper(`${BASE_URL}iplocate/address`, {
      method: MethodType.GET,
      headers,
    });

    return response.json();
  }
};
