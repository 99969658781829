import { selectDealers } from "./selectDealers";
import { selectCar} from "../../car/selectors";
import { selectCarCondition } from "../../app/selectors";
import { canDealerForCarCondition } from "../utility/canDealerForCarCondition";

export const selectDealersForCurrentBrand = (state) => {
  const car = selectCar(state);
  const dealers = selectDealers(state);
  const carCondition = selectCarCondition(state);

  return dealers ? dealers.filter(({ sell_only_used, brands }) => car.brand && brands
    ? canDealerForCarCondition(carCondition, sell_only_used) && brands.includes(car.brand.name.toLowerCase())
    : true) : [];
};
