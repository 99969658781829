import { API } from 'api';
import { request, api, getState } from 'helpers';
import reducers from 'redux/reducers';
import { user, car } from 'redux/actions';
import {CarConditionType, USED_CAR_CONFIGURATION_DEFAULT, CarSourceIdType, SwitchType} from "../../helpers/constants";
import { addParamsToUrl } from 'api';
import {selectCarCondition, selectToken} from "../app/selectors";
import {getDefaultCheryConfigurationForCurrentModel} from "../app/utils/isChery";
import { selectNormalizedModel } from "../app/selectors/selectNormalizedModel";

export const getBrands = async () => {
  user.setLoading();

  reducers.car.update({brand: null, model: null, config: null});

  const defaultParams = getState().app.defaultParams;
  const { price = 0, blockCarSeletsFromCard } = defaultParams;
  const carCondition = selectCarCondition(getState());
  let {brand = '', model = '', configuration = '', model_image = ''} = defaultParams;

  window.brands = window.models = window.configs = [];

  let models = [], brands = [], configs = [];

  if (brand && model && configuration && price && blockCarSeletsFromCard){
    brands = [
      {id: brand, name: brand}
    ];
  }else{
    if (selectToken(getState()) === 'cherynew') {
      models = [
        {
          id: "9943",
          brand_id: "7",
          name: "Tiggo 8 Pro e+",
          beecars_name: "Tiggo 8 Pro e+",
          picture: "/images/car_models_pics/8_screenshot1.png"
        }
      ];
    } else {
      models = await request.get(api.models + '?car_condition=' + carCondition, {noPreloader: true});
      models = models.items ? models.items : []
    }

    if (carCondition === CarConditionType.USED) {
      models = models.map((item)=>{
        return{...item, id: item.id ? item.id : item.name};
      });
    }

    configs = await request.get(api.configs + '?car_condition=' + carCondition, {noPreloader: true});
    configs = configs.items ? configs.items : []

    if (selectToken(getState()) === 'cherynew') {
      brands = [
        {
          id: "7",
          name: "Chery"
        }
      ];
    } else {
      brands = await request.get(api.brands + '?car_condition=' + carCondition, {noPreloader: true});
      brands = brands.items ? brands.items : []
    }
  }

  if (carCondition === CarConditionType.USED && configuration.length == 0){
    configuration = USED_CAR_CONFIGURATION_DEFAULT;
  }

  //If there is only one brand in selects that set it as default
  if ((!brand || brand.length === 0) && brands && brands.length === 1){
    brand = brands[0].name;
  }

  let normalizedBrand = brand.trim().toUpperCase();
  let selectedBrand = brand
    ? brands.find(it => it.name.trim().toUpperCase() === normalizedBrand)
    : null;

  const carRecognizeURL = addParamsToUrl(api.carRecognize, {
    'car_brand': brand,
    'car_model': model,
    'car_source_id': carCondition === CarConditionType.NEW ? CarSourceIdType.NEW : CarSourceIdType.USED,
  });

  window.brands = brands;
  window.models = models;

  //Стандартизация бренда/модели. Если передана кастомная модель, но при этом она успешно распозналась, то подмешиваем ее в набор
  if (selectedBrand && model){
    let modelId = model;
    const issetModel = models.find((item)=>(item.name.toUpperCase() === model.toUpperCase()));

    if (!issetModel){
      models.push({
        id: modelId,
        brand_id: selectedBrand.id,
        name: modelId,
        picture: model_image
      });
    }else{
      modelId = window.models.find(it => it.name.trim().toUpperCase() === model.trim().toUpperCase());
      modelId = modelId ? modelId.id : null;
    }

    if (configuration){
      const newConfig = {
        id: configuration,
        brand_id: selectedBrand.id,
        model_id: modelId,
        name: configuration,
        vin: null,
        price: price
      };
      configs.push(newConfig);
    }
  }
  window.configs = configs;

  reducers.dictionary.update({ brands });
  reducers.car.update({
    brand: selectedBrand,
    price,
  });

  if (selectedBrand){
    //TODO
    //Сначала ставится бренд, потом модель. Если иначе, то в предзаполненных параметрах автомобиля, на карфин автомотом не улетает запрос
    //Приходится менять параметры
    reducers.car.update({brand: selectedBrand});
    getModels(selectedBrand.id);
  }

  const config = selectedBrand ? getModels(selectedBrand.id) : null;

  let disableCarSelects = SwitchType.OFF;

  if (config && (price || config.price>0) && blockCarSeletsFromCard){
    disableCarSelects = SwitchType.ON;
  }

  reducers.car.update({ disableCarSelects });

  if (config) {
    await car.getOffers();
  }

  user.clearLoading();
};

const findModel = (model, models) => {
  let findModel = null;

  if (!!model && models.length > 0) {
    findModel = models.find(it => it.name.trim().toUpperCase() === model.trim().toUpperCase());
  }

  return findModel;
}

export const getModels = (brand_id) => {
  const $models = window.models.slice(0);

  let { model } = getState().app.defaultParams;
  const normalizedModel = selectNormalizedModel(getState());

  let $model = findModel(model, $models);

  if (!$model){
    if (normalizedModel && normalizedModel.length && normalizedModel.length > 1 &&
      model && model.length && model.length > 1 &&
      normalizedModel.length !== model.length){
      model = normalizedModel;
    }

    $model = findModel(model, models);
  }

  if (brand_id && !$model && !model && $models.length > 0){
      for (let i in $models){
        if ($models[i].brand_id == brand_id){
          $model = $models[i];
          break;
        }
      }
  }

  const models = $models.filter(it => `${it.brand_id}` === `${brand_id}`);

  reducers.dictionary.update({ models })
  reducers.car.update({ model: $model });

  if ($model) {
    return getConfigs($model);
  }

  return null;

};

export const getConfigs = ({id: model_id, brand_id}) => {
  const { configuration, vin = '' } = getState().app.defaultParams;
  const items = window.configs.slice(0);

  const configs = items
    .filter(it => it.model_id === model_id && it.brand_id === brand_id)
    .map((it, index) => ({...it, id: `${it.name}${index}`}));

  if (!configuration && (!vin || vin.length)) {
    reducers.dictionary.update({configs});
    return;
  }

  let config = false;

  config = getDefaultCheryConfigurationForCurrentModel(configuration, configs);

  if (!config && configuration){
    const normalizedConfiguration = configuration.trim().toUpperCase();
    config = items.find(it => (it.name.trim().toUpperCase() === normalizedConfiguration && it.model_id === model_id));
  }

  reducers.car.update({config});
  reducers.dictionary.update({configs});
  return config;
};

export const getDealers = async () => {
  //const { location } = await API.daData.getLocation();
  const { list = {} } = await request.get(api.citydealer, {noPreloader: true});
  const { cities = [], dealers = [] } = list;
  const { dealer_id: defaultDealerId } = getState().app.defaultParams;

  reducers.dictionary.update({ dealers: list.dealers, cities: list.cities });

  return ;

  // if (!location || defaultDealerId) {
  //   return;
  // }
  //
  // const city = cities.find(({ name }) => name.toUpperCase() === location.data.city.toUpperCase());
  // const dealer = city && dealers.find(({ city_id }) => city_id === city.id);
  //
  // if (city && dealer) {
  //   reducers.car.update({ city, dealer });
  // }
};
