import { EmploymentType, EmploymentSpouseType, WorkingLifeDateType, MaritalStatus } from "./constants"

export const gender = [
  {
    "id": 1,
    "name": "Мужской"
  },
  {
    "id": 2,
    "name": "Женский"
  }
]

export const marital_status = [
  {
    id: MaritalStatus.UNMARRIED,
    name: "холост/не замужем"
  },
  {
    id: MaritalStatus.MARRIED,
    name: "женат/замужем"
  },
  {
    id: MaritalStatus.DIVORCE,
    name: "разведен/разведена"
  },
  {
    id: MaritalStatus.WINDOWER,
    name: "вдовец/вдова"
  }
]

export const working_spouse_type = [
  {
    "id": EmploymentSpouseType.EMPLOYEE,
    "name": "Пенсионер"
  },
  {
    "id": EmploymentSpouseType.WORKING,
    "name": "Работает/Служит"
  },
  {
    "id": EmploymentSpouseType.WORKING_PENSIONER,
    "name": "Работающий пенсионер"
  },
  {
    "id": EmploymentSpouseType.NOT_WORKING,
    "name": "Не работает"
  },
]

export const employment_type = [
  {
    "id": EmploymentType.EMPLOYEE,
    "name": "Наемный работник"
  },
  {
    "id": EmploymentType.INDIVIDUAL_ENTERPRENEUER,
    "name": "Индивидуальный предприниматель"
  },
  {
    "id": EmploymentType.BUSINESS_OWNER,
    "name": "Собственник бизнеса"
  },
  {
    "id": EmploymentType.WORKING_PENSIONER,
    "name": "Работающий пенсионер"
  },
  {
    "id": EmploymentType.UNEMPLOYED_PENSIONER,
    "name": "Безработный пенсионер"
  },
  {
    "id": EmploymentType.MATERNITY_LEAVE,
    "name": "Декретный отпуск"
  }
]

export const education = [
  {
    "id": 1,
    "name": "Начальное или неполное среднее"
  },
  {
    "id": 2,
    "name": "Среднее"
  },
  {
    "id": 3,
    "name": "Среднеспециальное"
  },
  {
    "id": 4,
    "name": "Неполное высшее"
  },
  {
    "id": 5,
    "name": "Высшее"
  },
  {
    "id": 6,
    "name": "Два и более высших"
  },
  {
    "id": 7,
    "name": "Ученая степень"
  }
]

export const employer_type = [
  {
    "id": 1,
    "name": "Государственная"
  },
  {
    "id": 2,
    "name": "Коммерческая"
  },
  {
    "id": 3,
    "name": "Некоммерческая"
  }
]

export const employee_position_type = [
  {
    "id": 1,
    "name": "Неруководящий сотрудник"
  },
  {
    "id": 2,
    "name": "Руководитель подразделения"
  },
  {
    "id": 3,
    "name": "Руководитель организации"
  }
]

export const workingLifeDate = Object.values(WorkingLifeDateType).map((name, index) =>({
  id: index + 1,
  name,
}));

export const type_2nd_doc = [
  {
    "id": 1,
    "name": "Водительское удостоверение"
  },
  {
    "id": 2,
    "name": "Загранпаспорт"
  },
  {
    "id": 3,
    "name": "Свидетельство о постановке на учет в налоговом органе"
  },
  {
    "id": 4,
    "name": "Страховое свидетельство гос. пенсионного страхования"
  },
  {
    "id": 5,
    "name": "Военный билет"
  }
]

export const realty_state = [
  {
    "id": 1,
    "name": "Свое"
  },
  {
    "id": 2,
    "name": "У родственников"
  },
  {
    "id": 3,
    "name": "Съемное"
  },
  {
    "id": 4,
    "name": "Общежитие"
  }
]

export const employerBusinessType = [
  {"id":"1","name":"ООО"},
  {"id":"2","name":"ОАО"},
  {"id":"3","name":"ЗАО"},
  {"id":"4","name":"ИП"},
  {"id":"5","name":"МУП"},
  {"id":"6","name":"ЧОП"},
  {"id":"7","name":"ГБУ"},
  {"id":"8","name":"ГБОУ"},
  {"id":"9","name":"МБУ"},
  {"id":"10","name":"Автономная некоммерческая организация"},
  {"id":"11","name":"Адвокатская контора, юридическая консультация"},
  {"id":"12","name":"ЖСК, ТСЖ, Гаражный кооператив"},
  {"id":"13","name":"Религиозная организация"},
  {"id":"14","name":"Фонд, благотворительная организация"},
  {"id":"15","name":"ПАО"},
  {"id":"16","name":"МБОУ"},
  {"id":"17","name":"ГУП"},
  {"id":"18","name":"Общественное объединение, партия"},
  {"id":"19","name":"ФГУП"},
  {"id":"20","name":"Некоммерческое партнерство"},
  {"id":"21","name":"Другое"}
];

export const relation_to_person = [
  {
    "id": 1,
    "name": "Дедушка (бабушка)"
  },
  {
    "id": 2,
    "name": "Сын (дочь)"
  },
  {
    "id": 3,
    "name": "Отец (мать)"
  },
  {
    "id": 4,
    "name": "Брат/сестра, в т.ч. неполнородн."
  },
  {
    "id": 5,
    "name": "Супруг (супруга)"
  },
  {
    "id": 6,
    "name": "Внук (внучка)"
  },
  {
    "id": 7,
    "name": "Пасынок (падчерица)"
  },
  {
    "id": 8,
    "name": "Отчим (мачеха)"
  },
  {
    "id": 9,
    "name": "Усыновитель (усыновленный)"
  },
];

export const industryBranch = [
  {
    id: 1,
    name: 'Аварийные и спасательные службы',
    okved: [],
  },
  {
    id: 2,
    name: 'Авиа-, Авто-, Судо- и прочее машиностроение',
    okved: [28,29,30],
  },
  {
    id: 3,
    name: 'Автодилеры и автосервис',
    okved: [45],
  },
  {
    id: 4,
    name: 'Арендодатель / риэлторская деятельность',
    okved: [55,68],
  },
  {
    id: 5,
    name: 'Банки / финансы / лизинг / страхование',
    okved: [77],
  },
  {
    id: 6,
    name: 'Бытовые / коммунальные услуги / ремонтные работы',
    okved: [],
  },
  {
    id: 7,
    name: 'Добывающая промышленность (кроме ТЭК)',
    okved: [7,8,9],
  },
  {
    id: 9,
    name: 'Здравоохранение',
    okved: [86],
  },
  {
    id: 10,
    name: 'Игорный бизнес',
    okved: [92],
  },
  {
    id: 11,
    name: 'ИТ (информационные технологии)',
    okved: [62,63],
  },
  {
    id: 12,
    name: 'Кадры / HR',
    okved: [],
  },
  {
    id: 13,
    name: 'Консалтинг и аудит',
    okved: [],
  },
  {
    id: 14,
    name: 'Культура / искусство / шоу-бизнес',
    okved: [90,91],
  },
  {
    id: 15,
    name: 'Легкая и пищевая промышленность',
    okved: [10,11,12,13,14,15,16,17,31,32],
  },
  {
    id: 16,
    name: 'Лесное хозяйство',
    okved: [2],
  },
  {
    id: 17,
    name: 'Ломбард',
    okved: [64],
  },
  {
    id: 18,
    name: 'Металлургия',
    okved: [24,25,26],
  },
  {
    id: 19,
    name: 'Муниципальное и федеральное управление / Государственное управление',
    okved: [],
  },
  {
    id: 20,
    name: 'Наука и образование',
    okved: [85],
  },
  {
    id: 21,
    name: 'Общественное питание / Рестораны / Кейтеринг',
    okved: [],
  },
  {
    id: 22,
    name: 'Оптовая / розничная торговля',
    okved: [],
  },
  {
    id: 23,
    name: 'Охранное / детективное агентство',
    okved: [80],
  },
  {
    id: 24,
    name: 'Печать / издательское дело',
    okved: [18,58],
  },
  {
    id: 25,
    name: 'Политические и общественные организации',
    okved: [94],
  },
  {
    id: 26,
    name: 'Правоохранительная / судебная система',
    okved: [84],
  },
  {
    id: 27,
    name: 'Предприятия ТЭК',
    okved: [5,6,19],
  },
  {
    id: 28,
    name: 'Приборостроение / Радиоэлектроника',
    okved: [26,27],
  },
  {
    id: 29,
    name: 'Проектирование / Дизайн',
    okved: [],
  },
  {
    id: 30,
    name: 'Производство вооружения',
    okved: [],
  },
  {
    id: 31,
    name: 'Реклама, PR-агентства, Маркетинг',
    okved: [73],
  },
  {
    id: 32,
    name: 'Салон красоты / Фитнес / Спорт',
    okved: [93],
  },
  {
    id: 33,
    name: 'Связь / Телекоммуникации',
    okved: [61],
  },
  {
    id: 34,
    name: 'Сельское хозяйство',
    okved: [1,3],
  },
  {
    id: 35,
    name: 'Служба в вооруженных силах',
    okved: [],
  },
  {
    id: 36,
    name: 'СМИ',
    okved: [60],
  },
  {
    id: 37,
    name: 'Социальное обеспечение',
    okved: [84,87,88],
  },
  {
    id: 38,
    name: 'Строительство / Производство стройматериалов',
    okved: [41,42,43],
  },
  {
    id: 39,
    name: 'Таможня / Налоговая полиция',
    okved: [],
  },
  {
    id: 40,
    name: 'Торговля оптовая',
    okved: [46],
  },
  // {
  //   id: 41,
  //   name: 'Торговля розничная',
  //   okved: [47],
  // },
  // {
  //   id: 42,
  //   name: 'Транспорт / Логистика / Склады',
  //   okved: [52],
  // },
  // {
  //   id: 43,
  //   name: 'Туризм / Гостиничное дело / Развлечения',
  //   okved: [79],
  // },
  // {
  //   id: 44,
  //   name: 'Тяжелая / обрабатывающая промышленность',
  //   okved: [],
  // },
  // {
  //   id: 45,
  //   name: 'Услуги населению, ЖКХ',
  //   okved: [33,75,95,96],
  // },
  // {
  //   id: 46,
  //   name: 'Химическая и фармацевтическая промышленность',
  //   okved: [20,21],
  // },
  // {
  //   id: 47,
  //   name: 'Ювелирное дело / Предметы роскоши',
  //   okved: [],
  // },
  // {
  //   id: 48,
  //   name: 'Юридические услуги',
  //   okved: [],
  // },
  {
    id: 8,
    name: 'Другое',
    okved: [],
  },
];

export const findOkvedInIndustryBranch = okved => {
  if (!okved) {
    return null;
  }
  okved = +okved.split('.')[0];
  let branchInfo = industryBranch.find( (item) => item.okved.some( okvedElement => okvedElement === okved ) );

  if (!branchInfo){
    branchInfo = {
      id: 8,
      name: 'Другое',
      okved: [],
    };
  }

  return branchInfo;
};

export const employerCountEmployees = [
  {
    id: 1,
    name: 'меньше 10',
    min: 0,
    max: 10
  },
  {
    id: 2,
    name: '10-20',
    min: 10,
    max: 20
  },
  {
    id: 3,
    name: '20-50',
    min: 20,
    max: 50
  },
  {
    id: 4,
    name: '50-100',
    min: 50,
    max: 100
  },
  {
    id: 5,
    name: '100-200',
    min: 100,
    max: 200
  },
  {
    id: 6,
    name: '200-500',
    min: 200,
    max: 500
  },
  {
    id: 7,
    name: 'больше 500',
    min: 500,
    max: 100000000
  },
  {
    id: 8,
    name: 'Другое',
    min: 0,
    max: 0
  },
];

export const matchCountEmployees = (count) => {
  if (count === null){
    return {
      id: 8,
      name: 'Другое',
      min: 0,
      max: 0
    };
  }
  return employerCountEmployees.find( ({ min, max }) => {
    return count >= min && count < max;
  });
}

export default {
  gender,
  marital_status,
  employment_type,
  education,
  employer_type,
  employee_position_type,
  workingLifeDate,
  type_2nd_doc,
  realty_state,
  relation_to_person,
  working_spouse_type,
  industryBranch,
  employerCountEmployees,
  employerBusinessType,
  findOkvedInIndustryBranch,
  matchCountEmployees,
}
