import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'redaction';
import { app } from 'redux/actions';
import { monthlyIncome, postalCode } from 'redux/form';

import { InputModeType } from 'components/UI';
import { Select, Input, Dadata, Checkbox, FormError } from 'components/reduxForm';
import { FormSection } from 'components/form/FormSection';
import { Form } from 'components/form/Form';
import { FormFooter } from 'components/form/FormFooter';

import {
  employment_type,
  education,
  employer_type,
  employee_position_type,
  workingLifeDate,
  industryBranch,
  employerCountEmployees,
  employerBusinessType,
} from 'helpers/lists';


import { selectEmployerIndustryBranch, selectEmployerNumberOfEmployees, selectEmployerBusinessType, selectEmploymentType, selectEducation, selectEmployerType, selectEmployerName, selectEmployeePositionType, selectWorkingLifeDate } from 'modules/form';
import { createValidator, scrollToFirstError } from 'validation';
import { validationSchema } from './validationSchema';

import { InputMaskType } from '../constants';
import { Grid } from 'components/UI/grid/Grid';

import { selectShowIndustryBranchFields, selectShowEmployerAddressFields } from "modules/app";

export const Prof = connect({
  employment_type_selected: selectEmploymentType,
  education_selected: selectEducation,
  employer_type_selected: selectEmployerType,
  employerName: selectEmployerName,
  employee_position_type_selected: selectEmployeePositionType,
  working_life_date_selected: selectWorkingLifeDate,
  employerIndustryBranch: selectEmployerIndustryBranch,
  showIndustryBranchFields: selectShowIndustryBranchFields,
  showEmployerAddressFields: selectShowEmployerAddressFields,
  employerNumberOfEmployees: selectEmployerNumberOfEmployees,
  employerBusinessTypeValue: selectEmployerBusinessType,
})(reduxForm({
  form: 'Application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: createValidator(validationSchema),
  onSubmitFail: scrollToFirstError,
})(
  ({
    goBack,
    handleSubmit,
    employment_type_selected,
    education_selected,
    employer_type_selected,
    employerName,
    employee_position_type_selected,
    working_life_date_selected,
    submitFailed,
    employerIndustryBranch,
    employerNumberOfEmployees,
    employerBusinessTypeValue,
    showIndustryBranchFields,
    showEmployerAddressFields,
    error,
  }) =>
    <Form className="metric_form_prof">
      <FormSection header="Профессиональная деятельность">
        <Grid>
          <Field
            component={Select.Redux}
            label="Образование"
            selected={education_selected}
            options={education}
            module="app"
            field="education"
            name="education"
            width="4"
          />
          <Field
            component={Select.Redux}
            label="Социальный статус"
            selected={employment_type_selected}
            options={employment_type}
            module="app"
            name="employment_type"
            field="employment_type"
            width="4" />
          {
            employment_type_selected && [5,6].includes(employment_type_selected.id) &&
              <Field
                component={Input.Redux}
                name="primary_income_sum"
                normalize={monthlyIncome}
                label="Ежемесячный доход"
                width="4"
                inputMode={InputModeType.NUMERIC}
                help="Обращаем внимание, что платеж по кредиту не должен превышать третью часть вашего дохода."
              />
          }
          {(!employment_type_selected || ![5,6].includes(employment_type_selected.id)) &&
            <>
              <Field
                component={Dadata.Redux}
                name="employer_name"
                value={employerName}
                label="Юридическое название места работы"
                type="party"
                onBlur={app.employerNameBlur}
                onChange={app.employerName}
                width="12"
              />
              {showIndustryBranchFields && (
                <>
                  <Field
                    component={Input.Redux}
                    name="employer_inn"
                    label="ИНН организации"
                    mask={InputMaskType.INN}
                    width="4"
                    className="metric_employer_inn"
                    inputMode={InputModeType.NUMERIC}
                  />
                  <Field
                    component={Select.Redux}
                    label="Тип организации"
                    selected={employer_type_selected}
                    options={employer_type}
                    module="app"
                    className="metric_employer_type"
                    field="employer_type"
                    name="employer_type"
                    width="4"
                  />
                  <Field
                    component={Select.Redux}
                    name="employer_business_type"
                    className="metric_employer_number_of_employees"
                    label="Форма организации"
                    options={employerBusinessType}
                    selected={employerBusinessTypeValue}
                    notRequired
                    width="4"
                  />
                </>
              )}
              {showIndustryBranchFields && (
                <>
                  <Field
                    component={Select.Redux}
                    label="Отраслевая принадлежность"
                    selected={employerIndustryBranch}
                    className="metric_employer_industry_branch"
                    options={industryBranch}
                    name="employer_industry_branch"
                    notRequired
                    width="4"
                  />
                  <Field
                    component={Select.Redux}
                    name="employer_number_of_employees"
                    className="metric_employer_number_of_employees"
                    label="Количество сотрудников"
                    options={employerCountEmployees}
                    selected={employerNumberOfEmployees}
                    notRequired
                    width="4"
                  />
                </>
              )}
              {showEmployerAddressFields && (
                <>
                  <Field
                    component={Dadata.Redux}
                    onChange={app.profAdress}
                    name="employer_legal_address"
                    className="metric_employer_legal_address"
                    label="Юридический адрес работодателя"
                    width="6"
                  />
                  <Field
                    component={Input.Redux}
                    name="employer_apartment_number"
                    className="metric_employer_apartment_number"
                    notRequired
                    label="Офис"
                    width="3"
                    mask={InputMaskType.OFFICE}
                  />
                  {<Field
                    component={Input.Redux}
                    name="employer_index"
                    className="metric_employer_index"
                    label="Индекс"
                    normalize={postalCode}
                    width="3"
                    inputMode={InputModeType.NUMERIC}
                    mask={InputMaskType.POST_INDEX}
                  />}
                </>
              )}
            </>
          }
          {(!employment_type_selected || ![5,6].includes(employment_type_selected.id)) && (
            <>
              <Field
                component={Input.Redux}
                name="employee_position"
                label="Название должности"
                width="8"
              />
              <Field
                component={Select.Redux}
                label="Тип должности"
                selected={employee_position_type_selected}
                options={employee_position_type}
                module="app"
                field="employee_position_type"
                name="employee_position_type"
                width="8" />
              <Field
                component={Select.Redux}
                label="Длительность работы"
                selected={working_life_date_selected}
                options={workingLifeDate}
                module="app"
                field="working_life_date"
                name="working_life_date"
                width="4" />
              <Field
                component={Input.Redux}
                name="employer_phone"
                label="Телефон офиса"
                placeholder="+7 (___) ___ _____"
                mask={InputMaskType.PHONE}
                width="4"
                inputMode={InputModeType.NUMERIC}
              />
              <Field
                component={Input.Redux}
                name="primary_income_sum"
                normalize={monthlyIncome}
                label="Ежемесячный доход"
                width="4"
                inputMode={InputModeType.NUMERIC}
                help="Обращаем внимание, что платеж по кредиту не должен превышать третью часть вашего дохода."
              />
            </>
          )}
        </Grid>
      </FormSection>
      <FormError submitFailed={submitFailed} error={error} />
      <FormFooter
        goBack={goBack}
        primaryButtonDisabled={false}
        onSubmit={handleSubmit}
        help={null}
      />
    </Form>
))
