import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'components/UI';

import './introduction-step.scss';

export const IntroductionStep = ({ step, text }) => {
  return (
    <div className="introduction-step">
      <p className="introduction-step__number">{step}</p>
      <p className="introduction-step__text">{text}</p>
    </div>
  )
}

IntroductionStep.propTypes = {
  step: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};
