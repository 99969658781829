export const randomMinMax =
  (min, max) => Math.ceil( Math.random() * (max - min) + min )

export const splitPhone = value => {

  if (!value){
    return null;
  }

  const array = value.replace(/[+()]/g, '').split(' ')
  const country_code = array[0]
  const area_code = array[1]
  const phone_number = array[2] + (array[3] ? array[3] : '')
  return { country_code, area_code, phone_number }
}

export const getPhoneAsNumber = (phone) => {
  return Object.values(splitPhone(phone)).join('');
}

export const formatCurrency = input => {

  input = parseFloat(input);

  if (isNaN(input)){
    return '';
  }

  let result = ''

  const intlParse = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' });

  if (!intlParse.formatToParts){
    return '';
  }

  const array = intlParse.formatToParts(input)

  if (!array || !array.length){
    return '';
  }

  array.forEach( ({ type, value }) => { if (['integer', 'group'].includes(type)) result += value } )
  return result
}

export const range = months => {
  if (+months === 12) {
    return "1 год"
  } else if (months > 12 && months < 60) {
    return `${months / 12} года`
  } else {
    return `${months / 12} лет`
  }
}

export const getParameterByName = function (name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
