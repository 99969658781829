import { fetchWrapper, MethodType } from '../fetch';
import { createApiMethodUrl, ApiMethods } from "../createApiMethodUrl";

export const onlineBanks = {

  cache: {},

  setToCahce (key, value) {
    this.cache[key] = value;
  },

  getFromCahce (key) {
    return this.cache[key];
  },

  async loadBanks (carCondition, brandName) {
    const key = carCondition + '_' + brandName;
    const cachedValue = this.getFromCahce(key);

    if (cachedValue){
      return cachedValue;
    }

    const userFields = {
      car_condition: carCondition,
      car_brand: brandName
    };
    const response = await fetchWrapper(createApiMethodUrl(ApiMethods.getOnlineBanks, userFields), {
      method: MethodType.GET,
      headers: {},
    });

    const result = response.json();

    this.setToCahce(key, result);

    return result;
  }
};
