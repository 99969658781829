import { getState } from 'helpers';
import { selectToken } from "../selectors";

export const getApiCredentials = () => {
  const { app } = getState();
  const { defaultParams } = app;

  const token = defaultParams.token && defaultParams.token.length > 2
    ? defaultParams.token
    : selectToken(getState());

  return {
    token: token,
    apiUrl: defaultParams.api_url
  };
};

