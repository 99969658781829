import React, {useEffect} from "react";
import PropTypes from "prop-types";

import {useIFrameCommunication, MessageType} from "./useIFrameCommunication";
import {useSelector} from "react-redux";
import {initialize} from "redux-form";
import {dispatch, getParameterByName} from "helpers";
import {FORM_NAME} from "modules/form";
import {selectShowFormImmediate} from "modules/app";

import {
  selectKaskoApplicationId,
  selectApplicationId,
  selectClientApplicationId,
  selectInitialStep,
  setFormInitializedState, selectFormInitialized, selectPredefinedCustomerData,
  selectDefaultParams
} from "modules/app";
import {selectKasagoParams} from "modules/app";
import {selectToken} from "modules/app";
import {QuestionaryStepType} from "../constants";

import "./kasko.scss";
import {KaskoFooter} from "./components/footer/KaskoFooter";
import {Kasago} from "models/kasago";
import classnames from "classnames";
import {Button} from "../../../../../components/UI/Button";
import {GO_BACK_BUTTON} from "../../../../../components/form/goToNextFieldByEnter";
import {Flex} from "../../../../../components/layouts/Flex";
import {AppOnline} from "../../../../../models";
import {sendAlertIfKaskoPriceEmpty} from "./utils/KasagoResponseExpose";

const TARGET_ORIGIN = getParameterByName("iframe-url") || ((window.location.hostname.includes('hyundai')) || (window.location.hostname.includes('credit-online.e-credit.one'))
  ? "https://ekasago.e-credit.one"
  : "https://ekasago-stage.e-credit.one");


export const Kasko = ({ goBack, recalculate, onSubmit, onPaymentSuccess, updateKaskoConditions, saveFrameCommunication, telegramNotify }) => {
  const [completed, setCompleted] = React.useState(false);
  const iframeRef = React.useRef(null);
  const token = useSelector(selectToken);
  const showFormImmediate = useSelector(selectShowFormImmediate);
  const formInitialized = useSelector(selectFormInitialized);
  const predefinedCustomerData = useSelector(selectPredefinedCustomerData);
  const kaskoApplicationId = useSelector(selectKaskoApplicationId);
  const applicationId = useSelector(selectApplicationId);
  const clientApplicationId = useSelector(selectClientApplicationId);
  const originalKaskoParams = useSelector(selectKasagoParams);

  /**
   * Сохраненные параметры выбранных офферов во фрейме нужны только на момент оплаты полисов
   */
  const kasagoParams = showFormImmediate ? originalKaskoParams : {};

  //Установка значений формы, чтобы выбрать данные в модели kasago.js
  useEffect(() => {
    if (!formInitialized && predefinedCustomerData) {
      const formData = AppOnline.toFrontendModel(predefinedCustomerData);
      dispatch(initialize(FORM_NAME, formData));
      setFormInitializedState(true);
    }
  }, [initialize, predefinedCustomerData]);

  const resizeIframe = (height) => {
    console.log(iframeRef.target);
    iframeRef.current.style.height = height + 'px';
  }

  const onReceiveMessage = React.useCallback(
    async ( data, postMessage ) => {
      switch (data.type){
        case MessageType.BUTTONS_DISABLED:
          saveFrameCommunication({type:MessageType.BUTTONS_DISABLED, data: data.payload});
          telegramNotify('Заблокированы кнопки скипнуть осаго');
          break;
        case MessageType.GET_CONDITIONS:
          saveFrameCommunication({type:MessageType.GET_CONDITIONS, data: data.payload});
          postMessage(await Kasago.getKasagoSavedParams(clientApplicationId), MessageType.GET_CONDITIONS);
          break;
        case MessageType.SAVE_CONDITIONS:
          saveFrameCommunication({type:MessageType.SAVE_CONDITIONS, data: data.payload});
          await updateKaskoConditions(data.payload);
          break;
        case MessageType.COMPLETED:
          saveFrameCommunication({type: MessageType.COMPLETED, data: data.payload});

          sendAlertIfKaskoPriceEmpty(data.payload);

          if (showFormImmediate) {
            await updateKaskoConditions(data.payload);
          } else {
            await recalculate(data.payload);
            setCompleted(true);
            onSubmit();
          }
          break;
        case MessageType.KASAGO_LOADED:
          let info = Kasago.selectDriversKasago();
          if (showFormImmediate) {
            info = {...info, ...kasagoParams}
          }
          saveFrameCommunication({type: MessageType.KASAGO_LOADED, data: info});
          const conditions = await Kasago.getKasagoSavedParams(clientApplicationId);
          postMessage({info: {...info, ...conditions}});
          break;
        case MessageType.PAYMENT_COMPLETED:
          saveFrameCommunication({type:MessageType.PAYMENT_COMPLETED, data: data.payload});
          onPaymentSuccess();
          break;
        case MessageType.FRAME_HEIGHT_CHANGED:
          const {height} = data.payload;
          console.log("Set new frame height", height);
          if (height) resizeIframe(height);
          break;
        default:
          break;
      }
    },
    [setCompleted]
  );

  // const onSubmitWithRecalculate = React.useCallback(() => {
  //   onSubmit();
  // }, [recalculate, onSubmit]);

  const postMessage = useIFrameCommunication(
    iframeRef,
    TARGET_ORIGIN,
    onReceiveMessage
  );

  //const onLoad = React.useCallback(() => postMessage({ info: Kasago.selectDataForKasago() }), [drivers]);

  const getKasagoParams = () => {

    if (!showFormImmediate) {
      return '&new_offers=true';
    }

    const needForUrlFields = ['kasko_offer_id', 'kasko_calculation_id', 'osago_offer_id', 'osago_calculation_id'];

    let info = '';

    for (let i in kasagoParams) {
      if (needForUrlFields.includes(i)) {
        info += '&' + i + '=' + kasagoParams[i];
      }
    }

    info += '&order=true&new_offers=true';

    return info;
  }

  const {mock} = useSelector(selectDefaultParams);

  const mockParam = mock ? '&mock=1' : '';

  const postMessageUrl = '&post_message_url=' + (
    window.location.hostname.includes('localhost')
      ? 'http://' + window.location.hostname + ':3000'
      : 'https://' + window.location.hostname
  );


  return (
    <div className="kasko">
      <iframe
        ref={iframeRef}
        className="kasko__iframe"
        src={`${TARGET_ORIGIN}?cron=1&insuranceApplicationId=${kaskoApplicationId}&ecreditApplicationId=1248&clientApplicationId=${clientApplicationId}&token=${token}${getKasagoParams()}${mockParam}${postMessageUrl}`}
        frameBorder="0"
        scrolling="no"
      />
      {!showFormImmediate && <Flex className={classnames("form-footer", "kasko__back")}>
        <Button onClick={goBack} flat data-role={GO_BACK_BUTTON}>
          Назад
        </Button>
      </Flex>}

      {/*<KaskoFooter*/}
      {/*  goBack={goBack}*/}
      {/*  onSubmit={onSubmitWithRecalculate}*/}
      {/*  completed={completed}*/}
      {/*/>*/}
    </div>
  );
};

Kasko.propTypes = {
  goBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  recalculate: PropTypes.func.isRequired,
};
