import { selectDefaultParams } from "./selectDefaultParams";
import { selectAppConfig } from "./selectAppConfig";

export const selectToken = (state) => {

  //let token = selectDefaultParams(state).token;

  //if (!token || token.length < 2){
    const appConfig = selectAppConfig(state);
    const token = appConfig ? appConfig.token : token;
  //}

  return token;

}
