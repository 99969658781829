import React from "react";
import { Field } from "redux-form";
import { useSelector } from "react-redux";

import { Input, Dadata, Select } from "components/reduxForm";

import { InputModeType } from "components/UI";
import { Grid } from "components/UI/grid/Grid";
import { FormSection } from "components/form/FormSection";
import { selectSpouseEmploymentType } from "modules/form";
import { working_spouse_type as workingType } from "helpers/lists";
import { monthlyIncome } from 'redux/form';

import {
  normalizePersonName,
  formatMobilePhone,
  normalizeMobilePhone,
} from "../../utils";
import { InputMaskType } from "../../constants";

export function Spouse() {
  const employmentType = useSelector(selectSpouseEmploymentType);

  return (
    <FormSection header="Данные о супруге">
      <Grid>
        <Field
          component={Dadata.Redux}
          type="fio"
          name="spouse_surname"
          label="Фамилия"
          width="4"
          mask={InputMaskType.PERSON_NAME}
          autoReplace={normalizePersonName}
          parts="SURNAME"
        />
        <Field
          component={Dadata.Redux}
          type="fio"
          name="spouse_name"
          label="Имя"
          width="4"
          mask={InputMaskType.PERSON_NAME}
          autoReplace={normalizePersonName}
          parts="NAME"
        />
        <Field
          component={Dadata.Redux}
          type="fio"
          name="spouse_middle_name"
          label="Отчество"
          width="4"
          mask={InputMaskType.MIDDLE_NAME}
          autoReplace={normalizePersonName}
          parts="PATRONYMIC"
        />
        <Field
          component={Input.Redux}
          label="Дата рождения"
          name="spouse_birth_date"
          mask={InputMaskType.DATE}
          width="4"
          placeholder="дд.мм.гггг"
          inputMode={InputModeType.NUMERIC}
        />
        <Field
          component={Input.Redux}
          name="years_married"
          label="Кол-во лет в браке"
          width="4"
          inputMode={InputModeType.NUMERIC}
          maxLength={3}
          mask={Number}
        />
        <Field
          component={Input.Redux}
          name="basic_monthly_income_spouse"
          normalize={monthlyIncome}
          label="Доход супруга(и)"
          width="4"
          inputMode={InputModeType.NUMERIC}
        />
        <Field
          component={Dadata.Redux}
          name="spouse_birth_place"
          label="Место рождения супруга(и)"
          width="12"
        />
        <Field
          component={Select.Redux}
          label="Социальный статус супруга(-и)"
          selected={employmentType}
          options={workingType}
          name="spouse_employment_type"
          field="spouse_employment_type"
          width="8"
        />
        <Field
          component={Input.Redux}
          name="spouse_mobile"
          label="Мобильный телефон"
          prefix="+7"
          placeholder="(___) ___ ____"
          mask={InputMaskType.MOBILE_PHONE_WITHOUT_COUNTRY_CODE}
          width="4"
          inputMode={InputModeType.NUMERIC}
          format={formatMobilePhone}
          normalize={normalizeMobilePhone}
        />
      </Grid>
    </FormSection>
  );
}
