import React from 'react';
import PropTypes from 'prop-types';

import styles from './FormError.module.scss';

export const FormError = ({ submitFailed, error}) => {
  if (!submitFailed) {
    return null;
  }

  return (
    <p className={styles.error}>
      Проверьте, пожалуйста, введенные данные.
      <br/>
      <span>{error}</span>
    </p>
  );
};

FormError.propTypes = {
  submitFailed: PropTypes.bool,
  error: PropTypes.string,
};
