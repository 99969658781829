import React from 'react';
import PropTypes from 'prop-types';
import { FINAL_CONDITIONS_ELEMENT_ID } from 'helpers/constants';
import { Form, FormSection, FormFooter } from 'components/form';
import { FinalConditionsInfoBlock } from './components/info-block/FinalConditionsInfoBlock';
import { formatCurrency } from "helpers";
import { selectCurrentOffer, selectCar } from "modules/car/selectors";
import { useSelector } from "react-redux";
import { selectPeriod } from "modules/car/selectors";
import { selectFirstOffer } from "modules/car/selectors";


import "./final-conditions.scss";
import { FinalConditionsDetails } from './components/details/FinalConditionsDetails';
import {AutoDriverDetails} from "pages/Main/components/AppForm/final-conditions/components/auto-driver-details/AutoDriverDetails";
import {Input} from "../../../../../components/reduxForm";
import {InputModeType} from "../../../../../components/UI";
import {InputMaskType} from "../constants";

export const FinalConditions = (props) => {
  const { onSubmit, goBack } = props;
  const currentOffer = useSelector(selectCurrentOffer);
  const prevOffer = useSelector(selectFirstOffer);
  const car = useSelector(selectCar);
  const period = useSelector(selectPeriod);

  const payment = currentOffer ? Math.round(currentOffer.calculations.payment) : 0;
  const deltaKaskoPayment = payment - Math.round(prevOffer.payment);
  const payment1 = payment - deltaKaskoPayment;

  return (
    <Form id={FINAL_CONDITIONS_ELEMENT_ID} className="final-conditions">
      <FormSection header="Отправьте заявку в банк" className="final-conditions__header">
        <div className="final-conditions__content">
          <FinalConditionsInfoBlock
            header="Итого"
            amount={payment}
            className="final-conditions__side"
          />
          <div className="final-conditions__main">
            <div className="final-conditions__main__blocks"><FinalConditionsInfoBlock
              header="Ежемесячный платеж за автомобиль"
              amount={payment1}
              className="final-conditions__main-left"
            />
              <FinalConditionsInfoBlock
                header="КАСКО"
                amount={deltaKaskoPayment}
                plus
                details="Детали"
                className="final-conditions__main-right"
                help = "Сумма страхования рассчитывается на весь срок кредита"
              >
              </FinalConditionsInfoBlock></div>

            {/*<FinalConditionsDetails />*/}
          </div>
          <AutoDriverDetails />
        </div>
      </FormSection>
      <FormFooter onSubmit={onSubmit} goBack={goBack} secondaryButtonClassName="finalpage" primaryButtonName="Отправить заявку в банк"/>
    </Form>
  );
};

FinalConditions.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
}
