import { ResponseCodeType, ResponseErrorText } from 'helpers/constants';

export class SmsAuthorization {
  static toBackendModel(mobilePhone, smsForce) {
    return {
      client_phone: mobilePhone,
      sms_force: smsForce
    }
  }

  static toSubmissionError({error_code: errorCode, error_text: errorText}) {
    const errorMessage = ResponseErrorText[errorCode] || errorText;

    switch(errorCode) {
      case ResponseCodeType.INVALID_PHONE_NUMBER:
        return { mobile_phone: errorMessage };
      case ResponseCodeType.SUCCESS:
        return;
      default:
        return {
          _error: errorMessage,
        };
    }

  }
}
