import {selectToken} from "../selectors";
import {getState} from "../../../helpers";

export const CHERY_TIGGO8_PRO_TOKEN = 'cherytiggo8pro';
export const CHERY_TOKEN2 = 'cheryonline';
export const CHERY_TIGGO8_PRO_FULL_ANKETA_TOKEN = 'cherytiggo8profullanketa';
export const CHERY_TOKEN = 'hyt67gfr43edfr65dd';

export const CHERY_TIGGO8_PRO_THEME_NAME = 'chery_tiggo';

export const OMODA = 'omoda';

export const EXEED = 'exeed';

export const CHERYNEW = 'cherynew';

export const isCheryTiggo8Pro = () => {
  const token = selectToken(getState());
  return token === CHERY_TIGGO8_PRO_TOKEN || token === CHERY_TIGGO8_PRO_FULL_ANKETA_TOKEN;
}

export const isAnyChery = () => {
  const token = selectToken(getState());
  return token === CHERY_TIGGO8_PRO_TOKEN
      || token === CHERY_TIGGO8_PRO_FULL_ANKETA_TOKEN
      || token === CHERY_TOKEN
      || token === CHERY_TOKEN2
      || token === OMODA
      || token === EXEED
      || token === CHERYNEW
  ;
}

export const isExeed = () => {
  const token = selectToken(getState());
  return token === EXEED;
}

export const replacePercentForCheryTiggo8Pro = (allOffers) => {

  if (!isCheryTiggo8Pro() || !allOffers || !allOffers.offers){
    return allOffers;
  }

  for(let i in allOffers.offers){
    for(let j in allOffers.offers[i]){
      if(allOffers.offers[i][j].interest_rate == 0.01){
        allOffers.offers[i][j].interest_rate = 0;
      }
    }
  }

  return allOffers;
}
