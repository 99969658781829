import {selectCar} from "../car";
import {getState} from "../../helpers";
import {fetchWrapper, MethodType} from "../../api/fetch";
import {ApiMethods, createApiMethodUrl} from "../../api/createApiMethodUrl";
import reducers from "../../redux/reducers";
import {selectCanShowKaskoFrameOffers} from "../app";
import {selectCarCondition} from "../app";

export const validateKaskoParams = async () => {
  const carInfo = selectCar(getState());
  carInfo.car_condition = selectCarCondition(getState());

  const response = await fetchWrapper(
    createApiMethodUrl(
      ApiMethods.validateKaskoParams, {}
    ), {
      method: MethodType.POST,
      body: JSON.stringify(carInfo)
    });

  const result = await response.json();

  reducers.car.update({ osagoCar: {brand: result.response.osago_brand, model: result.response.osago_model} });

  reducers.app.update({ canShowKaskoFrameOffers: result.response.show_kasko_frame })

  return result;
}

export const canShowKaskoFrameOffers = () => {
  return selectCanShowKaskoFrameOffers(getState());
}
