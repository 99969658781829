export const GosPrograms = {
  FIRST_AUTO: 'firstAuto',
  FAMILY_AUTO: 'familyAuto',
  TRADE_ID: 'tradeIn',
  MEDICAL_WORKER: 'medicalWorker',
};

export const GosProgramLabels = {
  [GosPrograms.FIRST_AUTO] : 'Первый автомобиль',
  [GosPrograms.FAMILY_AUTO] : 'Семейный автомобиль',
  [GosPrograms.TRADE_ID] : 'Трейд-Ин',
  [GosPrograms.MEDICAL_WORKER] : 'Медицинский работник'
};

export const GosProgramHelpText = {
  [GosPrograms.FIRST_AUTO] : 'Это программа льготного автокредитования с господдержкой при покупке первого автомобиля.',
  [GosPrograms.FAMILY_AUTO] : 'Это программа льготного автокредитования с господдержкой для семей с несовершеннолетними детьми.',
  [GosPrograms.TRADE_ID] : null,
  [GosPrograms.MEDICAL_WORKER] : 'Это программа льготного автокредитования с господдержкой для работников медицины.'
};
