import {selectToken} from "../selectors"
import {getState} from "../../../helpers"

export const JAECOO_TOKEN = 'jaecoo'

export const isJaecoo = (token) => {
  token = token ? token : selectToken(getState())
  const domain = window.location.host

  return token === JAECOO_TOKEN;
}
