import React from 'react';
import { isVolvoOnline } from "../../../modules/app/utils/isVolvo";


export const DisclimerOffer = () => {
  return isVolvoOnline()
  ? (
    <a
      href="https://www.volvocars.com/ru/buy/design-and-buy/financing/volvocarcredit"
      target="_blank"
    >
      Условия предоставления кредита
    </a>
    )
  : '';
}
