export const QUESTIONS = [
	{
    id: '1',
		question: 'Это предварительное или окончательное одобрение?',
		answer:
			'Одобрение банка является окончательным. В редких случаях банк может изменить свое решение. Например, из-за ввода некорректных данных.',
	},
	{
    id: '2',
		question: 'Где и как получить кредит по этому одобрению?',
		answer:
			'Для получения кредита достаточно обратиться в кредитный отдел дилера, которого вы выберете при подаче заявки.',
	},
	{
    id: '3',
		question: 'Могу ли я купить другой автомобиль, используя полученное одобрение?',
		answer:
			'Сотрудники дилерского центра, который вы выберете при заполнении заявки, помогут вам в подборе другого автомобиля и оформлении кредита.',
	},
	{
    id: '4',
		question: 'Как будет использована моя персональная информация?',
		answer:
			'Ваша персональная информация будет использована только для целей одобрения кредита и подбора программы страхования. Мы не передаем данные клиентов третьим лицам и не используем ваши контактные данные в маркетинговых целях.',
	},
];
