import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './form.scss';

export function Form({ className, onSubmit, children, id }) {
  return (
    <form id={id} className={classnames('form', className)} onSubmit={onSubmit}>
      {children}
    </form>
  )
}

Form.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  id: PropTypes.string,
};
