import { string, object, boolean } from 'yup';

export const validationSchema = object().shape({
  'middle_name': string()
    .required(),
  'surname': string()
    .required(),
  'name': string()
    .required(),
  'mobile_phone': string()
    .required()
    .phone(),
  'personal_data': boolean()
    .selected(),
  'agreement': boolean()
    .selected()
});
