import { createStore, combineReducers } from 'redaction'
import { reducer as form } from 'redux-form'
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './_reducers'

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

export const store = createStore({
  reducers: {
    ...combineReducers(reducers),
    form,
  },
  enhancers: composeEnhancers(),
});
