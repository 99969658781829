import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { Flex } from 'components/layouts';

import './styles.sass'


const animate = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:  { opacity: 0 },
}

export const Preloader = ({ isShow }) => (
  <Transition in={isShow} timeout={100} mountOnEnter unmountOnExit>
    {
      state =>
        <Flex f1 center className={'preloader'} style={animate[state]}>
          <div className={'wrapper'}>
            <div className={'loading'}>
              <div className={'line'} />
              <div className={'line'} />
              <div className={'line'} />
            </div>
          </div>
        </Flex>
    }
  </Transition>
);

Preloader.propTypes = {
  isShow: PropTypes.bool,
};
