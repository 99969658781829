import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Button } from "components/UI";
import { Driver } from "./Driver";
import { ReactComponent as AddIcon } from "./add.svg";

import { setSecondDriverTypeAsDeleted } from "modules/drivers/actions";

import "./drivers-field.scss";
import {DriverType} from "../constants";
import { selectDrivers } from "modules/form/selectors";

const MAX_COUNT_DRIVERS = 4;

export function DriversField({ fields, showAddDriverButton }) {

  const drivers = useSelector(selectDrivers);
  const limitDriversReached = drivers && drivers.length === MAX_COUNT_DRIVERS;

  const onRemoveDriver = ( index, type ) => {
    fields.remove(index);
    if (type === DriverType.SECOND){
      setSecondDriverTypeAsDeleted();
    }
  };

  return (
    <>
      {fields.map((driver, index) => (
        <Driver
          key={driver}
          driver={driver}
          index={index}
          value={fields.get(index)}
          onRemove={onRemoveDriver}
        />
      ))}
      <Button
        flat
        className="drivers-field__add-button"
        onClick={() => {
          if (limitDriversReached){
            alert('Можно указать не больше ' + MAX_COUNT_DRIVERS + ' водителей');
            return;
          }
          fields.push({})
        }}
      >
        {
          showAddDriverButton &&
            <React.Fragment>
              <AddIcon />
              <span className="drivers-field__add-button-label">
              Добавить водителя
            </span>
            </React.Fragment>
        }
      </Button>
    </>
  );
}

DriversField.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DriversField;
