import React from 'react'

const domain = window.location.host;

let api_url = `https://api-online.ecredit.one/v1`;

let environment;

export const env = {
  api_url,
  environment,
  envName: "prod",
    blocked: {
    token: [],
    host: [],
    all: false
  }
}
