import React, {useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'redaction';
import {app as application} from 'redux/actions';
import {Flex} from 'components/layouts';
import {Button, Grid, InputModeType} from 'components/UI';

import {Select, Checkbox, Input, Dadata} from 'components/reduxForm';
import {createValidator} from 'validation';
import {validationSchema} from './validationSchema';
import {selectIsMobile, selectToken} from 'modules/app';
import {Agreement} from '../../agreement/Agreement';
import {AgreementType} from '../../agreement/constants';
import {InputMaskType} from "../constants";
import {formatMobilePhone, normalizeMobilePhone, normalizePersonName} from "../utils";
import {ThankYouShort} from "../../ThankYouShort";

import {choseFirstCityDealer} from "modules/car/actions";
import {QUESTIONNAIRE_ELEMENT_ID, SwitchType} from "helpers/constants";
import {selectCitiesForCurrentBrand, selectCityDealersForCurrentBrand} from "modules/car/selectors";
import {ReactComponent as InfoShortIcon1} from "assets/info-short-icon1.svg";
import {ReactComponent as InfoShortIcon2} from "assets/info-short-icon2.svg";
import {ReactComponent as InfoShortIcon3} from "assets/info-short-icon3.svg";
import {ReactComponent as InfoShortIcon4} from "assets/info-short-icon4.svg";
import {ReactComponent as InfoRed1} from "assets/info-red1.svg";
import {ReactComponent as InfoRed2} from "assets/info-red2.svg";
import {ReactComponent as InfoRed3} from "assets/info-red3.svg";
import {ReactComponent as ArrowLine} from "assets/arrow-line.svg";
import { selectDealerBlock } from "modules/app";
import { selectTheme } from "modules/app";
import { CHERY_TIGGO8_PRO_THEME_NAME } from "../../../../../modules/app/utils/cheryTiggo8Pro";
import {isVolvoOnline} from "../../../../../modules/app/utils/isVolvo";
import {ShortIntroduction} from "../../short-introduction/ShortIntroduction";

import './info-short.scss';

export const InfoShort = connect({
  birth_date: 'app.birth_date',
  application_id: 'app.application_id',
  success_newapp: 'app.success_newapp',
  dealers: 'dictionary.dealers',
  cities: 'dictionary.cities',
  city: 'car.city',
  dealer: 'car.dealer'
})(reduxForm({
  form: 'Application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  keepDirtyOnReinitialize: true,
  initialValues: {
    personal_data: true
  },
  validate: createValidator(validationSchema),
})(
  ({application_id, success_newapp, handleSubmit, city, dealer, submitFailed}) => {
    const cities = useSelector(selectCitiesForCurrentBrand);
    const dealers = useSelector(selectCityDealersForCurrentBrand);
    const [newappRequestStatus, setReqStatus] = useState(false);
    const [showHappy, setHappyScreen] = useState(false);
    const dealerBlock = useSelector(selectDealerBlock);
    const [agreementType, setAgreementType] = useState(null);
    const theme = useSelector(selectTheme);

    useEffect(() => {
      if (newappRequestStatus && success_newapp) {
        setHappyScreen(true);
        setReqStatus(false);
      }
    }, [application_id, newappRequestStatus]);

    const closeAgreement = useCallback(() => setAgreementType(null), [setAgreementType]);
    const isCherry = useCallback(() => theme === 'chery_red' || theme === CHERY_TIGGO8_PRO_THEME_NAME, [theme])

    const openPersonalDataAgreement = useCallback(() => {
      setAgreementType(AgreementType.PERSONAL_DATA);
    }, [setAgreementType]);

    const openCreditBureauAgreement = useCallback(() => {
      setAgreementType(AgreementType.CREDIT_BUREAU);
    }, [setAgreementType]);

    const openElectronicDocumentManagementAgreement = useCallback(() => {
      setAgreementType(AgreementType.ELECTRONIC_DOCUMENT_MANAGEMENT);
    }, [setAgreementType]);

    const onSubmit = (e) => {
      e.preventDefault();
      setReqStatus(true);
      handleSubmit();
    }

    //const btnDisabled = !disableSubmit && !!personal_data;
    const btnDisabled = true;

    if (showHappy) {
      return <ThankYouShort/>;
    }

    const setDealerInfoToAppConfig = (dealer) => {
      application.setDealerToAppConfig(dealer)
    }

    const isCurrentVolvoOnline = isVolvoOnline();

    return (
      <div>
        <div className='info-short'>
          <ShortIntroduction />
        </div>
        <form className={'part-short'} onSubmit={onSubmit}>
          <section>
            {dealerBlock.show === SwitchType.ON &&
              <Grid className='center dealer-part'>
                <Select.Flat
                  width="6"
                  label="Ваш город"
                  options={cities}
                  selected={city}
                  module="car"
                  field="city"
                  onChange={choseFirstCityDealer}
                />
                <Select.Flat
                  width="6"
                  label="Ваш дилер"
                  options={dealers}
                  selected={dealer}
                  onChange={setDealerInfoToAppConfig}
                  module="car"
                  field="dealer"
                />
              </Grid>
            }
            <Grid className='center'>
              <Field
                component={Dadata.Redux}
                type="fio"
                name="surname"
                label="Ваша фамилия"
                width="6"
                mask={InputMaskType.PERSON_NAME}
                autoReplace={normalizePersonName}
                parts="SURNAME"
              />
              <Field
                component={Dadata.Redux}
                type="fio"
                name="name"
                label="Ваше имя"
                width="6"
                mask={InputMaskType.PERSON_NAME}
                autoReplace={normalizePersonName}
                parts="NAME"
              />
            </Grid>
            <Grid className='center'>
              <Field
                component={Input.Redux}
                name="mobile_phone"
                label="Ваш телефон"
                prefix="+7"
                placeholder="(___) ___ __ __"
                mask={InputMaskType.MOBILE_PHONE_WITHOUT_COUNTRY_CODE}
                width="6"
                //className={classnames('info__mobile-phone', { 'info__mobile-phone_disabled': phoneConfirmed })}
                inputMode={InputModeType.NUMERIC}
                format={formatMobilePhone}
                normalize={normalizeMobilePhone}
              />
            </Grid>

          </section>
          <section className="part-short__controls">
            <div className="info-short__agreement_check_block">
              <Field
                className="info-short__agreement"
                component={Checkbox.Redux}
                name="agreement"
                label={(
                  <label>
                    Я соглашаюсь с <span className="info__agreement-item"
                                         onClick={openElectronicDocumentManagementAgreement}>условиями</span> и даю свое <span
                    className="info__agreement-item" onClick={openPersonalDataAgreement}>согласие на обработку</span>

                    {
                      isCurrentVolvoOnline && <span> моих персональных данных</span>
                    }

                    {
                      !isCurrentVolvoOnline && <span>и использование моих персональных данных, и </span>
                    }

                    {
                      !isCurrentVolvoOnline && <span className="info__agreement-item" onClick={openCreditBureauAgreement}>разрешаю</span>
                    }

                    {
                      !isCurrentVolvoOnline && <span> сделать запрос в бюро кредитных историй</span>
                    }

                  </label>
                )}
              />
            </div>
            <div className="info-short__agreement-text">
              Отправляя настоящую заявку, я подтверждаю, что действую от своего имени и с целью получения решения Банка по
              вопросу предоставления мне кредита на приобретение автомобиля.
            </div>

            {
              submitFailed &&
              <p className={'warning'}>
                Проверьте, пожалуйста, введенные данные.
              </p>
            }

            <Flex column>
              <Button className="button-v1" submit disabled={!btnDisabled}>
                Жду звонка
              </Button>
            </Flex>
          </section>

          <Agreement
            type={agreementType}
            onClose={closeAgreement}
          />
        </form>
      </div>

    )
  }
))
