export const parseSubmissionTextError = (error) => {
  const fieldsIndex = error.indexOf("Fields:");

  if (fieldsIndex === -1) {
    return;
  }

  const fields = error.substring(fieldsIndex);
  const commaIndex = fields.indexOf(";");

  return fields.substring(0, commaIndex);
};
