import { createApplicationFormSelector } from './createApplicationFormSelector';
import { selectBirthDate } from "./selectBirthDate";
import { selectFullNameAsObject } from "./selectFullNameAsObject";

export const selectDrivers = (state) => {
  const drivers = createApplicationFormSelector(state, 'drivers')

  //При первом проходе дата рождения залетает только в store, а валидатор ищет дату рождения в объекте drivers[0]
  //Надо исправить валидатор, чтобы брал дату рождения из Стора
  if (drivers && drivers[0] && !drivers[0].birth_date){
    drivers[0].birth_date = selectBirthDate(state);
  }

  //Drivers прилетает с бека и сразу ложится в форму. Нужно заменить данные с 1-й вкладки в объекте drivers
  if (drivers && drivers[0]){

    const fullName = selectFullNameAsObject(state);

    if (fullName.name && fullName.name.value && fullName.name.value.length){
      drivers[0].name.value = fullName.name.value;
      drivers[0].middle_name.value = fullName.middle_name.value;
      drivers[0].surname.value = fullName.surname.value;
    }

  }

  return drivers;
};
