import React from 'react'
import { Transition } from 'react-transition-group'
import { connect } from 'redaction'

import { Flex } from 'components/layouts'

import './styles.sass'


const animate = {
  entering: { transform: "translate(-50%, -100%)" },
  entered: { transform: "translate(-50%, 20px)" },
  exiting:  { transform: "translate(-50%, 20px)" },
  exited:  { transform: "translate(-50%, -100%)" },
}


export const Logger = connect({
  message: 'user.message',
})(
  ({ message }) =>
    <Transition in={message} timeout={200} mountOnEnter unmountOnExit>
      {
        state =>
          <Flex
            className={'logger-container'}
            style={animate[state]}>
              {message}
          </Flex>
      }
    </Transition>
)
