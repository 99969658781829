import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';

import { InputType } from 'components/UI';
import { useAlternativeInput } from 'components/reduxForm/Input/useAlternativeInput';

export const FormInputField = ({
  name,
  type = InputType.TEXT,
  ...restProps
}) => {
  const { input, meta } = useField(name, { type });
  const error = meta.touched && meta.error ? meta.error : null;
  const InputField = useAlternativeInput();

  return (
    <InputField
      error={error}
      {...restProps}
      {...input}
    />
  );
}

FormInputField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
};
