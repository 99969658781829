import { selectCities, selectDealersForCurrentBrand } from 'modules/dictionary/selectors';

export const selectCitiesForCurrentBrand = (state) => {
  const cities = selectCities(state);
  const dealersWithCurrentBrand = selectDealersForCurrentBrand(state);

  const needCitiesIds = dealersWithCurrentBrand ? dealersWithCurrentBrand.map(({city_id}) => city_id) : [];

  return cities ? cities.filter(({ id }) => needCitiesIds.includes(id)) : [];
}
