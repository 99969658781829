import { HyundaiMetricSteps } from "./Hyundai";
import { selectDebugFlag } from "../modules/app";
import { getState } from "../helpers";

const eventNames = {
  START_APPLICATION: 'START_APPLICATION',
  NEW_APPLICATION: 'NEW_APPLICATION',
  APP_ONLINE_SUCCESS: 'APP_ONLINE_SUCCESS',
  SEND_CID: 'SEND_CID'
};

const metricEntities = {
  NEW_APPLICATION: {
    category: 'Кредит Онлайн',
    action: 'Создание заявки',
    label: 'applicationId={{applicationId}},clientApplicationId={{clientApplicationId}}'
  },
  START_APPLICATION: {
    category: 'Кредит Онлайн',
    action: 'Старт приложения',
    label: 'null'
  },
  APP_ONLINE_SUCCESS: {
    category: 'Кредит Онлайн',
    action: 'Оформление заявки',
    label: 'applicationId={{applicationId}},clientApplicationId={{clientApplicationId}}'
  },
  SEND_CID: {
    category: 'lead-send',
    action: 'lsubmit_e-credit_online_form_submit',
    label: '',
    cid: '{cid}'
  }
};


const sendedMetrics = {};

const currentStepIsProcessed = (metricName) => {

  if (metricName === HyundaiMetricSteps.INFORMATION_CARD_CLICKED || metricName === HyundaiMetricSteps.CHAT_BUTTON_CLICKED){
    return false;
  }

  if (sendedMetrics[metricName]){
    return true;
  }

  sendedMetrics[metricName] = true;

  return false;
}

const logg = (message, data) => {
  const debug = selectDebugFlag(getState());

  if (!debug){
    return;
  }
  window.console.log('message = ' + message, data);
}

const send = (gtmEvent, metricName) => {

  if (!window.dataLayer){
    return;
  }

  if (currentStepIsProcessed(metricName)){
    return null;
  }

  logg(metricName, gtmEvent);

  window.dataLayer.push(gtmEvent);
}

const createGtmEventForMetricName = metricName => {
  const gtmEvent = {
    "event": "custom_event",
    "category" : metricEntities[metricName].category,
    "action": metricEntities[metricName].action,
    "label": metricEntities[metricName].label
  };

  if (metricEntities[metricName].cid){
    gtmEvent.cid = metricEntities[metricName].cid;
  }

  return gtmEvent;
};

const replaceParams = (stringData, paramName, paramValue) => {
  return stringData.replace('{{' + paramName + '}}', paramValue);
}

export const gtmStartApplication = () => {
  const gtmEvent = createGtmEventForMetricName(eventNames.START_APPLICATION);

  send(gtmEvent, eventNames.START_APPLICATION);
}

export const gtmSendNewApplication = (applicationId, clientApplicationId = 0) => {
  const gtmEvent = createGtmEventForMetricName(eventNames.NEW_APPLICATION);

  gtmEvent.label = replaceParams(gtmEvent.label, 'applicationId', applicationId);
  gtmEvent.label = replaceParams(gtmEvent.label, 'clientApplicationId', clientApplicationId);

  send(gtmEvent, eventNames.NEW_APPLICATION);
}

export const gtmSendAppOnlineSuccess = (applicationId, clientApplicationId, cid) => {
  const gtmEvent = createGtmEventForMetricName(eventNames.APP_ONLINE_SUCCESS);

  gtmEvent.label = replaceParams(gtmEvent.label, 'applicationId', applicationId);
  gtmEvent.label = replaceParams(gtmEvent.label, 'clientApplicationId', clientApplicationId);

  send(gtmEvent, eventNames.APP_ONLINE_SUCCESS);

  const gtmEventCid = createGtmEventForMetricName(eventNames.SEND_CID);
  gtmEventCid.cid = replaceParams(gtmEventCid.cid, 'cid', cid);

  send(gtmEventCid, eventNames.SEND_CID);
}
