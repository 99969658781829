import React, { useState } from 'react';
import classnames from 'classnames';

import { Typography } from 'components/UI/typography/Typography';
import { TypographyType } from 'components/UI';
import { QUESTIONS } from './constants';

import { Hyundai, HyundaiMetricSteps } from "../../models";
import { selectToken, selectDebugFlag } from "../app/selectors";
import { getState } from "../../helpers";
import { isAnyChery } from "../app/utils/cheryTiggo8Pro";

import './faq.scss';

export const FAQ = () => {
	const [activeQuestion, setActiveQuestion] = useState(null);

	const token = selectToken(getState());
	const debugFlag = selectDebugFlag(getState());

	const toggleActiveQuestion = React.useCallback((event) => {
		const { dataset: { id }} = event.target;
		setActiveQuestion(id === activeQuestion ? null : id);
		try{
      const question = id ? QUESTIONS.filter(question => question.id === id)[0].question : null;
      Hyundai.sendMetric(HyundaiMetricSteps.INFORMATION_CARD_CLICKED, token, debugFlag, question);
    }catch (e) {
      console.log('Error in hyundai metrics', e);
    }
	}, [activeQuestion, setActiveQuestion]);

	const labelFAQ = isAnyChery()
    ? "Вопросы и ответы"
    : "Мы поможем вам разобраться";

	return (
		<div className="faq">
			<Typography text={labelFAQ} type={TypographyType.BLOCK_HEADER} />
			<div className="faq__questions-container">
				{QUESTIONS.map(({id, question, answer}) => (
					<React.Fragment key={id}>
						<div
							className={classnames('faq__question', { faq__question_active: id === activeQuestion})}
							data-id={id}
							onClick={toggleActiveQuestion}
						>
							{question}
							<div className="faq__question-triangle" />
						</div>
							<div className={classnames('faq__answer', {faq__answer_active: id === activeQuestion})}>
								{answer}
							</div>
					</React.Fragment>
				))}
			</div>
		</div>
	);
};
