import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Tooltip } from 'components/UI';
import { ReactComponent as HelpIcon } from './help.svg';

import './alternative-field.scss';

export const AlternativeField = ({ children, label, error, className, help, required = true }) => {

  return (
    <div className={classnames('alternative-field', className)}>
      <label className={classnames("alternative-field__label", { "alternative-field__label_skipped": !label })}>
        {label}
        {required && label && <span className="alternative-field__required-star">*</span>}
        {help && <Tooltip content={help}><span><HelpIcon /></span></Tooltip>}
      </label>
      {children}
      <div className={classnames("alternative-field__error", { "alternative-field__error_skipped": !label })}>
        {error}
      </div>
    </div>
  );
}

AlternativeField.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  help: PropTypes.string,
}
