import React from 'react';
import { useSelector } from 'react-redux';

import { Header, Footer } from 'components/layouts';
import { Main } from 'pages';
import { MainLayout, Preloader } from 'components/layouts'
import {
  selectHeaderBlock,
  selectFooterBlock,
} from 'modules/app';
import { selectIsLoading } from 'modules/user';

import { SwitchType } from 'helpers/constants';

export const MainPage = () => {
  const header = useSelector(selectHeaderBlock);
  const footer = useSelector(selectFooterBlock);
  const isLoading = useSelector(selectIsLoading);

  const creditonlineShow = header.items.creditonline.show === SwitchType.ON;
  const eosagoShow = header.items.eosago.show === SwitchType.ON;

  return (
    <>
      {header.show === SwitchType.ON && <Header creditonline={creditonlineShow} eosago={eosagoShow}/>}
        <MainLayout>
          <Preloader isShow={isLoading} />
          <Main />
        </MainLayout>
      {footer && footer.show === SwitchType.ON && <Footer />}
    </>
  );
};
