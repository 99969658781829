import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import { FormInputField } from 'components/final-form/form-input-field';

import { app } from 'redux/actions';

import { Button } from 'components/UI';
import { createValidator } from 'validation';
import { validationSchema } from './validationSchema';

import './feedback.scss';

export const Feedback = ({ isOpen }) => {
  const feedbackActions = React.useRef(null);

  React.useEffect(() => {
    if (isOpen) {
      feedbackActions.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <Form
      onSubmit={(values) => {
        console.log(values);
        app.toggleFeedbackForm();
      }}
      validate={createValidator(validationSchema)}
    >
      {({ handleSubmit }) => (
        <form className="feedback">
          <FormInputField name="name" label="Ваше имя" />
          <FormInputField
            name="email"
            label="Ваш адрес электронной почты"
          />
          <FormInputField
            component="textarea"
            name="question"
            label="Ваш вопрос"
            inputClassName="feedback__question"
          />
          <div className="feedback__actions" ref={feedbackActions}>
            <Button flat onClick={app.toggleFeedbackForm}>Назад</Button>
            <Button onClick={handleSubmit}>Отправить</Button>
          </div>
        </form>
      )}
    </Form>
  )
}

Feedback.propTypes = {
  isOpen: PropTypes.bool,
};
