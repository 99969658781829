import { getPts } from "./getPts";

const KASAGO_PTS_DOCUMENT_TYPE = 1;

export const getCarDocument = () => {
  const pts = getPts();

  return {
    date: pts.issue_date,
    documentType: KASAGO_PTS_DOCUMENT_TYPE,
    series: pts.series,
    number: pts.number
  }
};
