
export const resizeHandler = (callback) => {
  let resizeTimeout = null;

  const resizeThrottler = () => {
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(() => {
        resizeTimeout = null;
        callback();
       }, 66);
    }
  }

  window.addEventListener("resize", resizeThrottler, false);

  return () => window.removeEventListener("resize", resizeThrottler);
}
