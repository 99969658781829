import { getCookieValue } from "../app/utils/cookie";

class YandexMetrika
{
  static getClientId()
  {
    const Ya = window.Ya;

    if (!!Ya.Metrika){
      const counterId = Ya.Metrika.counters()[0].id;
      return  Ya.Metrika(counterId).getClientID();
    } else if (!!Ya.Metrika2) {
      const counterId = Ya.Metrika2.counters()[0].id;
      return Ya.Metrika2(counterId).getClientID();
    } else if (!!getCookieValue("_ym_uid")) {
      return getCookieValue("_ym_uid");
    }

    return null;
  }
}

export { YandexMetrika };
