import React from "react";
import "./not-working-page.scss";
import {Typography, TypographyType} from "../../components/UI/typography";
import {SwitchType} from "../../helpers/constants";
import {Button} from "../../components/UI/Button";

export const NotWorkingPage = () => {
  return (
    <div className="not-working">
      <Typography type={TypographyType.RESULT_HEADER} text="Ведутся технические работы" />
      <Typography
        type={TypographyType.SUB_HEADER}
        text="Приносим свои извинения. Работа сайта возобновится в ближайшее время."
      />
      {
        <a href={document.referrer} className='not-working__closebutton'>
          <Button>Назад</Button>
        </a>
      }
    </div>
  );
}
