import React, { useState, useEffect } from 'react';
import { selectCarCondition } from "modules/app";
import { selectCarBrandName } from "modules/car";
import { useSelector } from 'react-redux';
import { onlineBanks } from "api/online-banks";

export const Banks = () => {

  const [banks, setBanks] = useState([]);
  const brandName = useSelector(selectCarBrandName);
  const carCondition = useSelector(selectCarCondition);

  useEffect(() => {
    const loadOnlineBanks = async () => {
      //const { banks } = await onlineBanks.loadBanks(carCondition, brandName);
      const { banks } = await onlineBanks.loadBanks();
      setBanks(banks);
    };
    loadOnlineBanks();
  }, [brandName, carCondition]);

  return banks.map(({ legal_name: legalName, legal_type: legalType, inn, address }) => (
    <tr key={legalName}>
      <td>{`${legalType} ${legalName}`}</td>
      <td>{`ИНН ${inn}`}</td>
      <td>{address}</td>
    </tr>
  ))
};
