import Settings from "../modules/user/reducers";
import { ResponseCodeType } from 'helpers/constants';

export const isRequestFailed = (code) => code !== ResponseCodeType.SUCCESS;

export const api = {
  brands: `dictionary/brand`,
  models: `dictionary/model`,
  configs: `dictionary/configuration`,
  newapp: 'application/newapp',
  app_online: 'application/apponline',
  config: 'application/fihubconfig',
  citydealer:'dictionary/dealer-city-list',
  appStatus: 'application/appstatus',
  authSMS: 'sms/authsms',
  authCode: 'sms/authcode',
  errorLogger: 'error-logger/logg',
  carRecognize: 'carsource/recognize',
  intercomSettings: 'utility/intercominfo',
  suggestById: 'dadata/suggest-by-id'
};
