import {addMethod, string} from "yup";
import {subYears, startOfDay, max, addYears, isValid} from "date-fns";
import {stringToDate} from "helpers";

const DRIVE_EXPERIENCE_START_AGE = 18;
const DRIVE_LICENSE_DURATION = 10;

function driveLicenseSeries() {
  return this.test("driveLicenseSeries", "Невалидное значение", (value) => {
    if (value === undefined) {
      return true;
    }

    return /^\d{2}[\dА-я]{2}$/g.test(value);
  });
}

addMethod(string, "driveLicenseSeries", driveLicenseSeries);

function driveLicenseNumber() {
  return this.test("driveLicenseNumber", "Невалидное значение", (value) => {
    if (value === undefined) {
      return true;
    }
    return /^\d{6}$/.test(value);
  });
}

addMethod(string, "driveLicenseNumber", driveLicenseNumber);

function notLatin() {
  return this.test("notLatin", "Нельзя использовать буквы латинского алфавита", (value) => {
    if (value === undefined) {
      return true;
    }

    return !(new RegExp(/[a-z]/ig)).test(value);
  })
}

addMethod(string, "notLatin", notLatin);

function driverLicense() {
  return this.test("driverLicense", "Невалидное значение", (value) => {
    if (value === undefined) {
      return true;
    }

    return /^\d{2}[\dА-я]{2}\d{6}$/g.test(value);
  });
}

addMethod(string, "driverLicense", driverLicense);

function driverLicenseIssueDate() {
  return this.date()
    .notFutureDate()
    .minDateDependsOnOtherDate("birth_date", 18, "Дата выдачи не может быть меньше даты на момент 18-летия заемщика")
    .test({
      test: (value) => {
        const issueDate = stringToDate(value);
        const minDateExpired = subYears(startOfDay(new Date()), 10);
        return issueDate >= minDateExpired;
      },
      message: "Дата выдачи меньше минимально допустимой",
    })
    .test({
      test: (value) => {
        const issueDate = stringToDate(value);

        if (!isValid(issueDate)) {
          return true;
        }

        const minDate = subYears(
          startOfDay(new Date()),
          DRIVE_LICENSE_DURATION
        );
        return issueDate > minDate;
      },
      message: "Водительское удостоверение просрочено",
    });
}

addMethod(string, "driverLicenseIssueDate", driverLicenseIssueDate);

function driverLicenseExperience() {
  return this
    .min(1)
    .max(2)
    .onlyNumbers()
    .when("birth_date", function (birthDate) {
      return this.test({
        test: (value) => {
          const experienceFrom = subYears(new Date(), Number(value || 0));
          const minExperienceFrom = addYears(
            stringToDate(birthDate),
            DRIVE_EXPERIENCE_START_AGE
          );
          return experienceFrom >= minExperienceFrom;
        },
        message: "Стаж вождения больше максимально допустимого",
      });
    });
}

addMethod(string, "driverLicenseExperience", driverLicenseExperience);

function driverLicenseExperienceBegin() {
  return this.date()
    .notFutureDate()
    .minDateDependsOnOtherDate("birth_date", 18, "Дата начала стажа не может быть меньше даты на момент 18-летия заемщика")
    .when("issue_date_2nd", function (licenseIssueDate) {
      return this.test({
        test: (value) => {
          if (!value) return;
          if (!licenseIssueDate) return true;
          return stringToDate(value) <= stringToDate(licenseIssueDate)
        },
        message: "Дата начала стажа больше даты выдачи ВУ",
      });
    });
}

addMethod(string, "driverLicenseExperienceBegin", driverLicenseExperienceBegin);
