import { YandexMetrika } from "./yandex-metrika";
import { GoogleAnalytics } from "./google-analytics";

class Metric
{
  static getMetricClients(){
    try{
      return {
        google_client_id: GoogleAnalytics.getClientId(),
        yandex_client_id: YandexMetrika.getClientId()
      };
    }catch (e){
      console.log('Ошибка получения метрики', e);
    }
  }
}

export { Metric };
