import { formatCurrency } from 'helpers'


export const normalizePhone = value => {
  if (!value) return value
  let onlyNums = value.replace('+7', '').replace(/[^\d]/g, '')
  onlyNums = onlyNums[0] === '9' ? onlyNums : '';

  if (onlyNums.length <= 3) return `+7 (${onlyNums}`
  if (onlyNums.length <= 6) return `+7 (${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`
  if (onlyNums.length <= 10) return `+7 (${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)} ${onlyNums.slice(6, 10)}`

  return `+7 (${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)} ${onlyNums.slice(6, 10)}`
}

export const onlyNumbers = value => {
  if (!value) return value

  return value.replace(/[^\d]/g, '')
}
export const onlySmallNumbers = value => {
  if (+value > 9) return value[0]
  return onlyNumbers(value)
}
export const onlyHundred = value => {
  if (+value > 99) return value.slice(0, 2)
  return onlyNumbers(value)
}

export const onlyText = value => {
  if (!value) return value
  const woNums = value.replace(/[A-Za-z\d]/g, '')

  if (woNums.length > 20) return woNums.slice(0, 20)
  return woNums
}

export const onlyRus = value => {
  if (value.value){
    value = value.value;
  }
  if (!value) return value;

  const res = value.replace(/[^А-Яа-я\-]/g, '');

  return res;
}

export const onlyEng = value => {
  if (!value) return value
  return value.replace(/[А-Яа-я]/g, '')
}

export const inn = value => {
  if (!value) return value

  if (value.length > 12) return value.slice(0, 12)
  return onlyNumbers(value)
}

export const monthlyIncome = value => {
  if (!value) return value

  const bare = onlyNumbers(value)
  if (bare.length > 8) return formatCurrency(bare.slice(0, 8))
  return formatCurrency(bare)
}

export const postalCode = value => {
  if (!value) return value
  if (value.length > 6) return value.slice(0, 6)
  return onlyNumbers(value)
}

export const normalizeDate = (val, prevVal) => {
  if (isNaN(parseInt(val[val.length - 1], 10))) {
    return val.slice(0, -1)
  }

  if (prevVal && (prevVal.length >= val.length)) {
    return val
  }

  // Add / at appropriate sections of the input
  if (val.length === 2 || val.length === 5) {
    val += '-'
  }

  // Prevent characters being entered after Dob is full
  if (val.length >= 10) {
    return val.slice(0, 10)
  }

  return val
}

const series = value => {
  if (!value) return value

  if (value.length > 4) return onlyNumbers(value.slice(0, 4))
  return onlyNumbers(value)
}

const number = value => {
  if (!value) return value

  if (value.length > 6) return value.slice(0, 6)
  return onlyNumbers(value)
}

const code = value => {
  if (!value) return value
  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums.length <= 3) return onlyNums
  if (onlyNums.length <= 6) return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}`
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}`
}

const series_drive = value => {
  if (!value) return value

  return value.slice(0, 4)
}

export const document = { series, number, code, series_drive }
