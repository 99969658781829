import {getState} from "../../helpers";
import {selectCar} from "../../modules/car";

const hyundaiModels = [
  {
    "id": "2544",
    "name": "Accent"
  },
  {
    "id": "2545",
    "name": "Atos"
  },
  {
    "id": "2546",
    "name": "Avante"
  },
  {
    "id": "2547",
    "name": "Centennial"
  },
  {
    "id": "2548",
    "name": "Click"
  },
  {
    "id": "2549",
    "name": "Coupe"
  },
  {
    "id": "5033",
    "name": "Creta"
  },
  {
    "id": "2550",
    "name": "Dynasty"
  },
  {
    "id": "2551",
    "name": "Elantra"
  },
  {
    "id": "2552",
    "name": "Equus"
  },
  {
    "id": "2553",
    "name": "Excel"
  },
  {
    "id": "2554",
    "name": "Galloper"
  },
  {
    "id": "2555",
    "name": "Galloper ii"
  },
  {
    "id": "2556",
    "name": "Genesis"
  },
  {
    "id": "2557",
    "name": "Genesis coupe"
  },
  {
    "id": "2558",
    "name": "Getz"
  },
  {
    "id": "2559",
    "name": "Grace"
  },
  {
    "id": "4410",
    "name": "Grand santa fe"
  },
  {
    "id": "255700",
    "name": "Grand starex"
  },
  {
    "id": "2560",
    "name": "Grandeur"
  },
  {
    "id": "2563",
    "name": "H-1/starex (категория b)"
  },
  {
    "id": "2564",
    "name": "H-1/starex (категория d)"
  },
  {
    "id": "4411",
    "name": "H1"
  },
  {
    "id": "4412",
    "name": "H1 starex"
  },
  {
    "id": "2561",
    "name": "H1/grand starex (категория b)"
  },
  {
    "id": "4063",
    "name": "H1/grand starex (категория b)"
  },
  {
    "id": "2562",
    "name": "H1/grand starex (категория d)"
  },
  {
    "id": "2565",
    "name": "H100"
  },
  {
    "id": "2566",
    "name": "H100 bus"
  },
  {
    "id": "2567",
    "name": "H150 pickup"
  },
  {
    "id": "2568",
    "name": "H200"
  },
  {
    "id": "2569",
    "name": "Highway"
  },
  {
    "id": "2570",
    "name": "I10"
  },
  {
    "id": "2571",
    "name": "I20"
  },
  {
    "id": "2572",
    "name": "I30"
  },
  {
    "id": "2573",
    "name": "I40"
  },
  {
    "id": "2574",
    "name": "I45 (sonata)"
  },
  {
    "id": "2575",
    "name": "Ix35"
  },
  {
    "id": "2576",
    "name": "Ix55"
  },
  {
    "id": "2577",
    "name": "Lantra"
  },
  {
    "id": "2578",
    "name": "Lavita"
  },
  {
    "id": "2579",
    "name": "Matrix"
  },
  {
    "id": "4413",
    "name": "Nf"
  },
  {
    "id": "4414",
    "name": "Nf sonata"
  },
  {
    "id": "2580",
    "name": "Nf/sonata nf"
  },
  {
    "id": "2581",
    "name": "Pony"
  },
  {
    "id": "2582",
    "name": "Pony excel"
  },
  {
    "id": "2583",
    "name": "Porter h100"
  },
  {
    "id": "4070",
    "name": "Porter h100"
  },
  {
    "id": "2584",
    "name": "S coupe"
  },
  {
    "id": "2585",
    "name": "Santa fe"
  },
  {
    "id": "2586",
    "name": "Santamo"
  },
  {
    "id": "2587",
    "name": "Satellite"
  },
  {
    "id": "2588",
    "name": "Solaris"
  },
  {
    "id": "2589",
    "name": "Sonata"
  },
  {
    "id": "4415",
    "name": "Starex"
  },
  {
    "id": "2590",
    "name": "Stellar"
  },
  {
    "id": "2591",
    "name": "Terracan"
  },
  {
    "id": "4416",
    "name": "Tiburon"
  },
  {
    "id": "2592",
    "name": "Tiburon tuscani"
  },
  {
    "id": "2593",
    "name": "Trajet"
  },
  {
    "id": "2594",
    "name": "Tucson"
  },
  {
    "id": "2595",
    "name": "Veloster"
  },
  {
    "id": "2596",
    "name": "Veracrus"
  },
  {
    "id": "2597",
    "name": "Verna"
  },
  {
    "id": "2598",
    "name": "Xg"
  },
  {
    "id": "2599",
    "name": "Другая модель (автобус)"
  },
  {
    "id": "2600",
    "name": "Другая модель (грузовой)"
  },
  {
    "id": "255701",
    "name": "Palisade"
  }
];

export const getOsagoBrandAndModelId = () => {
  const car = selectCar(getState());

  console.log('car', car);

  return {
    modelId: car.osagoCar.model.id,
    brandId: car.osagoCar.brand.id
  };

  // const modelInfo = hyundaiModels.find(item => model && item.name && model.toLowerCase().indexOf(item.name.toLowerCase()) > -1);
  //
  // return {
  //   modelId: modelInfo ? +modelInfo.id : null,
  //   brandId: 106
  // };
}
