import React from 'react';
import PropTypes from "prop-types";
import { formatCurrency } from "helpers";
import {ReactComponent as AlertIcon} from "assets/alert.svg";
import "./customer-income.scss";
import classnames from "classnames";

export const CustomerIncome = ({monthlyPayment, isVisible, className}) => {
  const customerIncome = Math.round(monthlyPayment * 3 / 100 ) * 100;

  return (
    isVisible &&
    <div className={classnames("customer-income", className)}>
      <AlertIcon />
      <span>При выбранном ежемесячном платеже, Ваш ежемесячный доход должен быть не менее {formatCurrency(customerIncome)} руб.</span>
    </div>
  )
}

CustomerIncome.propTypes = {
  monthlyPayment: PropTypes.number,
  isVisible: PropTypes.bool
}
