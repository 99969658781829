import { selectCar } from "./selectCar";
import { selectCity } from './selectCity';
import { selectDealers } from 'modules/dictionary/selectors';
import { selectCarCondition } from "../../app/selectors";
import { canDealerForCarCondition } from "../../dictionary/utility/canDealerForCarCondition";

export const selectCityDealersForCurrentBrand = (state) => {
  const city = selectCity(state);
  const dealers = selectDealers(state) || [];
  const car = selectCar(state);
  const carCondition = selectCarCondition(state);

  const cityWithDealers = city ? dealers.filter(({ sell_only_used, city_id }) => canDealerForCarCondition(carCondition, sell_only_used) && city_id === city.id) : [];

  return cityWithDealers.filter(({ brands }) => car.brand && brands ? brands.includes(car.brand.name.toLowerCase()) : true);
}
