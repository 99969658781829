import { format, parse } from "date-fns";

export const toFrontendDate = date => {
  if (date && date != '0000-00-00') {
    try{
      return format(parse(date, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy');
    }catch(e){
      console.log('data not valid', date, e);
    }
  }
};

export const toBackendDate = date => {
  if (date) {
    try{
      return format(parse(date, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd')
    }catch(e){
      console.log('back data not valid', date, e);
    }
  }
};
