import React from "react";
import { useSelector } from "react-redux";
import { selectSuccessReturnUrl } from "modules/app/selectors";
import { Typography } from "components/UI/typography/Typography";
import {Button, TypographyType} from "components/UI";
import { isHyundaiDosProject } from "../../../../modules/app/utils/isHyundaiDosProject";
import "./thank-you.scss";
import {isKia} from "../../../../modules/app/utils/isKia";

export const ThankYou = () => {
  const successReturnUrl = useSelector(selectSuccessReturnUrl);
  return (
    <div className="thank-you">
      <Typography type={TypographyType.RESULT_HEADER} text="Спасибо!" />
      <Typography
        type={TypographyType.RESULT_HEADER}
        text="Мы получили вашу заявку и отправили ее в банк."
        className="thank-you__result-header"
      />
      <Typography
        type={TypographyType.SUB_HEADER}
        text="Мы сообщим вам о результате рассмотрения заявки"
      />
      <Typography
        type={TypographyType.SUB_HEADER}
        text="сразу, как получим результат"
      />
      {
        (isHyundaiDosProject() || isKia()) &&
            <a href={successReturnUrl} className='thank-you__closebutton'>
              <Button>Вернуться назад</Button>
            </a>
      }
    </div>
  );
}
