import {selectShowFormImmediate} from "../../modules/app";
import {timeStampToDate} from "../../modules/utils";
import { getState } from "helpers";

const addPolicyStartDays = {
  ON_CREDIT: 15,
  ON_PAYMENT: 1
};

export const getPolicyStartDate = () => {
  const showFromImmediate = selectShowFormImmediate(getState());
  let date = new Date();

  showFromImmediate
    ? date.setDate(date.getDate() + addPolicyStartDays.ON_PAYMENT)
    : date.setDate(date.getDate() + addPolicyStartDays.ON_CREDIT);

  return timeStampToDate(date);
}
