import * as commonReducers from 'helpers/commonReducers'


const initialState = {
  secondTypeDriverIsDeleted: false
}

export default {
  initialState,
  ...commonReducers,
}
