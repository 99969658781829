import React from "react";
import { reduxForm, formValueSelector, getFormValues } from "redux-form";
import { connect } from "redaction";
import { FormError } from "components/reduxForm";

import { createValidator, scrollToFirstError } from "validation";
import { validationSchema } from "./validationSchema";

import { MaritalStatus } from "helpers/constants";
import { ContactPerson } from "./components/ContactPerson";
import { Spouse } from "./components/Spouse";

import { FORM_NAME } from "modules/form";
import { selectMaritalStatus } from "modules/form/selectors/selectMaritalStatus";
import { Form } from "components/form";
import { MartialStatus } from "./components/MartialStatus";
import { Passport } from "./components/Passport";

import { PreviousPassport } from "./components/PreviousPassport";
import { Address } from "./components/Address";
import { ActualAddress } from "./components/ActualAddress";
import { ObligatoryPayments } from "./components/ObligatoryPayments";
import { FormFooter } from "components/form/FormFooter";

import "./docs.scss";

export const Docs = connect({
  maritalStatus: selectMaritalStatus,
  noPreviousPassport: (state) =>
    formValueSelector("Application")(state, "no_previous_passport"),
  actualAddressIsEqualAddress: (state) =>
    formValueSelector("Application")(state, "matches_registration"),
  initialValues: (state) => ({
    no_previous_passport: true,
    matches_registration: true,
    ...getFormValues(FORM_NAME)(state),
  }),
})(
  reduxForm({
    form: "Application",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: createValidator(validationSchema),
    onSubmitFail: scrollToFirstError,
  })(
    ({
      goBack,
      handleSubmit,
      maritalStatus,
      change,
      noPreviousPassport,
      submitFailed,
      actualAddressIsEqualAddress,
      error,
    }) => {
      const onChildrenChange = React.useCallback(
        (value) => {
          change("number_of_dependents", value);
        },
        [change]
      );

      return (
        <Form className="metric_form_docs">
          <MartialStatus onChildrenChange={onChildrenChange} />
          {maritalStatus && maritalStatus.id === MaritalStatus.MARRIED ? (
            <Spouse />
          ) : (
            <ContactPerson />
          )}
          <Passport />
          {!noPreviousPassport && <PreviousPassport /> || ''}
          <Address />
          {!actualAddressIsEqualAddress && <ActualAddress /> || ''}
          <ObligatoryPayments />
          <FormError submitFailed={submitFailed} error={error} />
          <FormFooter
            goBack={goBack}
            primaryButtonDisabled={false}
            onSubmit={handleSubmit}
            help={null}
          />
        </Form>
      );
    }
  )
);
