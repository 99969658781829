import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import {connect} from 'redaction';
import {Range} from 'react-range';
import {car} from 'redux/actions';

import {Flex} from 'components/layouts';
import {InputField, Typography, TypographyType} from 'components/UI';

import placeholder from 'assets/no_pic.png';
import {InputModeType} from 'components/UI';
import dragLeftIcon from 'assets/drag-left.gif';

import './styles.sass';
import {Calculator} from "../../../../models/calculator";
import {formatCurrency} from "helpers";
import {selectAppConfig, selectTheme, selectShowCalculatorDisclimer} from "modules/app/selectors";
import {HIDDEN_AUTO_PRICE_BY_BRANDS} from "helpers/constants";

export const Slider = connect({
  price: 'car.price',
  model: 'car.model',
  initialFee: 'car.initial_fee',
  initialFeeMoney: 'car.initial_fee_money',
  isMobile: 'app.isMobile',
  appConfig: selectAppConfig,
  theme: selectTheme,
  showCalculatorDisclimer: selectShowCalculatorDisclimer
})(
  class SliderСomponent extends PureComponent {
    static propTypes = {
      isSliderEnabled: PropTypes.bool,
      price: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string, // TODO: It must be always number
      ]),
      model: PropTypes.shape({
        picture: PropTypes.string,
      }),
      initialFee: PropTypes.number,
      initialFeeMoney: PropTypes.number,
      isMobile: PropTypes.bool,
    };

    state = {
      showDragIcon: true,
    };

    setPercent = initialFee => {
      const {price} = this.props;
      car.update({
        initial_fee: Array.isArray(initialFee) ? initialFee[0] : initialFee,
        initial_fee_money: Number(price) / 100 * initialFee,
      });
      this.finishDragAnimation();
    };

    finishDragAnimation = () => {
      this.setState({showDragIcon: false});
    };

    onTrackClick = initialFee => {
      this.setPercent(initialFee);
      this.handleOffers();
    };

    handleOffers = debounce(() => {
      const {price, initialFeeMoney} = this.props;

      car.update({period: null, chosen: null});

      if (price && (initialFeeMoney || initialFeeMoney === 0)) {
        car.getOffers();
      }
    }, 700);

    setFee = value => {
      const {price} = this.props;
      const initialFeeMoney = value ? Number(value) : 0;

      if (initialFeeMoney < Number(price)) {
        car.update({
          initial_fee_money: initialFeeMoney,
          initial_fee: Math.round(initialFeeMoney * 100 / price),
        });
        this.handleOffers();
      }
    };

    createInitialFeeMoneyMask = () => {
      const {price} = this.props;

      return [{
        mask: 'SUM ₽',
        lazy: false,
        blocks: {
          SUM: {
            mask: Number,
            thousandsSeparator: ' ',
            max: Number(price),
          },
        },
      }];
    };

    render() {
      const {
        model,
        price,
        initialFee,
        initialFeeMoney,
        isMobile,
        isSliderEnabled,
        theme,
        showCalculatorDisclimer
      } = this.props;

      const disabledBlockClassName = isSliderEnabled ? '' : 'slider__blocked';
      const {showDragIcon} = this.state;

      const domain = model && model.picture && !model.picture.includes('http') ? 'https://credspec.car-fin.com' : '';
      const image = model && model.picture
        ? domain + model.picture
        : placeholder;

      return (
        <Flex column={isMobile}
              relative
              center
              justifyBetween
              className="slider"
        >
          <Flex column alignCenter className={`display ${disabledBlockClassName}`}>
            {!HIDDEN_AUTO_PRICE_BY_BRANDS.includes(theme) && (<>
              <Typography className="slider__price_description" type={TypographyType.PARAGRAPH}>Стоимость автомобиля</Typography>
              <Typography className="slider__price" type={TypographyType.SUB_HEADER}>
                {`${price.toLocaleString("ru")} ₽`}
              </Typography>
            </>)}
            <div className="slider__input-description">
              Введите сумму первоначального взноса
            </div>
            <div className="slider__input-container">
              <InputField
                inputClassName="slider__input"
                value={initialFeeMoney ? String(initialFeeMoney) : '0'}
                onChange={this.setFee}
                disabled={!Number(price)}
                mask={this.createInitialFeeMoneyMask()}
                unmask
                inputMode={InputModeType.NUMERIC}
                required={false}
              />
            </div>
          </Flex>
          <div className={`slider__ranger ${disabledBlockClassName}`}>
            <Range
              step={1}
              min={0}
              max={100}
              values={[Math.floor(initialFee)]}
              disabled={!Number(price)}
              onChange={this.setPercent}
              onFinalChange={this.handleOffers}
              renderTrack={({props, children}) => (
                <div
                  {...props}
                  className="track"
                  style={{background: `url("${image}") no-repeat center/contain`}}
                >
                  {children}
                  <div className="backlog" style={{transform: `translateX(${initialFee}%)`}}/>
                </div>
              )}
              renderThumb={({props: {key, ...rest}, value}) => (
                <div
                  key={key}
                  {...rest}
                  className="slider__handle"
                >
                  {showDragIcon && (
                    <img className="slider__drag-icon" src={dragLeftIcon} alt="drag icon"/>
                  )}
                  <div>{value}</div>
                  <div>{100 - value}</div>
                </div>
              )}
            />
            {showCalculatorDisclimer && <div className="track-caption">
              Комплектация на изображении может отличаться от выбранной в калькуляторе.
            </div>}
          </div>
        </Flex>
      );
    }
  }
);
