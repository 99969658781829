export function addParamsToUrl(url, paramsToAdd) {
  const params = new URLSearchParams();

  Object.keys(paramsToAdd).forEach(key => {
    const paramValue = paramsToAdd[key];

    if (shouldAddParam(paramValue)) {
      if (Array.isArray(paramValue)) {
        paramValue.forEach((value) => {
          params.append(key, value);
        });
      } else {
        params.append(key, paramValue);
      }
    }
  });

  const sign = params.toString() === '' ? '' : '?';

  return `${url}${sign}${params}`;
}

function shouldAddParam(paramValue) {
  return paramValue !== undefined && paramValue !== '';
}
