import { fetchWrapper, MethodType } from '../../fetch';
import { createApiMethodUrl, ApiMethods } from "../../createApiMethodUrl";

export const agreement = {

  async loadAgreement (userFields) {
    const response = await fetchWrapper(createApiMethodUrl(ApiMethods.agreement), {
      method: MethodType.POST,
      headers: {},
      body: JSON.stringify(userFields)
    });
    return response;
  }
};
