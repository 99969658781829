import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { equipments } from "./constants";
import { EquipmentItem } from "./equipment-item";
import "./style.scss";

export const EquipmentSelector = ({ items = [], onChange = () => {} }) => {
  const [activeEquipment, setActiveEquipment] = useState(null);

  const setDescription = (options = [], index) => {
    let title = 'В пакет включено';
    if (index === 1) title = 'Дополнение к комплектации Prestige';
    if (index === 2) title = 'В дополнение к пакету Speedline';

    return (options && <div>
      <b>{title}:</b>
      <ul>
        {options.map(o => <li>{o}</li>)}
      </ul>
    </div>);
  }

  const handleChange = (index) => {
    setActiveEquipment(index);
    onChange(equipments[index]);
  }

  useEffect(() => {
    handleChange(0)
  }, []);

  return (
    <div className="equipment-selector">
      {equipments &&
      equipments.map((e, index) =>
        <EquipmentItem
          name={e.displayName}
          price={e.displayPrice}
          description={setDescription(e.description, index)}
          active={activeEquipment === index}
          onClick={() => handleChange(index)}/>)
      }
    </div>
  )
}

EquipmentSelector.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func
}
