import * as commonReducers from 'helpers/commonReducers'
import {env} from '.env'

// DEPRICATED
function $_GET(key, def) {

  if (def===undefined)
    def=false;

  let p = window.location.href;
  p = p.replace(/#\w+$/, '');
  p = p.match(new RegExp(key + '=([^&=]+)'));
  return p ? p[1] : def;
}

const initialState = {
  token: $_GET('token', env.token),
  car_condition: +$_GET('car_condition'),
  environment: +$_GET('environment'),
  partner: env.partner,
  dealer_id: +$_GET('dealer_id'),
  user_id: +$_GET('user_id'),
  isFetching: false,
  message: null,
};

//В инкогнито до localstorage ходить нельзя, поэтому в try catch
try{
  initialState.locale = window.localStorage.getItem('locale') ? window.localStorage.getItem('locale') : 'ru';
}catch (e){
  initialState.locale = 'ru';
}

export default {
  initialState,
  ...commonReducers,
}
