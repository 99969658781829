import React from "react";
import { Field } from "redux-form";
import { app } from "redux/actions";

import { Input, Dadata, Select, Checkbox } from "components/reduxForm";
import { InputModeType } from "components/UI";
import { Grid } from "components/UI/grid/Grid";
import { FormSection } from "components/form/FormSection";
import { toCapitalize } from "helpers";
import { realty_state as realtyStates } from "helpers/lists";
import { selectRealtyState } from "modules/form";
import { InputMaskType } from "../../constants";
import { useSelector } from "react-redux";
import { GridEmptyCell } from "components/UI/grid/components/GridEmptyCell";

export function Address() {
  const realtyState = useSelector(selectRealtyState);

  return (
    <FormSection header="Адрес регистрации">
      <Grid>
        {<Field
          component={Input.Redux}
          name="index"
          label="Индекс"
          width="4"
          mask={InputMaskType.POST_INDEX}
          inputMode={InputModeType.NUMERIC}
        />}
        <Field
          component={Dadata.Redux}
          onChange={app.regAdress}
          name="registration_address"
          label="Адрес"
          width="12"
          autoReplace={toCapitalize}
        />
        <Field
          component={Input.Redux}
          name="registration_address_date"
          label="Дата регистрации"
          mask={InputMaskType.DATE}
          width="4"
          placeholder="дд.мм.гггг"
          inputMode={InputModeType.NUMERIC}
        />
        <GridEmptyCell width="8" />
        <Field
          component={Select.Redux}
          label="Статус недвижимости"
          options={realtyStates}
          selected={realtyState}
          field="realty_state"
          name="realty_state"
          width="4"
        />
        <Field
          component={Checkbox.Redux}
          label="Совпадает с адресом проживания"
          name="matches_registration"
          defaultChecked={true}
          width="12"
        />
      </Grid>
    </FormSection>
  );
}
