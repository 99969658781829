export const $_GET = (key, def) => {
  if (def===undefined) {
    def=false;
  }

  let p = window.location.href;
  p = p.replace(/#\w+$/, '');
  p = p.match(new RegExp(key + '=([^&=]+)'));
  return p ? p[1] : def;
}
