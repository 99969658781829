export const GO_BACK_BUTTON = 'goBackButton';

export const goToNextFormFieldByEnter = (event) => {
  if (event.key === 'Enter' && event.target.nodeName === 'INPUT') {
    const form = event.target.form;
    const index = [...form.elements].indexOf(event.target);
    const nextElement = form.elements[index + 1];

    if (nextElement.dataset.role === GO_BACK_BUTTON) {
      form.elements[index + 2].focus();
    } else {
      nextElement.focus();
    }
  }
};
