import { MaritalStatus } from "helpers/constants";
import { getState } from "helpers";
import { selectMaritalStatus } from "../../modules/form/selectors/selectMaritalStatus";


const kasagoReferenceMarriedState = {
  MARRIED: 1,
  UNMARRIED: 0
};

const maritalStatusToKasagoModel = (maritalStatus) => {

  if (!maritalStatus){
    return null;
  }

  return maritalStatus.id == MaritalStatus.MARRIED
    ? kasagoReferenceMarriedState.MARRIED
    : kasagoReferenceMarriedState.UNMARRIED;

};

export const getFamilyState = () => {
  const maritalStatus = selectMaritalStatus(getState());

  return maritalStatusToKasagoModel(maritalStatus);
};
