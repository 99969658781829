import React from 'react';
import { useSelector } from 'react-redux';
import { isMazda } from 'modules/app/utils/isMazda';
import { PersonalDataAgreementMazda } from './PersonalDataAgreementMazda';
import { PersonalDataAgreement } from './PersonalDataAgreement';
import { selectDefaultParams } from 'modules/app/selectors/selectDefaultParams';

export const PersonalDataAgreementFactory = () => {
  const { token } = useSelector(selectDefaultParams);

  if (isMazda(token)) {
    return <PersonalDataAgreementMazda />
  }

  return <PersonalDataAgreement />
}
