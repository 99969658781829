import { getApplicationFormValues } from "./getApplicationFormValues";
import { toBackendDate } from "../dateModels";
import { getPassport } from "./getPassport";
import { getRegistrationAddress } from "./getRegistrationAddress";

export const createOwner = () => {
  const formValues = getApplicationFormValues();

  return {
    birthDate: toBackendDate(formValues.birth_date),
    firstName: formValues.name && formValues.name.value || '',
    middleName: formValues.middle_name && formValues.middle_name.value || '',
    lastName: formValues.surname && formValues.surname.value || '',
    passport: getPassport(),
    registration: getRegistrationAddress()
  };
};

export const createInsurer = () => {
  const formValues = getApplicationFormValues();

  return {
    ...createOwner(),
    phone: formValues.mobile_phone && formValues.mobile_phone.replace(/[+()\s]+/g, '') || '',
    email: formValues.email || ''
  };
};
