import React from 'react'
import PropTypes from "prop-types";

import { Flex } from 'components/layouts'

import './styles.sass'

export const MainLayout = ({ children }) => {
  return (
    <Flex column className="container" f1>
      {children}
    </Flex>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};
