import React from "react";
import { useSelector } from "react-redux";
import { selectDebugFlag } from "modules/app/selectors";

export const MessageType = {
  DATA: "DATA",
  //Финальный расчет
  COMPLETED: "COMPLETED",
  //Фрейм загружен
  KASAGO_LOADED: "KASAGO_LOADED",
  //Оплата завершена
  PAYMENT_COMPLETED: "PAYMENT_COMPLETED",
  SAVE_CONDITIONS: "SAVE_CONDITIONS",
  GET_CONDITIONS: "GET_CONDITIONS",
  FRAME_HEIGHT_CHANGED: "FRAME_HEIGHT_CHANGED",
  BUTTONS_DISABLED: 'BUTTONS_DISABLED'
};

export const useIFrameCommunication = (
  iframeRef,
  targetOrigin,
  onReceiveMessage
) => {

  const debugFlag = useSelector(selectDebugFlag);

  const logg = (title, data) => {
    if (!debugFlag){
      return;
    }

    window.console.log(title, data);
  };

  const postMessage = React.useCallback(
    (data, type = MessageType.DATA) => {

      logg('send to kasko', data);

      iframeRef.current.contentWindow.postMessage(
        { type, payload: data },
        targetOrigin
      );
    },
    [targetOrigin]
  );

  const handleReceiveMessage = React.useCallback(
    (event) => {
      const { origin, data } = event;

      if (!(['https://stage-online.e-credit.one','http://localhost:3000','https://hyundai-dos-online.e-credit.one'].includes(origin) && data.type) && origin !== targetOrigin) {
        return;
      }

      logg('received from kasko', data);

      onReceiveMessage(data, postMessage);
    },
    [onReceiveMessage, targetOrigin]
  );

  React.useEffect(() => {
    window.addEventListener("message", handleReceiveMessage);
    return () => window.removeEventListener("message", handleReceiveMessage);
  }, []);

  return postMessage;
};
