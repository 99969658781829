import compose from 'lodash/fp/compose';
import { replaceEnToRu } from 'helpers/replaceEnToRu';
import { toCapitalize } from 'helpers';

const onlyOneHyphen = (value) => {
  const firstOne = value.search(/-/) + 1;
  return value.slice(0, firstOne) + value.slice(firstOne).replace('-', '');
}

export const normalizePersonName = compose(
  onlyOneHyphen,
  toCapitalize,
  replaceEnToRu,
);
