import React from 'react';
import PropTypes from 'prop-types';

export function GridEmptyCell({ width}) {
  return <div className={`grid__col_width-${width} grid__empty-cell`} />;
}

GridEmptyCell.propTypes = {
  width: PropTypes.string,
};
