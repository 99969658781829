import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { useSelector } from "react-redux";

import { Input, Select } from "components/reduxForm";

import { InputModeType } from "components/UI";
import { Grid } from "components/UI/grid/Grid";
import { FormSection } from "components/form/FormSection";
import { selectMaritalStatus } from 'modules/form/selectors/selectMaritalStatus';
import { marital_status as martialStatuses } from "helpers/lists";

export function MartialStatus({ onChildrenChange }) {
  const status = useSelector(selectMaritalStatus);

  return (
    <FormSection header="Семейное положение">
      <Grid>
        <Field
          component={Select.Redux}
          label="Семейное положение"
          options={martialStatuses}
          selected={status}
          field="marital_status"
          name="marital_status"
          width="4"
        />
        <Field
          component={Input.Redux}
          name="num_of_children_younger_than_21"
          label="Дети до 21 года"
          onChange={onChildrenChange}
          width="4"
          inputMode={InputModeType.NUMERIC}
          maxLength={3}
          mask={Number}
        />
        <Field
          component={Input.Redux}
          name="number_of_dependents"
          label="Кол-во иждивенцев"
          width="4"
          inputMode={InputModeType.NUMERIC}
          maxLength={3}
          mask={Number}
        />
      </Grid>
    </FormSection>
  );
}

MartialStatus.propTypes = {
  onChildrenChange: PropTypes.func,
}
